import {Icon, Text, useStyle, IconName, Pressable} from '@amana/ui';
import Toast, {ToastShowParams} from 'react-native-toast-message';
import capitalize from 'lodash/capitalize';

const iconMap: {[key: string]: IconName} = {
  success: 'Done',
  error: 'Union',
  info: 'Info',
};

// TODO: move to DS after deployment
export const ToastUI = ({text1, props}: ToastShowParams) => {
  const S = useStyle();

  return (
    <Pressable
      onPress={() => Toast.hide()}
      bg="secondary_10"
      style={[{height: 144, width: 188}, S.rad2, S.flex, S.justify, S.align, S.gap2, S.pad6]}>
      <Icon name={iconMap[props.type] || 'Info'} size={48} color="secondary_50" />
      <Text style={S.text} variant="PB">
        {text1}
      </Text>
    </Pressable>
  );
};

export const ToastWithConfig = () => <Toast config={{customToast: ToastUI}} />;

export const toastSuccess = (msg: string) =>
  toast(capitalize(msg), {
    type: 'success',
  });

export const toastInfo = (msg: string) => toast(capitalize(msg), {type: 'error'});

export const toastError = (msg: string) => toast(capitalize(msg), {type: 'error'});

const toast = (msg: string, props = {}) => {
  Toast.show({
    text1: msg,
    type: 'customToast',
    visibilityTime: 4000,
    topOffset: 300,
    props,
  });
};
