import {ContentCard} from './types';
import * as Braze from '@braze/web-sdk';
import {Linking} from 'react-native';

export function getContentCards() {
  return new Promise<ContentCard[]>(resolve => {
    const cachedCards = Braze.getCachedContentCards()?.cards;
    if (cachedCards) return resolve(cachedCards as unknown as ContentCard[]);

    return Braze.requestContentCardsRefresh(async () => {
      const cards = Braze.getCachedContentCards()?.cards as unknown as ContentCard[];
      resolve(cards as any);
    });
  });
}

export function registerPushToken(_fcmtoken: string) {}

export function init(brazeKey: string, VERSION: string) {
  __DEV__ ||
    Braze.initialize(brazeKey, {
      baseUrl: 'https://sdk.fra-02.__DEV__ || Braze.eu',
      enableSdkAuthentication: true,
      manageServiceWorkerExternally: true,
      serviceWorkerLocation: '/web/braze-service-worker.js',
      enableLogging: true,
    });
  __DEV__ || Braze.automaticallyShowInAppMessages();
  __DEV__ || Braze.requestPushPermission();
  __DEV__ || Braze.openSession();
  setAttribute('app_version', VERSION);
}

export function logBrazeEvent(name: string, properties?: any) {
  __DEV__ || Braze.logCustomEvent(name, properties);
}

export function changeUser(userId: string, token: string) {
  __DEV__ || Braze.changeUser(userId, token);
}

export function setLanguage(lang: string) {
  return __DEV__ || Braze?.getUser()?.setLanguage(lang);
}
export function setAttribute(key: string, value: any) {
  return __DEV__ || Braze?.getUser()?.setCustomUserAttribute(key, value, true);
}

export function unsetAttribute(key: string) {
  return __DEV__ || Braze.getUser()?.setCustomUserAttribute(key, null);
}

export function getInitialURL(storage: Storage) {
  Linking.getInitialURL().then(url => {
    if (url) storage.set('DeepLink', url);
  });
}

export function requestContentCardsRefresh() {
  return __DEV__ || Braze.requestContentCardsRefresh();
}

export function logContentCardImpression(card: ContentCard) {
  const b = __DEV__ || Braze.logContentCardImpressions([card as any]);
  console.log('Braze: logContentCardImpression', b);
}

export function logContentCardClicked(card: ContentCard) {
  const b = __DEV__ || Braze.logContentCardClick(card as any);
  console.log('Braze: logContentCardClicked', b);
}
