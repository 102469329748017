import {accountStore} from './AccountStore';
import {positionStore} from './PositionStore';
import {ordersStore} from './OrderStore';
import {symbolStore} from './SymbolStore';
import {sessionStore} from './Session';
import {api} from '../api';
import {setSentryUser} from '../analytics/sentry';
import {SymbolInfo} from '@/Lib';
import {changeUser} from '@/Lib/analytics/braze';
import Base64 from '@/components/Charts/lib/base64';
import {SentryTag} from '@/setup';
import {TradingModes} from '@/components/Charts/helper';
import {makeAutoObservable, runInAction} from 'mobx';
import {captureException, setTag} from '@sentry/react-native';
import {Platform} from 'react-native';
import {AppEventsLogger} from 'react-native-fbsdk-next';
import type {News, Strapi} from '../types';
export class AppUiStore {
  activeSymbol: SymbolInfo | null = null;
  ready = false;
  showSearch = false;
  keyword = '';
  accountId = '';
  tradingMode: TradingModes | null = null;
  token: string | null = '';
  jwt: any | null = null;
  banners = [] as Strapi.Banners['data'];
  howtos = [] as Strapi.HowTos['data'];
  NewsResponse = [] as News.NewsResponse[];
  notifications = [] as News.Notifications[];
  mandatoryUpdate: boolean | null = null;
  location: string | null = '/';
  layout = {x: 0, y: 0, width: 0, height: 0};
  TZ = 7200;
  accountSwitcher = false;
  lockAccountSwitcher = false;
  currentRoute = '';
  statusModal: null | {title?: string; msg?: string} = null;
  loginThroughForgotPassword = false;
  loading = false;

  setActiveSymbol(activeSymbol: AppUiStore['activeSymbol']) {
    this.activeSymbol = activeSymbol;
  }

  toggleAccountSwitcher = (val: boolean) => {
    this.lockAccountSwitcher = true;
    this.accountSwitcher = val;
  };

  setAppDimensions = (layout: {width: number; height: number}) => {
    for (let key in layout) {
      if (key in this.layout) {
        // @ts-expect-error
        this.layout[key] = Number(layout[key]);
      }
    }
  };

  get lastNotificationKey() {
    return 'lastNotification.' + (this.accountId || '');
  }

  setTradingMode(show: TradingModes | null) {
    this.tradingMode = show;
  }

  clear = () => {
    runInAction(() => {
      this.ready = false;
      this.showSearch = false;
      this.keyword = '';
      this.accountId = '';
      this.token = '';
      this.jwt = null;
      this.banners = [] as Strapi.Banners['data'];
      this.howtos = [] as Strapi.HowTos['data'];
      this.NewsResponse = [];
      this.notifications = [];
      this.accountSwitcher = false;
    });
  };

  async processAccesstoken(tokens: {access_token: string; refresh_token?: string}) {
    if (this.token === tokens.access_token) {
      console.error("DUPLICATE TOKENS, DON'T PROCESS");
      return;
    }

    const token = tokens.access_token;
    const jwt = JSON.parse(Base64.atob(token.split('.')[1]).toString()) as any;

    runInAction(() => {
      this.token = token;
      this.jwt = jwt;
      this.accountId = String(jwt.active || jwt.mt?.length ? jwt.mt[0]?.login : 0);
    });

    try {
      await symbolStore.init(this.ActiveAccount?.group);
    } catch (e) {
      console.error('FIRESTORE ERROR', e);
      captureException(e);
    }
    // change user in braze and request PUSH permissions
    setTag('CUID', String(jwt.sub));
    SentryTag('CUID', String(jwt.sub));

    setTag('MT', String(jwt.active));
    changeUser(jwt.sub, token);
    Platform.OS !== 'web' && AppEventsLogger.setUserID(jwt.sub);
    setSentryUser({
      id: jwt.sub,
      username: jwt.sub,
    });
  }

  constructor() {
    makeAutoObservable(this);
  }
  async fetchAndUpdateMTData() {
    if (accountStore.isMT) return;
    return api.get('/api/mtdata').then(res => {
      accountStore.update({...res.data.user, ...res.data.metrics});
      positionStore.addPositions(res.data.positions);
      ordersStore.addPositions(res.data.orders);
      return res.data;
    });
  }

  get ActiveAccount() {
    return sessionStore.account;
  }

  setCurrentRoute(route?: string) {
    if (!route) return;
    this.currentRoute = route;
  }

  setStatusModal(data: typeof this.statusModal) {
    this.statusModal = data;
  }

  setLoginThroughForgotPassword(val: boolean) {
    this.loginThroughForgotPassword = val;
  }
  setFullScreenLoader(isLoading: boolean) {
    this.loading = isLoading;
  }
}

export const appUiStore = new AppUiStore();
