import {Position} from './entities/Position';
import {symbolStore} from './SymbolStore';
import {api} from '../api';
import DeviceEventEmitter from '../DeviceEmitter';
import {makeAutoObservable, observable} from 'mobx';
import type {Tapi} from '../websocket';

export class PositionStore {
  private working: Promise<unknown> | null = null;
  positions: Position[] = [];

  get Rics() {
    return (
      this.positions
        ?.map(row => row.Ric)
        .filter(Boolean)
        .join(',') || ''
    );
  }

  get top() {
    return [...this.positions].sort((a, b) => Math.abs(b.Profit) - Math.abs(a.Profit)).slice(0, 3);
  }

  bySymbol: Record<string, Position> = {};

  getTotalMargin() {
    return this.positions.reduce((acc, cur) => acc + cur.RequiredMargin, 0);
  }
  getTotalProfit() {
    return this.positions.reduce((acc, cur) => acc + cur.Profit, 0);
  }
  getSymbolByName(symbol: string) {
    return this.bySymbol[symbol];
  }
  getSymbolVolume(symbol: string) {
    let i = 0;

    for (let p in this.positions) {
      const elm = this.positions[p];
      if (elm.symbol === symbol) {
        i += elm.Units * (elm.type % 2 === 0 ? 1 : -1);
      }
    }

    return i;
  }

  reset() {
    this.positions = [];
    this.bySymbol = {};
  }
  constructor() {
    makeAutoObservable(this, {
      positions: observable.shallow,
    });
    DeviceEventEmitter.on('positions', ({type, id}) => {
      if (type === 'update') {
        this.fetchAndUpdatePositions();
      }

      if (type === 'remove') {
        this.removePosition(id);
      }
    });
  }

  async fetchAndUpdatePositions() {
    api.get('/api/positions').then(res => {
      this.addPositions(res.data);
    });
  }

  addPositions = (data: Tapi.Position[]) => {
    this.bySymbol = {};

    this.positions = data
      .filter(n => symbolStore.resolveSymbol(n.Symbol)?.symbol === n.Symbol)
      .map(n => {
        this.bySymbol[n.Symbol] = new Position(n, this);
        return this.bySymbol[n.Symbol];
      })
      .sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt));
  };
  removePosition = (n: string) => {
    const id = String(n);
    this.positions = this.positions.filter(p => {
      let stay = String(p.id) !== id;
      if (!stay) {
        delete this.bySymbol[p.symbol];
      }
      return stay;
    });
  };
  clear() {
    this.positions = [];
    this.working = null;
    this.bySymbol = {};
  }

  get USInvestments() {
    return this.positions.reduce((acc, cur) => {
      if (cur.Symbol.isDWStock) {
        acc += cur.RequiredMargin;
      }
      return acc;
    }, 0);
  }

  get ProfitablePositions() {
    return positionStore.positions.reduce((carry, p) => {
      if (p.Profit > 0) return carry + p.Profit;
      return carry;
    }, 0);
  }
  get LosingPositions() {
    return positionStore.positions.reduce((carry, p) => {
      if (p.Profit < 0) return carry + p.Profit;
      return carry;
    }, 0);
  }

  get State() {
    const x = {Profits: 0, Invested: 0};

    positionStore.positions.forEach(p => {
      if (p.Profit) x.Profits += p.Profit;
      if (p.RequiredMargin) x.Invested += p.RequiredMargin || 0;
    });

    return {
      Profits: isFinite(x.Profits) ? x.Profits : 0,
      Invested: isFinite(x.Invested) ? x.Invested : 0,
    };
  }
}

export const positionStore = new PositionStore();
