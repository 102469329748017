import {setUser} from '@sentry/react';
interface SentryUser {
  id: string;
  email?: string;
  username: string;
}
export const setSentryUser = (user: SentryUser) => {
  if (__DEV__) return;
  setUser(user);
};
