import {api, appUiStore, historyApi, mobileApi, mobileApiV3, mobileApiV4, useAppLang} from './Lib';
import {crmApi} from './Lib/api/crmApi';
import {inject401Interceptor} from './Lib/api/inject401Interceptor';
import {QueryProvider} from './Lib/queries/Provider';
import {countryStore} from './Lib/stores/CountryStore';
import {sessionStore} from './Lib/stores/Session';
import {userStore} from './Lib/stores/UserStore';
import {Spinner} from './components/Spinner';
import {useAppTheme} from './hooks';
import Navigation from './navigation';

import './Lib/stores/rootStore';
import {ToastWithConfig} from './components/ToastUI';
import {themeOverride} from '@/themes';
import {UIContext} from '@amana/ui';
import {observer} from 'mobx-react-lite';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {useWindowDimensions} from 'react-native';
import {Suspense, lazy, useEffect, useState} from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
const SocialSignUp = lazy(() => import('./Features/PublicApp/SocialSignUpNav'));
const Protected = lazy(() => import('./ProtectedApp'));
const Public = lazy(() => import('./Features/PublicApp'));
const AppGatewayStack = createNativeStackNavigator();

export default observer(function Gateway() {
  console.log('======== GATEWAY =======');
  const [locale] = useAppLang();
  const [theme] = useAppTheme();
  const [token, setToken] = useState<string | null | undefined>(undefined);
  const promise = sessionStore.access_token;
  useEffect(() => {
    promise.then(d => {
      setToken(d);
    });
  }, [promise]);

  if (token === undefined) return <Spinner />;

  countryStore.fetch();

  return (
    <QueryProvider>
      <UIContext.Provider value={{theme, locale: locale as 'en', overrideTheme: themeOverride}}>
        <SyncWindowSize />
        <SafeAreaProvider>
          <Navigation>
            <AppGatewayStack.Navigator screenOptions={{headerShown: false}}>
              {token ? (
                <AppGatewayStack.Screen name="RootApp" component={ProtectedApp} />
              ) : (
                <AppGatewayStack.Screen name="AuthApp" component={PublicApp} />
              )}
            </AppGatewayStack.Navigator>
          </Navigation>
          <ToastWithConfig />
        </SafeAreaProvider>
      </UIContext.Provider>
    </QueryProvider>
  );
});

const ProtectedApp = () => {
  const [isTokenInjected, setIsTokenInjected] = useState(false);

  useEffect(() => {
    async function setupInterceptors() {
      let interceptors = await Promise.all([
        inject401Interceptor(crmApi.instance),
        inject401Interceptor(mobileApi),
        inject401Interceptor(mobileApiV3),
        inject401Interceptor(mobileApiV4),
        inject401Interceptor(api),
        inject401Interceptor(historyApi),
      ]);

      setIsTokenInjected(true); // Indicate that setup is complete

      return () => {
        interceptors.forEach(removeInterceptor => removeInterceptor());
        setIsTokenInjected(false);
      };
    }

    const cleanup = setupInterceptors();

    return () => {
      cleanup.then(remove => remove());
    };
  }, []);

  return isTokenInjected ? <ProtectedAppWithReadyToken /> : <Spinner />;
};

const ProtectedAppWithReadyToken = observer(() => {
  const {userDetails} = userStore;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    userStore.fetch().finally(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Spinner />;

  if (
    !userDetails.phone_verified ||
    !userDetails?.metadata?.kyc?.residency ||
    !userDetails?.metadata?.kyc?.nationality
  ) {
    return (
      <Suspense fallback={<Spinner />}>
        <SocialSignUp />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Protected />
    </Suspense>
  );
});

function PublicApp() {
  return (
    <Suspense fallback={<Spinner />}>
      <Public />
    </Suspense>
  );
}

function SyncWindowSize() {
  const {width, height} = useWindowDimensions();
  useEffect(() => {
    appUiStore.setAppDimensions({width, height});
  }, [width, height]);
  return null;
}
