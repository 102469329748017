import {useT} from '@/Lib';
import {View, useStyle, Text, Icon, useMediaQuery, If} from '@amana/ui';
import LottieView from 'lottie-react-native';

export default function LottieSpiner() {
  const S = useStyle();
  const {isMobile} = useMediaQuery();
  const t = useT();
  return (
    <View style={[S.flex, S.align]}>
      <If condition={isMobile}>
        <LottieView
          source={require('./bg.json')}
          style={{width: '100%', height: '100%', position: 'absolute'}}
          resizeMode="cover"
          autoPlay
          loop
        />
      </If>
      <View bg="transparent" style={[S.flex, S.justifyEnd]}>
        <Icon name="LogoHorizontal" size={200} />
      </View>
      <View bg="transparent" style={[S.gap6, {width: 140}, S.flex, S.align, S.justifyEnd, S.pb12]}>
        <View bg="transparent" style={{width: 58, height: 58}}>
          <LottieView
            source={require('./spinner.json')}
            style={{width: '100%', height: '100%'}}
            autoPlay
            loop
          />
        </View>
        <Text style={S.text} color="secondary_50" variant="Label">
          {t.general.mainWaiting}
        </Text>
      </View>
    </View>
  );
}
