import {OAuth} from './OAuth';
import {LangButton} from '../LangButton';
import {isEmail, testId, useT} from '@/Lib';
import {useScreenTracker} from '@/hooks/useScreenTracker';
import {navigate} from '@/helpers/navigation';
import {Header} from '@/Features/PublicApp/components/Header/Header';
import config from '@/config';
import {sessionStore} from '@/Lib/stores/Session';
import {appSettings} from '@/Lib/storage';

import useTimezoneLocation from '@/Lib/queries/hooks/useTimezoneLocation';
import {
  Button,
  DesktopOnly,
  If,
  Pressable,
  ScrollView,
  Text,
  TextField,
  useMediaQuery,
  useStyle,
  View,
} from '@amana/ui';
import {useCallback, useRef, useState} from 'react';
import {Keyboard, KeyboardAvoidingView, TextInput} from 'react-native';
import capitalize from 'lodash/capitalize';
import {MMKV} from 'react-native-mmkv';
import {useFocusEffect} from '@react-navigation/native';

export const passStorage = new MMKV({
  id: 'pwd-storage',
  encryptionKey: '£@£e23eDwsdae312123123sdxhunter2',
});

export function Login() {
  const t = useT();
  const S = useStyle();
  const {isEurope} = useTimezoneLocation();
  const [email, setEmail] = useState<string>(passStorage.getString('LAST_USERNAME') || '');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const passwordRef = useRef<TextInput>(null);
  const [error, setError] = useState<string>();
  const {md} = useMediaQuery();
  useScreenTracker('LoginScreen', 2000);

  const handleSubmit = useCallback(() => {
    if (loading) return;
    if (isEmail(email) === false) return setError('Invalid Email');
    if (password.length < 7) return setError('Invalid Password');
    Keyboard.dismiss();
    setLoading(true);
    passStorage.set('LAST_USERNAME', email);
    return submitLogin(email, password, t)
      .catch(e => {
        if (typeof e === 'string') {
          if (e.includes('INVALID_CRED')) {
            return setError(t.login.wrongCredentials);
          }
        } else {
          return setError(e?.message || e);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [email, password, loading, t]);

  const goToPassword = (e?: any) => {
    if (/[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email)) passwordRef.current?.focus();
    else {
      e?.preventDefault();
    }
  };
  const isValid = isEmail(email) && password.length > 6;
  useFocusEffect(
    useCallback(() => {
      const user = passStorage.getString('LAST_USERNAME');
      const pass = passStorage.getString('LAST_PASSWORD');
      if (user && pass) {
        setLoading(true);
        submitLogin(user, pass, t).finally(() => setLoading(false));
      }
    }, [t])
  );

  return (
    <KeyboardAvoidingView behavior="padding" style={[S.flex]}>
      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        style={[S.flex]}
        contentContainerStyle={[S.flex, S.pad4]}
        scrollEnabled={md}>
        <DesktopOnly
          fallback={
            <Header
              hasBack={false}
              text={t.logIn.login}
              style={{
                marginBottom: 24,
              }}
            />
          }>
          <View style={[S.row, S.wFull, S.justifyEnd, S.pad4]} testID={testId('Login-View-1')}>
            {config.features.isLanguageEnabled ? <LangButton /> : null}
          </View>
        </DesktopOnly>

        <View style={[S.flex, md && S.justify]} testID={testId('Login-View-2')}>
          <DesktopOnly>
            <Pressable
              onPress={() => {
                window.open('https://amana.app/', '_blank');
              }}
              style={[S.align, S.self]}>
              {() => config.horizontal_logo()}
            </Pressable>
          </DesktopOnly>

          <View
            style={[
              md && [
                {
                  width: 400,
                },
                S.self,
                S.mt15,
              ],
              S.gap3,
            ]}
            id="asddsa"
            testID={testId('Login-View-3')}>
            <DesktopOnly>
              <View style={[S.mb4, S.align]}>
                <Text variant="H2B" testID={testId('Login-Text-Title')}>
                  {t.logIn.title}
                </Text>
              </View>
            </DesktopOnly>
            <TextField
              autoFocus={email.length === 0}
              value={email}
              onChangeText={txt => setEmail(txt.replace(/\s/g, ''))}
              onSubmitEditing={goToPassword}
              label={t.logIn.yourEmail}
              textContentType="username"
              autoCapitalize="none"
              autoComplete="email"
              keyboardType="email-address"
              testID={testId('Login-TextField-4')}
            />
            <TextField
              secureTextEntry={!showPassword}
              textContentType="password"
              autoFocus={email.length > 0}
              ref={passwordRef}
              onSubmitEditing={handleSubmit}
              value={password}
              onChangeText={setPassword}
              label={t.logIn.password}
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="password"
              rightText={showPassword ? t.generatePassword.hide : t.generatePassword.show}
              onRightText={() => setShowPassword(!showPassword)}
              bottomText={error}
              variant={error ? 'error' : undefined}
              testID={testId('Login-TextField-5')}
            />

            <Pressable
              style={[S.selfEnd]}
              onPress={() => navigate('ForgotPasswordScreen')}
              testID={testId('Login-Pressable-6')}>
              <Text variant="Link" color="primary" style={[S.pv0]} testID={testId('Login-Text-7')}>
                {t.logIn.forgotPassword}
              </Text>
            </Pressable>

            <Button
              variant="primary"
              style={[S.mt1]}
              testID={testId('login-screen-login-btn')}
              loading={loading}
              disabled={!isValid || loading}
              onPress={handleSubmit}
              title={t.logIn.login}
            />
          </View>

          {config.features.isSocialLoginEnabled && !isEurope ? <OAuth /> : null}

          <If condition={!isEurope}>
            <DesktopOnly>
              <View style={[{marginTop: 72, width: 400}, S.self]}>
                <View
                  bg="secondary_5"
                  style={[
                    {
                      top: 11,
                      height: 1,
                    },
                  ]}
                  testID={testId('AuthContent-View-3')}
                />
                <View style={[S.align, S.self, S.ph3]} testID={testId('AuthContent-View-4')}>
                  <Text
                    color="secondary_50"
                    variant="SubText"
                    style={[S.text]}
                    testID={testId('AuthContent-Text-5')}>
                    {t.logIn.noAccount}
                  </Text>
                </View>
                <Button
                  style={[S.mt6]}
                  title={t.logIn.signUp}
                  variant="primary"
                  onPress={() => navigate('SignUpNavigation')}
                />
                {/* {Platform.OS === 'web' ? (
                <iframe
                  style={{
                    display: 'none',
                  }}
                  src={`${MARKETING_URL}/webtrader?event=logout`}
                  id="ifr"
                />
              ) : null} */}
              </View>
            </DesktopOnly>
          </If>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const submitLogin = (usr: string, pwd: string, t: ReturnType<typeof useT>) => {
  return sessionStore
    .login(usr, pwd)
    .then(res => {
      console.log('===>logged in success.. starting app', res);
      passStorage.set('LAST_USERNAME', usr);
      appSettings.set('LOGGED_ONCE', true);
    })
    .catch(e => {
      if (e.response?.status === 400 && e.response?.data?.message === 'USER_DISABLED') {
        navigate('RejectedScreen' as any);
      } else if (e.response?.status === 412) {
        navigate('InvalidGroupModal');
      } else if (e.response?.data?.errors) {
        let error = e.response?.data?.errors
          .map((n: any) => capitalize(Object.values(n.constraints || {}).join(', ')))
          .join('\n');
        throw error;
      } else {
        console.log(e);
        let error =
          e.response?.data?.message === 'INVALID_CRED'
            ? t.login.wrongCredentials
            : e.response?.data?.message || e.message || 'Failed to login';
        throw error;
      }
    });
};
