/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface SendMagicLinkPayload {
  /** @format email */
  email: string;
  /** @format uri */
  redirect_uri: string;
}

export interface SendMagicLinkData {
  success: boolean;
}

export interface CheckEmailPayload {
  /** @format email */
  email: string;
}

export type CheckEmailData = any;

export interface SendVerifyEmailLinkPayload {
  /** @format uri */
  redirect_uri: string;
}

export type SendVerifyEmailLinkData = any;

export interface LoginAsPayload {
  cuid: string;
}

export interface LoginAsData {
  access_token: string;
  refresh_token: string;
}

export interface LoginPayload {
  /** @format email */
  email: string;
  password: string;
}

export interface LoginData {
  access_token: string;
  refresh_token: string;
}

export interface BlockUserPayload {
  userId: string;
  blockReason: string;
}

export interface BlockUserData {
  success: boolean;
  message: string;
}

export interface UnblockUserPayload {
  userId: string;
}

export interface UnblockUserData {
  success: boolean;
  message: string;
}

export type RefreshTokenPayload =
  | any
  | {
      refresh_token?: string;
      account_id?: string;
    };

export interface RefreshTokenData {
  refresh_token: string;
  access_token: string;
}

export interface CreateStaffPayload {
  /** @format email */
  email: string;
  password: string;
  name: string;
}

export interface CreateStaffData {
  refresh_token: string;
  access_token: string;
}

export interface ManualSignupPayload {
  /** @format email */
  email: string;
  /**
   * @minLength 8
   * @maxLength 35
   * @pattern ^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,35}$
   */
  password: string;
  metadata?: any;
  token: string;
  /** @format uri */
  redirect_uri: string;
}

export interface ManualSignupData {
  refresh_token: string;
  access_token: string;
}

export interface SocialSignupPayload {
  token: string;
  metadata?: any;
}

export interface SocialSignupData {
  refresh_token: string;
  access_token: string;
}

export interface MigrateUserPayload {
  /** @format email */
  email: string;
  password: string;
  appdata: Record<string, string>;
  role: 'client' | 'staff' | 'admin';
  phoneNumber?: string;
}

export type MigrateUserData = any;

export interface ChangePasswordPayload {
  /**
   * @minLength 8
   * @maxLength 35
   * @pattern ^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,35}$
   */
  password: string;
}

export interface ChangePasswordData {
  success: boolean;
}

export interface SendOtpPayload {
  phoneNumber: string;
}

export type SendOtpData = any;

export interface VerifyOtpPayload {
  phoneNumber: string;
  otp: string;
}

export interface VerifyOtpData {
  token: string;
}

export type AdminGetDocumentsData = {
  id: string;
  external_id: string | null;
  path: string;
  type: string;
  category: 'ID' | 'POR' | 'SELFI';
  auto_approved: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt: string | null;
  /** @format date-time */
  rejectedAt: string | null;
  /** @format date-time */
  approved_at: string | null;
  /** @format date-time */
  expire_at: string | null;
  comments: string | null;
  metadata?: any;
  cuid: string;
  provider: 'IDWISE' | 'USER' | 'MIGRATION' | 'STAFF';
  kyc_formId: string;
  kyc_form?: {
    id: string;
    cuid: string;
    formType: string;
    entityName: string;
    metadata?: any;
    answers: Record<string, string>;
    additionalInformation: Record<string, any>;
    isValid: boolean;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @default 1 */
    page?: number;
  } | null;
  user?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
}[];

export interface AdminVerifyDocPayload {
  comment: string;
  verified: boolean;
}

export type AdminVerifyDocData = any;

export type AdminDeleteDocumentData = any;

export type PreviewDocumentData = any;

export type GetPendingDocumentsData = {
  id: string;
  external_id: string | null;
  path: string;
  type: string;
  category: 'ID' | 'POR' | 'SELFI';
  auto_approved: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt: string | null;
  /** @format date-time */
  rejectedAt: string | null;
  /** @format date-time */
  approved_at: string | null;
  /** @format date-time */
  expire_at: string | null;
  comments: string | null;
  metadata?: any;
  cuid: string;
  provider: 'IDWISE' | 'USER' | 'MIGRATION' | 'STAFF';
  kyc_formId: string;
  kyc_form?: {
    id: string;
    cuid: string;
    formType: string;
    entityName: string;
    metadata?: any;
    answers: Record<string, string>;
    additionalInformation: Record<string, any>;
    isValid: boolean;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @default 1 */
    page?: number;
  } | null;
  user?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
}[];

export interface GetDocumentStatsData {
  totalDocuments: number;
  pendingDocuments: number;
  approvedDocuments: number;
  rejectedDocuments: number;
}

export type GetAllDefinitionsData = Record<string, any>;

export type AppendDefinitionPayload = Record<string, any>;

export type AppendDefinitionData = Record<string, any>;

export interface CreateUserKycFormPayload {
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
}

export interface CreateUserKycFormData {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  page: number;
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue: string | null;
    min: number | null;
    max: number | null;
    /** @default 1 */
    page?: number;
    /** @default 1 */
    order?: number;
    pattern: string | null;
    logic: string[] | null;
    options: string | null;
    showIf: string | null;
    /** @default false */
    published?: boolean;
  }[];
  extendedFields?: {
    name: string;
    required: boolean;
    defaultValue?: any;
    section?: string | null;
    pattern?: string | null;
    options?: string[];
    type: string;
    min?: number | null;
    max?: number | null;
    description?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  }[];
  id_status?: string | null;
  /** @format date-time */
  id_verified?: string | null;
  /** @format date-time */
  kyc_verified?: string | null;
  por_status?: string | null;
  /** @format date-time */
  por_verified?: string | null;
  residency?: string | null;
  nationality?: string | null;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  acknowledgementCheckedDateTime?: string | null;
}

export type AdminGetAllUserKycFormsData = {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  page: number;
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue: string | null;
    min: number | null;
    max: number | null;
    /** @default 1 */
    page?: number;
    /** @default 1 */
    order?: number;
    pattern: string | null;
    logic: string[] | null;
    options: string | null;
    showIf: string | null;
    /** @default false */
    published?: boolean;
  }[];
  extendedFields?: {
    name: string;
    required: boolean;
    defaultValue?: any;
    section?: string | null;
    pattern?: string | null;
    options?: string[];
    type: string;
    min?: number | null;
    max?: number | null;
    description?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  }[];
  id_status?: string | null;
  /** @format date-time */
  id_verified?: string | null;
  /** @format date-time */
  kyc_verified?: string | null;
  por_status?: string | null;
  /** @format date-time */
  por_verified?: string | null;
  residency?: string | null;
  nationality?: string | null;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  acknowledgementCheckedDateTime?: string | null;
}[];

export type GetKycFormsByUserIdData = {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  page: number;
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue: string | null;
    min: number | null;
    max: number | null;
    /** @default 1 */
    page?: number;
    /** @default 1 */
    order?: number;
    pattern: string | null;
    logic: string[] | null;
    options: string | null;
    showIf: string | null;
    /** @default false */
    published?: boolean;
  }[];
  extendedFields?: {
    name: string;
    required: boolean;
    defaultValue?: any;
    section?: string | null;
    pattern?: string | null;
    options?: string[];
    type: string;
    min?: number | null;
    max?: number | null;
    description?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  }[];
  id_status?: string | null;
  /** @format date-time */
  id_verified?: string | null;
  /** @format date-time */
  kyc_verified?: string | null;
  por_status?: string | null;
  /** @format date-time */
  por_verified?: string | null;
  residency?: string | null;
  nationality?: string | null;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  acknowledgementCheckedDateTime?: string | null;
}[];

export interface GetOrCreateKycFormData {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  page: number;
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue: string | null;
    min: number | null;
    max: number | null;
    /** @default 1 */
    page?: number;
    /** @default 1 */
    order?: number;
    pattern: string | null;
    logic: string[] | null;
    options: string | null;
    showIf: string | null;
    /** @default false */
    published?: boolean;
  }[];
  extendedFields?: {
    name: string;
    required: boolean;
    defaultValue?: any;
    section?: string | null;
    pattern?: string | null;
    options?: string[];
    type: string;
    min?: number | null;
    max?: number | null;
    description?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  }[];
  id_status?: string | null;
  /** @format date-time */
  id_verified?: string | null;
  /** @format date-time */
  kyc_verified?: string | null;
  por_status?: string | null;
  /** @format date-time */
  por_verified?: string | null;
  residency?: string | null;
  nationality?: string | null;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  acknowledgementCheckedDateTime?: string | null;
}

export type UpdateUserFormPayload = Record<string, string>;

export interface UpdateUserFormData {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  page: number;
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue: string | null;
    min: number | null;
    max: number | null;
    /** @default 1 */
    page?: number;
    /** @default 1 */
    order?: number;
    pattern: string | null;
    logic: string[] | null;
    options: string | null;
    showIf: string | null;
    /** @default false */
    published?: boolean;
  }[];
  extendedFields?: {
    name: string;
    required: boolean;
    defaultValue?: any;
    section?: string | null;
    pattern?: string | null;
    options?: string[];
    type: string;
    min?: number | null;
    max?: number | null;
    description?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  }[];
  id_status?: string | null;
  /** @format date-time */
  id_verified?: string | null;
  /** @format date-time */
  kyc_verified?: string | null;
  por_status?: string | null;
  /** @format date-time */
  por_verified?: string | null;
  residency?: string | null;
  nationality?: string | null;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  acknowledgementCheckedDateTime?: string | null;
}

export type UpdateUserFormAdditionalInfoPayload = Record<string, any>;

export interface UpdateUserFormAdditionalInfoData {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  page: number;
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue: string | null;
    min: number | null;
    max: number | null;
    /** @default 1 */
    page?: number;
    /** @default 1 */
    order?: number;
    pattern: string | null;
    logic: string[] | null;
    options: string | null;
    showIf: string | null;
    /** @default false */
    published?: boolean;
  }[];
  extendedFields?: {
    name: string;
    required: boolean;
    defaultValue?: any;
    section?: string | null;
    pattern?: string | null;
    options?: string[];
    type: string;
    min?: number | null;
    max?: number | null;
    description?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  }[];
  id_status?: string | null;
  /** @format date-time */
  id_verified?: string | null;
  /** @format date-time */
  kyc_verified?: string | null;
  por_status?: string | null;
  /** @format date-time */
  por_verified?: string | null;
  residency?: string | null;
  nationality?: string | null;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  acknowledgementCheckedDateTime?: string | null;
}

export type GetLeadsData = {
  id: number;
  cuid: string;
  email: string;
  fname?: string | null;
  lname?: string | null;
  /** @format date-time */
  createdAt: string;
  metadata?: any;
}[];

export type GetAllData = {
  id: number;
  name: string;
  group: string;
  requirements: string;
  priority: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  is_dw: boolean;
  is_crypto: boolean;
  iDRangeId: number;
  server_id: string;
  kyc_formName: string;
  defaultLeverage: number;
  maxLeverage: number;
}[];

export interface CreatePayload {
  name: string;
  group: string;
  requirements: string;
  priority: number;
  is_dw: boolean;
  is_crypto: boolean;
  iDRangeId: number;
  server_id: string;
  kyc_formName: string;
  defaultLeverage: number;
  maxLeverage: number;
}

export interface CreateData {
  id: number;
  name: string;
  group: string;
  requirements: string;
  priority: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  is_dw: boolean;
  is_crypto: boolean;
  iDRangeId: number;
  server_id: string;
  kyc_formName: string;
  defaultLeverage: number;
  maxLeverage: number;
}

export type GetAllByServerIdData = {
  id: number;
  name: string;
  group: string;
  requirements: string;
  priority: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  is_dw: boolean;
  is_crypto: boolean;
  iDRangeId: number;
  server_id: string;
  kyc_formName: string;
  defaultLeverage: number;
  maxLeverage: number;
}[];

export type GetAllByGroupData = {
  id: number;
  name: string;
  group: string;
  requirements: string;
  priority: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  is_dw: boolean;
  is_crypto: boolean;
  iDRangeId: number;
  server_id: string;
  kyc_formName: string;
  defaultLeverage: number;
  maxLeverage: number;
}[];

export type GetAllByKycFormNameData = {
  id: number;
  name: string;
  group: string;
  requirements: string;
  priority: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  is_dw: boolean;
  is_crypto: boolean;
  iDRangeId: number;
  server_id: string;
  kyc_formName: string;
  defaultLeverage: number;
  maxLeverage: number;
}[];

export interface UpdatePayload {
  name?: string;
  group?: string;
  requirements?: string;
  priority?: number;
  is_dw?: boolean;
  is_crypto?: boolean;
  iDRangeId?: number;
  server_id?: string;
  kyc_formName?: string;
  defaultLeverage?: number;
  maxLeverage?: number;
}

export interface UpdateData {
  id: number;
  name: string;
  group: string;
  requirements: string;
  priority: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  is_dw: boolean;
  is_crypto: boolean;
  iDRangeId: number;
  server_id: string;
  kyc_formName: string;
  defaultLeverage: number;
  maxLeverage: number;
}

export type SoftDeleteByIdData = any;

export interface SoftDeleteManyByIdsPayload {
  ids: number[];
}

export type SoftDeleteManyByIdsData = any;

export interface CreateBody {
  min: number;
  max: number;
  next: number;
}

export interface CreateResult {
  id: number;
  min: number;
  max: number;
  next: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export type GetAllResult = {
  id: number;
  min: number;
  max: number;
  next: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface DeleteManyPayload {
  ids: number[];
}

export interface DeleteManyData {
  message: string;
}

export interface UpdateBody {
  min?: number;
  max?: number;
  next?: number;
}

export interface UpdateResult {
  id: number;
  min: number;
  max: number;
  next: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface DeleteData {
  id: number;
  min: number;
  max: number;
  next: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface GetAllByServerIdResult {
  page: number;
  limit: number;
  result: {
    id: string;
    group: string;
    enabled: boolean;
    commissions: any[];
    symbols: any[];
    permissionsFlags: number | null;
    authMode: number | null;
    authPasswordMin: number | null;
    authOtpMode: number | null;
    company: string | null;
    companyPage: string | null;
    companyEmail: string | null;
    companySupportPage: string | null;
    companySupportEmail: string | null;
    companyCatalog: string | null;
    currency: string | null;
    currencyDigits: number | null;
    reportsMode: number | null;
    reportsFlags: number | null;
    reportsSmtp: string | null;
    reportsSmtpLogin: string | null;
    reportsSmtpPass: string | null;
    newsMode: number | null;
    newsCategory: string | null;
    newsLangs: number[] | null;
    mailMode: number | null;
    tradeFlags: number | null;
    tradeTransferMode: number | null;
    tradeInterestrate: number | null;
    tradeVirtualCredit: number | null;
    marginMode: number | null;
    marginSoMode: number | null;
    marginFreeMode: number | null;
    marginCall: number | null;
    marginStopOut: number | null;
    marginFreeProfitMode: number | null;
    demoLeverage: number | null;
    demoDeposit: number | null;
    limitHistory: number | null;
    limitOrders: number | null;
    limitSymbols: number | null;
    limitPositions: number | null;
  }[];
  count: number;
  exceedCount: boolean;
  exceedTotalPages: boolean;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  totalPages: number;
}

export interface GetAllOutput {
  page: number;
  limit: number;
  result: {
    id: string;
    group: string;
    enabled: boolean;
    commissions: any[];
    symbols: any[];
    permissionsFlags: number | null;
    authMode: number | null;
    authPasswordMin: number | null;
    authOtpMode: number | null;
    company: string | null;
    companyPage: string | null;
    companyEmail: string | null;
    companySupportPage: string | null;
    companySupportEmail: string | null;
    companyCatalog: string | null;
    currency: string | null;
    currencyDigits: number | null;
    reportsMode: number | null;
    reportsFlags: number | null;
    reportsSmtp: string | null;
    reportsSmtpLogin: string | null;
    reportsSmtpPass: string | null;
    newsMode: number | null;
    newsCategory: string | null;
    newsLangs: number[] | null;
    mailMode: number | null;
    tradeFlags: number | null;
    tradeTransferMode: number | null;
    tradeInterestrate: number | null;
    tradeVirtualCredit: number | null;
    marginMode: number | null;
    marginSoMode: number | null;
    marginFreeMode: number | null;
    marginCall: number | null;
    marginStopOut: number | null;
    marginFreeProfitMode: number | null;
    demoLeverage: number | null;
    demoDeposit: number | null;
    limitHistory: number | null;
    limitOrders: number | null;
    limitSymbols: number | null;
    limitPositions: number | null;
  }[];
  count: number;
  exceedCount: boolean;
  exceedTotalPages: boolean;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  totalPages: number;
}

export interface GetAllByServerTypeData {
  page: number;
  limit: number;
  result: {
    id: string;
    group: string;
    enabled: boolean;
    commissions: any[];
    symbols: any[];
    permissionsFlags: number | null;
    authMode: number | null;
    authPasswordMin: number | null;
    authOtpMode: number | null;
    company: string | null;
    companyPage: string | null;
    companyEmail: string | null;
    companySupportPage: string | null;
    companySupportEmail: string | null;
    companyCatalog: string | null;
    currency: string | null;
    currencyDigits: number | null;
    reportsMode: number | null;
    reportsFlags: number | null;
    reportsSmtp: string | null;
    reportsSmtpLogin: string | null;
    reportsSmtpPass: string | null;
    newsMode: number | null;
    newsCategory: string | null;
    newsLangs: number[] | null;
    mailMode: number | null;
    tradeFlags: number | null;
    tradeTransferMode: number | null;
    tradeInterestrate: number | null;
    tradeVirtualCredit: number | null;
    marginMode: number | null;
    marginSoMode: number | null;
    marginFreeMode: number | null;
    marginCall: number | null;
    marginStopOut: number | null;
    marginFreeProfitMode: number | null;
    demoLeverage: number | null;
    demoDeposit: number | null;
    limitHistory: number | null;
    limitOrders: number | null;
    limitSymbols: number | null;
    limitPositions: number | null;
  }[];
  count: number;
  exceedCount: boolean;
  exceedTotalPages: boolean;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  totalPages: number;
}

export interface SetEnabledPayload {
  enabled: boolean;
}

export interface SetEnabledData {
  id: string;
  group: string;
  enabled: boolean;
  commissions: any[];
  symbols: any[];
  permissionsFlags: number | null;
  authMode: number | null;
  authPasswordMin: number | null;
  authOtpMode: number | null;
  company: string | null;
  companyPage: string | null;
  companyEmail: string | null;
  companySupportPage: string | null;
  companySupportEmail: string | null;
  companyCatalog: string | null;
  currency: string | null;
  currencyDigits: number | null;
  reportsMode: number | null;
  reportsFlags: number | null;
  reportsSmtp: string | null;
  reportsSmtpLogin: string | null;
  reportsSmtpPass: string | null;
  newsMode: number | null;
  newsCategory: string | null;
  newsLangs: number[] | null;
  mailMode: number | null;
  tradeFlags: number | null;
  tradeTransferMode: number | null;
  tradeInterestrate: number | null;
  tradeVirtualCredit: number | null;
  marginMode: number | null;
  marginSoMode: number | null;
  marginFreeMode: number | null;
  marginCall: number | null;
  marginStopOut: number | null;
  marginFreeProfitMode: number | null;
  demoLeverage: number | null;
  demoDeposit: number | null;
  limitHistory: number | null;
  limitOrders: number | null;
  limitSymbols: number | null;
  limitPositions: number | null;
}

export interface SyncByServerIdData {
  message: string;
  count: number;
}

export type GetMinimalGroupsByServerIdData = {
  group: string;
  id: string;
  enabled: boolean;
  server: {
    /**
     * @minLength 1
     * @maxLength 255
     */
    name: string;
    id: string;
  };
}[];

export type AdminGetWalletByUserIdData = Record<string, number>;

export type AdminGetWalletsData = {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  balances: {
    currency: string;
    balance: number;
    /** @format date-time */
    lastUpdatedAt: string;
  }[];
}[];

export type AdminGetTransactionsData = {
  status: 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'REJECTED' | 'ERROR';
  id: string;
  owner: string;
  Owner: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  createdBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  updatedBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  type: string;
  comment?: string | null;
  subtype?: string | null;
  audit_log: string[];
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  bankAccount?: {
    bankName: string;
    accountName: string;
  } | null;
  creditCard?: {
    lastFourDigits: number;
    typeName: string;
  } | null;
  entries: {
    id: string;
    transactionId: string;
    usd_amount: number;
    raw_amount: number;
    exchange_rate: number;
    currency_code: string;
    audit_log: string[] | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    dealId?: string | null;
    userId?: string | null;
    accountId?: string | null;
    gatewayId?: string | null;
    /** @format date-time */
    approvedAt?: string | null;
    approvedBy?: string | null;
    account: {
      external_id: string;
      account_type: {
        server?: {
          name: string;
          type: string;
        };
      } | null;
    };
  }[];
  WithdrawalRequest: {
    bankAccount: {
      bankName: string;
      accountName: string;
    } | null;
    creditCard: {
      lastFourDigits: number;
      typeName: string;
    } | null;
    processedComment?: string | null;
    amount: number;
  };
}[];

export interface AdminTransferFromWalletToAccountPayload {
  kycId: string;
  toAccountId: string;
  amount: number;
  /** @minLength 1 */
  currency: string;
  fromCurrency?: string;
  toCurrency?: string;
  comment?: string;
  txId?: string;
}

export interface AdminTransferFromWalletToAccountData {
  success: true;
}

export interface AdminTransferFromAccountToWalletPayload {
  kycId: string;
  fromAccountId: string;
  amount: number;
  /** @minLength 1 */
  currency: string;
  fromCurrency?: string;
  toCurrency?: string;
  comment?: string;
}

export interface AdminTransferFromAccountToWalletData {
  success: true;
}

export interface AdminTransferFromAccountToAccountPayload {
  fromAccountId: string;
  toAccountId: string;
  amount: number;
  currency: 'USD';
  comment?: string;
  txId?: string;
}

export interface AdminTransferFromAccountToAccountData {
  success: true;
}

export interface AdminTransferFromGatewayToTargetPayload {
  kycId: string;
  gateway: string;
  toAccountId?: string;
  amount: number;
  currency: string;
  fromCurrency?: string;
  toCurrency?: string;
  txId: string;
  comment?: string;
  bankAccountId?: number;
  creditCardId?: number;
}

export interface AdminTransferFromGatewayToTargetData {
  success: true;
}

export interface AdminUpdateTransactionStatusPayload {
  status: 'COMPLETED' | 'REJECTED';
}

export interface AdminUpdateTransactionStatusData {
  success: true;
}

export interface AdminUpdateWithdrawPayload {
  id: number;
  status: 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'REJECTED' | 'ERROR';
  comment?: string;
  creditCardId?: number;
  bankAccountId?: number;
  paymentGatewayName?: string;
}

export interface AdminUpdateWithdrawData {
  id: number;
  kycId: string;
  accountId: string | null;
  amount: number;
  currency: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  processedAt?: string | null;
  processedBy?: string | null;
  processedComment?: string | null;
  userComment?: string | null;
  transactionId: string;
  bankAccountId?: number | null;
  creditCardId?: number | null;
  paymentGatewayName?: string | null;
}

export type GetWithdrawRequestsData = {
  id: number;
  kycId: string;
  accountId: string | null;
  amount: number;
  currency: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  processedAt?: string | null;
  processedBy?: string | null;
  processedComment?: string | null;
  userComment?: string | null;
  transactionId: string;
  bankAccountId?: number | null;
  creditCardId?: number | null;
  paymentGatewayName?: string | null;
  transaction: {
    status: 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'REJECTED' | 'ERROR';
    id: string;
    owner: string;
    Owner?: {
      id: number;
      cuid: string;
      email: string;
      fname?: string | null;
      lname?: string | null;
      /** @format date-time */
      createdAt: string;
      metadata?: any;
    };
    createdBy?: {
      id: number;
      cuid: string;
      email: string;
      fname?: string | null;
      lname?: string | null;
      /** @format date-time */
      createdAt: string;
      metadata?: any;
    };
    updatedBy?: {
      id: number;
      cuid: string;
      email: string;
      fname?: string | null;
      lname?: string | null;
      /** @format date-time */
      createdAt: string;
      metadata?: any;
    };
    type: string;
    comment?: string | null;
    subtype?: string | null;
    audit_log: string[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    bankAccount?: {
      bankName: string;
      accountName: string;
    } | null;
    creditCard?: {
      lastFourDigits: number;
      typeName: string;
    } | null;
  };
}[];

export interface RequestWithdrawPayload {
  kycId: string;
  accountId?: string;
  amount: number;
  /** @minLength 1 */
  currency: string;
  fromCurrency?: string;
  toCurrency?: string;
  comment?: string;
  creditCardId?: number;
  bankAccountId?: number;
  paymentGatewayName?: string;
  txId?: string;
}

export type RequestWithdrawData = any;

export interface GetDepositsPayload {
  cuid: string;
}

export interface GetDepositsData {
  firstDeposit: {
    value: number;
    currency: string;
    /** @format date-time */
    date: string;
  } | null;
  lastDeposit: {
    value: number;
    currency: string;
    /** @format date-time */
    date: string;
  } | null;
}

export type GetAllOutput1 = {
  /**
   * @minLength 3
   * @maxLength 50
   */
  name: string;
  logo: string;
  currencies: string[];
  enabled: boolean;
  autoApprove: boolean;
  type: 'PAYMENT_GATEWAY' | 'BANK_ACCOUNT';
  accountNumber?: string | null;
  iban?: string | null;
  shownToClients?: boolean | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  balance?: Record<string, number>;
  entities?: {
    name: string;
    description: string | null;
  }[];
}[];

export interface CreateInput {
  /**
   * @minLength 3
   * @maxLength 50
   */
  name: string;
  logo: string;
  currencies: string[];
  enabled: boolean;
  autoApprove: boolean;
  type: 'PAYMENT_GATEWAY' | 'BANK_ACCOUNT';
  accountNumber?: string | null;
  iban?: string | null;
  shownToClients?: boolean | null;
}

export interface CreateOutput {
  /**
   * @minLength 3
   * @maxLength 50
   */
  name: string;
  logo: string;
  currencies: string[];
  enabled: boolean;
  autoApprove: boolean;
  type: 'PAYMENT_GATEWAY' | 'BANK_ACCOUNT';
  accountNumber?: string | null;
  iban?: string | null;
  shownToClients?: boolean | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface SoftDeleteManyPayload {
  names: string[];
}

export type SoftDeleteManyData = any;

export interface UpdateInput {
  enabled: boolean;
}

export interface UpdateOutput {
  /**
   * @minLength 3
   * @maxLength 50
   */
  name: string;
  logo: string;
  currencies: string[];
  enabled: boolean;
  autoApprove: boolean;
  type: 'PAYMENT_GATEWAY' | 'BANK_ACCOUNT';
  accountNumber?: string | null;
  iban?: string | null;
  shownToClients?: boolean | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export type SoftDeleteData = any;

export type GetBalanceData = Record<string, number>;

export interface UpsertConfigPayload {
  configName: string;
  configData?: any;
  encrypted: boolean;
  id?: number;
}

export interface UpsertConfigData {
  configName: string;
  configData?: any;
  encrypted: boolean;
  createdAt: string;
  id?: number;
}

export type GetLatestData = {
  configName: string;
  configData?: any;
  encrypted: boolean;
  createdAt: string;
  id?: number;
}[];

export type GetByNameData = any;

export type GetHistoryByNameData = {
  configName: string;
  configData?: any;
  encrypted: boolean;
  createdAt: string;
  id?: number;
}[];

export interface DeleteManyByNamePayload {
  names: string[];
}

export type DeleteManyByNameData = any;

export type GetAllOutput2 = {
  /** @minLength 2 */
  name: string;
  description: string | null;
  _count: {
    kyc_form: number;
    user_kyc_form: number;
  };
}[];

export interface EntitiesCreatePayload {
  /** @minLength 2 */
  name: string;
  description: string | null;
}

export interface EntitiesCreateData {
  name: string;
  description?: string | null;
}

export interface DeleteByNameData {
  name: string;
}

export interface UpdateByNamePayload {
  /** @minLength 2 */
  name?: string;
  description?: string | null;
}

export interface UpdateByNameData {
  /** @minLength 2 */
  name: string;
  description: string | null;
}

export interface CreateKycFormPayload {
  entityName: string;
  formName: string;
}

export type CreateKycFormData = any;

export type GetKycFormsData = {
  name: string;
  entityName: string;
}[];

export type AdminGetFormQuestionsData = any;

export interface AdminLinkQuestionsPayload {
  questions: string;
}

export type AdminLinkQuestionsData = any;

export interface AdminLinkExtendedFieldsPayload {
  extendedFieldsNames: string[];
}

export type AdminLinkExtendedFieldsData = any;

export type GetAllResult1 = {
  name: string;
  required: boolean;
  defaultValue?: any;
  section?: string | null;
  pattern?: string | null;
  options?: string[];
  type: string;
  min?: number | null;
  max?: number | null;
  description?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}[];

export interface UpsertPayload {
  name: string;
  /** @default false */
  required?: boolean;
  defaultValue?: any;
  min?: number;
  max?: number;
  /** @default "text" */
  type?: string;
  description?: string;
  /** @default "default" */
  section?: string;
  pattern?: string;
  options?: string[];
}

export interface UpsertData {
  name: string;
  required: boolean;
  defaultValue?: any;
  section?: string | null;
  pattern?: string | null;
  options?: string[];
  type: string;
  min?: number | null;
  max?: number | null;
  description?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface DeleteManyBody {
  names: string[];
}

export interface DeleteManyResult {
  message: string;
}

export interface DeleteResult {
  message: string;
}

export type GetSectionsData = string[];

export interface UpsertQuestionPayload {
  name: string;
  type: string;
  required: boolean;
  defaultValue?: string;
  min?: number;
  max?: number;
  /** @default 1 */
  page?: number;
  /** @default 1 */
  order?: number;
  pattern?: string;
  logic?: string[];
  options?: string;
  showIf?: string;
  /** @default false */
  published?: boolean;
}

export interface UpsertQuestionData {
  name: string;
  type: string;
  required: boolean;
  defaultValue: string | null;
  min: number | null;
  max: number | null;
  /** @default 1 */
  page?: number;
  /** @default 1 */
  order?: number;
  pattern: string | null;
  logic: string[] | null;
  options: string | null;
  showIf: string | null;
  /** @default false */
  published?: boolean;
}

export interface DeleteQuestionData {
  name: string;
  type: string;
  required: boolean;
  defaultValue: string | null;
  min: number | null;
  max: number | null;
  /** @default 1 */
  page?: number;
  /** @default 1 */
  order?: number;
  pattern: string | null;
  logic: string[] | null;
  options: string | null;
  showIf: string | null;
  /** @default false */
  published?: boolean;
}

export type GetAllQuestionsNamesData = string[];

export type GetAllUsersData = {
  id: string;
  status: 'PENDING' | 'APPROVED' | string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface CreateUserPayload {
  userId: string;
  formId: string;
}

export interface CreateUserData {
  id: string;
  status: 'PENDING' | 'APPROVED' | string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface SoftDeleteManyUsersByIdsPayload {
  ids: string[];
}

export interface SoftDeleteManyUsersByIdsData {
  message: string;
}

export interface UpdateUserPayload {
  formId: string;
}

export interface UpdateUserData {
  id: string;
  status: 'PENDING' | 'APPROVED' | string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface SoftDeleteUserByIdData {
  message: string;
}

export interface GetUserByKycFormIdData {
  id: string;
  status: 'PENDING' | 'APPROVED' | string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface SyncUserStatusData {
  id: string;
  status: 'PENDING' | 'APPROVED' | string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export type GetAllAccountsData = {
  id: string;
  cuid: string;
  accountNo: string;
  status: 'PENDING' | 'OPEN' | 'OPEN_NO_NEW_TRADES' | 'FROZEN' | 'CLOSED' | string;
  accountType: string;
  trading_account?: {
    external_id: string;
    id: string;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface SoftDeleteManyAccountsByIdsPayload {
  ids: string[];
}

export interface SoftDeleteManyAccountsByIdsData {
  message: string;
}

export interface CreateAccountPayload {
  /** @minLength 1 */
  tradingAccountId: string;
}

export interface CreateAccountData {
  id: string;
  cuid: string;
  accountNo: string;
  status: 'PENDING' | 'OPEN' | 'OPEN_NO_NEW_TRADES' | 'FROZEN' | 'CLOSED' | string;
  accountType: string;
  trading_account?: {
    external_id: string;
    id: string;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export type GetAllAccountsByUserIdData = {
  id: string;
  cuid: string;
  accountNo: string;
  status: 'PENDING' | 'OPEN' | 'OPEN_NO_NEW_TRADES' | 'FROZEN' | 'CLOSED' | string;
  accountType: string;
  trading_account?: {
    external_id: string;
    id: string;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface SyncAccountStatusData {
  id: string;
  cuid: string;
  accountNo: string;
  status: 'PENDING' | 'OPEN' | 'OPEN_NO_NEW_TRADES' | 'FROZEN' | 'CLOSED' | string;
  accountType: string;
  trading_account?: {
    external_id: string;
    id: string;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface CreateBody1 {
  /** @default "" */
  comment?: string;
  /** @minLength 1 */
  title: string;
  enabled: boolean;
}

export interface CreateResult1 {
  id: number;
  /** @default "" */
  comment?: string;
  /** @minLength 1 */
  title: string;
  enabled: boolean;
  createdBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  modifiedBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export type GetAllResult2 = {
  id: number;
  /** @default "" */
  comment?: string;
  /** @minLength 1 */
  title: string;
  enabled: boolean;
  createdBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  modifiedBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}[];

export interface DeleteManyInput {
  ids: number[];
}

export type GetMinimalData = {
  /** @minLength 1 */
  title: string;
  /** @default "" */
  comment?: string;
}[];

export interface UpdateBody1 {
  /** @default "" */
  comment?: string;
  enabled?: boolean;
}

export interface UpdateResult1 {
  id: number;
  /** @default "" */
  comment?: string;
  /** @minLength 1 */
  title: string;
  enabled: boolean;
  createdBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  modifiedBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface DeleteOutput {
  id: number;
  /** @default "" */
  comment?: string;
  /** @minLength 1 */
  title: string;
  enabled: boolean;
  createdBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  modifiedBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface CreateBody2 {
  section: string;
  key: string;
  value: string;
  language: string;
}

export interface CreateResult2 {
  id: string;
  section: string;
  key: string;
  value: string;
  language: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface SoftDeleteManyByIdsBody {
  ids: string[];
}

export interface SoftDeleteManyByIdsResult {
  message: string;
}

export type GetAllOutput3 = {
  id: string;
  section: string;
  key: string;
  value: string;
  language: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface UpdateByIdPayload {
  section?: string;
  key?: string;
  value?: string;
  language?: string;
}

export interface UpdateByIdData {
  id: string;
  section: string;
  key: string;
  value: string;
  language: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface SoftDeleteByIdResult {
  id: string;
  section: string;
  key: string;
  value: string;
  language: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface GetI18NByIdData {
  id: string;
  section: string;
  key: string;
  value: string;
  language: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface DeleteByIdData {
  message: string;
}

export interface DeleteManyByIdsPayload {
  ids: string[];
}

export interface DeleteManyByIdsData {
  message: string;
}

export type GetAllResult3 = {
  id: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  api_key: string;
  type: 'MT5' | 'MT4' | 'C2C';
  /** @format uri */
  uri: string;
  is_active: boolean;
  is_demo: boolean;
  kyc_rules?: any;
  /** @format date-time */
  createdAt: string;
  isSyncing: boolean;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface CreateBody3 {
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /** @format uri */
  uri: string;
  api_key: string;
  type: 'MT5' | 'MT4' | 'C2C';
  is_demo: boolean;
}

export interface CreateResult3 {
  id: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  api_key: string;
  type: 'MT5' | 'MT4' | 'C2C';
  /** @format uri */
  uri: string;
  is_active: boolean;
  is_demo: boolean;
  kyc_rules?: any;
  /** @format date-time */
  createdAt: string;
  isSyncing: boolean;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface DeleteMultipleByIdsPayload {
  ids: string[];
}

export type DeleteMultipleByIdsData = any;

export type GetAllByUserIdData = {
  id: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  api_key: string;
  type: 'MT5' | 'MT4' | 'C2C';
  /** @format uri */
  uri: string;
  is_active: boolean;
  is_demo: boolean;
  kyc_rules?: any;
  /** @format date-time */
  createdAt: string;
  isSyncing: boolean;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface GetByIdData {
  id: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  api_key: string;
  type: 'MT5' | 'MT4' | 'C2C';
  /** @format uri */
  uri: string;
  is_active: boolean;
  is_demo: boolean;
  kyc_rules?: any;
  /** @format date-time */
  createdAt: string;
  isSyncing: boolean;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface UpdateInput1 {
  /**
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /** @format uri */
  uri?: string;
  api_key?: string;
  type?: 'MT5' | 'MT4' | 'C2C';
  is_demo?: boolean;
}

export interface UpdateData1 {
  id: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  api_key: string;
  type: 'MT5' | 'MT4' | 'C2C';
  /** @format uri */
  uri: string;
  is_active: boolean;
  is_demo: boolean;
  kyc_rules?: any;
  /** @format date-time */
  createdAt: string;
  isSyncing: boolean;
  /** @format date-time */
  deletedAt?: string | null;
}

export type DeleteResult1 = any;

export interface GetGroupsByConfigPayload {
  /** @format uri */
  uri: string;
  api_key: string;
}

export type GetGroupsByConfigData = string[];

export interface VerifyMtServerPayload {
  /** @format uri */
  uri: string;
  api_key: string;
}

export interface VerifyMtServerData {
  message: string;
}

export type GetAllByServerIdOutput = {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  user?: {
    email: string;
    cuid: string;
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
  account_type?: {
    name: string;
    group: string;
    id: number;
  };
  cuid?: string;
  isDemo?: boolean;
}[];

export interface GetByIdResult {
  /** The login of a user. */
  Login?: string;
  /** User group. */
  Group?: string;
  /** Flags of the users permissions. Passed using a value of the EnUserRights enumeration (sum of values of appropriate flags). */
  Rights?: number;
  /** The name of the client. Obsolete field. */
  Name?: string;
  /** The size of a user's leverage. */
  Leverage?: string;
  /** The number of a last used certificate for user authorization. */
  CertSerialNumber?: string;
  /** The user record creation date. */
  Registration?: string;
  /** The date of the last connection using the account. */
  LastAccess?: string;
  /** The IP address from which the user last connected to the server. */
  LastIP?: string;
  /** The date of the last password change. */
  LastPassChange?: string;
  /** The first name of the client. */
  FirstName?: string;
  /** The last name of the client. */
  LastName?: string;
  /** The middle name of the client. */
  MiddleName?: string;
  /** The name of user's company. */
  Company?: string;
  /** The number of a user's account in an external bank. */
  Account?: string;
  /** The client's country of residence. */
  Country?: string;
  /** User's language in the format LANGID used in MS Windows (value from Prim.lang.identifier). */
  Language?: string;
  /** The client's city of residence. */
  City?: string;
  /** The user's state (region) of residence. */
  State?: string;
  /** The client's zip code. */
  ZIPCode?: string;
  /** The address of the user. */
  Address?: string;
  /** The user's phone number. */
  Phone?: string;
  /** The email address of the user. */
  EMail?: string;
  /** The number of a user's identity document. */
  ID?: string;
  /** Client's status. */
  Status?: string;
  /** A comment to the user. */
  Comment?: string;
  /** The color of the user. This is the color of the user's requests shown when handling the requests via the manager terminal. */
  Color?: string;
  /** The user's phone password. */
  PhonePassword?: string;
  /** Agent account number of the user. */
  Agent?: string;
  /** The current balance of a client. The balance cannot be updated via this field when creating or modifying an account. */
  Balance?: string;
  /** The current amount of funds credited to the client. */
  Credit?: string;
  /** The amount accrued for the current month calculated based on the annual interest rate. */
  InterestRate?: string;
  /** The amount of commissions charged from a client for a day. */
  CommissionDaily?: string;
  /** The total amount of commissions charged from a client for the current month. */
  CommissionMonthly?: string;
  /** The size of agent commissions charged for a client's trade operations for a day, from a daily report. */
  CommissionAgentDaily?: string;
  /** The amount of agent commissions charged for a client's trade operations for the current month. */
  CommissionAgentMonthly?: string;
  /** Client's balance as of the end of the previous day. */
  BalancePrevDay?: string;
  /** Client's balance as of the end of the previous trading month. */
  BalancePrevMonth?: string;
  /** A client's equity as of the end of the previous day. */
  EquityPrevDay?: string;
  /** The value of a client's equity as of the end of the previous trading month. */
  EquityPrevMonth?: string;
  /** MetaQuotes ID of the user. */
  MQID?: string;
  /** User account numbers in external trading systems. The total length of the accounts and IDs stored for an account is limited to 128 characters. */
  TradeAccounts?: string;
  /** A lead source — a website a client has come from. */
  LeadSource?: string;
  /** A lead campaign — name of a marketing campaign a client was attracted by. */
  LeadCampaign?: string;
  /** The maximum number of active (placed) pending orders allowed on the account. */
  LimitOrders?: string;
  /** Maximum value of open positions allowed on the account. */
  LimitPositions?: string;
  /** List representation of user rights */
  Permissions?: (
    | 'USER_RIGHT_ENABLED'
    | 'USER_RIGHT_PASSWORD'
    | 'USER_RIGHT_TRADE_DISABLED'
    | 'USER_RIGHT_INVESTOR'
    | 'USER_RIGHT_CONFIRMED'
    | 'USER_RIGHT_TRAILING'
    | 'USER_RIGHT_EXPERT'
    | 'USER_RIGHT_OBSOLETE'
    | 'USER_RIGHT_REPORTS'
    | 'USER_RIGHT_READONLY'
    | 'USER_RIGHT_RESET_PASS'
    | 'USER_RIGHT_OTP_ENABLED'
    | 'USER_RIGHT_SPONSORED_HOSTING'
    | 'USER_RIGHT_API_ENABLED'
    | 'USER_RIGHT_PUSH_NOTIFICATION'
    | 'USER_RIGHT_TECHNICAL'
    | 'USER_RIGHT_EXCLUDE_REPORTS'
  )[];
}

export interface UpdateBody2 {
  accountTypeId?: number;
  /** @default false */
  isDemo?: boolean;
  data?: {
    /**
     * @minLength 1
     * @maxLength 128
     */
    name?: string;
    /** @format email */
    email?: string;
    /**
     * @min 1
     * @max 500
     */
    leverage?: number;
  };
}

export interface UpdateResult2 {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export type DeleteData1 = any;

export type GetInternalBalanceData = Record<string, number>;

export type GetAllByUserIdResult = {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
  cuid?: string;
  account?: {
    /** The currency of the trading account. */
    currency?: string;
    /** The type of the trading account MT5/MT4/C2C. */
    type?: string;
    /** The login of the user, to whom the trading account belongs. */
    login?: string;
    /** The number of digits after the decimal point in the account deposit currency. */
    currency_digits?: number;
    /** The balance of a trade account. */
    balance?: number;
    /** The current amount of credit given to an account. */
    credit?: number;
    /** The current amount of credit given to an account. */
    margin?: number;
    /** The free margin of an account. */
    margin_free?: number;
    /** The margin level as a percentage. It is calculated as a percentage of the current account equity (Equity) to the margin volume (Margin). */
    margin_level?: number;
    /** The current size of the initial margin of positions on a trading account. */
    margin_initial?: number;
    /** The current size of the maintenance margin of positions on a trading account. */
    margin_maintenance?: number;
    /** The size of the current profit for all open positions. */
    profit?: number;
    /** The current size of swaps charged for open positions on the account. */
    storage?: number;
    /** The size of floating profit/loss of open positions on the account. The floating profit/loss is calculated as the sum of Profit, Storage and Commission of open positions on the account. */
    floating?: number;
    /** The account equity calculated as a sum of Balance, Credit and Floating. */
    equity?: number;
    /** The current total amount of assets on a trading account. */
    assets?: number;
    /** The current total amount of liabilities on a trading account. */
    liabilities?: number;
    /** The account status as per the minimum amount of funds on the account required to maintain trading positions. */
    so_activation?: number;
    /** The time when the Margin Call or Stop Out level was reached, in seconds that had elapsed since 01.01.1970. */
    so_time?: number;
    /** The margin level of an account at the time it reaches the Stop Out level. */
    so_level?: number;
    /** The equity of an account at the time it reaches the Stop Out level. */
    so_equity?: number;
    /** The margin of an account at the time it reaches the Stop Out level. */
    so_margin?: number;
    /** The amount of the standard commission locked on the account, which has been accumulated during the day/month. */
    blocked_commission?: number;
    /** The amount of intraday profit locked on the account. */
    blocked_profit?: number;
    /** Margin leverage. */
    leverage?: number;
    /** The volume. */
    volume?: number;
    /** Margin type. */
    margin_type?: number;
    /** Margin level type. */
    level_type?: number;
  };
  isDemo?: boolean;
}[];

export interface CreateBody4 {
  /** @minLength 1 */
  userId: string;
  accountTypeId: number;
  /** @default false */
  isDemo?: boolean;
  /** @minLength 1 */
  userKycFormId: string;
  data: {
    /**
     * @minLength 1
     * @maxLength 128
     */
    name: string;
    /** @format email */
    email?: string;
    /**
     * @min 1
     * @max 500
     */
    leverage: number;
  };
}

export interface CreateData1 {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  passMain: string;
  passInvestor: string;
  passPhone: string;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export interface DeleteMultipleByIdsBody {
  ids: string[];
}

export type DeleteMultipleByIdsResult = any;

export interface DisableData {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export interface ReactivateData {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export interface ReactivateManyPayload {
  ids: string[];
}

export type ReactivateManyData = any;

export interface DisableManyPayload {
  ids: string[];
}

export type DisableManyData = any;

export interface UnlinkAccountData {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export interface UnlinkManyAccountsPayload {
  ids: string[];
}

export type UnlinkManyAccountsData = any;

export interface LinkAccountPayload {
  /** @minLength 1 */
  login: string;
  /** @minLength 1 */
  userId: string;
  /** @minLength 1 */
  userKycFormId: string;
  accountTypeId: number;
}

export interface LinkAccountData {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export interface LinkExistingAccountPayload {
  /** @minLength 1 */
  login: string;
  /** @minLength 1 */
  userId: string;
  /** @minLength 1 */
  userKycFormId: string;
  accountTypeId: number;
  /** @minLength 1 */
  tradingAccountId: string;
}

export interface LinkExistingAccountData {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export interface CreateFromKycFormPayload {
  /** @minLength 1 */
  userId: string;
  /** @minLength 1 */
  kycFormId: string;
  accountTypeName?: string;
  isDemo?: boolean;
  metadata?: Record<string, string>;
}

export interface CreateFromKycFormData {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export interface ResetPasswordData {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  passMain: string;
  passInvestor: string;
  passPhone: string;
}

export type GetExternalAccountsData = {
  /** The currency of the trading account. */
  currency?: string;
  /** The type of the trading account MT5/MT4/C2C. */
  type?: string;
  /** The login of the user, to whom the trading account belongs. */
  login?: string;
  /** The number of digits after the decimal point in the account deposit currency. */
  currency_digits?: number;
  /** The balance of a trade account. */
  balance?: number;
  /** The current amount of credit given to an account. */
  credit?: number;
  /** The current amount of credit given to an account. */
  margin?: number;
  /** The free margin of an account. */
  margin_free?: number;
  /** The margin level as a percentage. It is calculated as a percentage of the current account equity (Equity) to the margin volume (Margin). */
  margin_level?: number;
  /** The current size of the initial margin of positions on a trading account. */
  margin_initial?: number;
  /** The current size of the maintenance margin of positions on a trading account. */
  margin_maintenance?: number;
  /** The size of the current profit for all open positions. */
  profit?: number;
  /** The current size of swaps charged for open positions on the account. */
  storage?: number;
  /** The size of floating profit/loss of open positions on the account. The floating profit/loss is calculated as the sum of Profit, Storage and Commission of open positions on the account. */
  floating?: number;
  /** The account equity calculated as a sum of Balance, Credit and Floating. */
  equity?: number;
  /** The current total amount of assets on a trading account. */
  assets?: number;
  /** The current total amount of liabilities on a trading account. */
  liabilities?: number;
  /** The account status as per the minimum amount of funds on the account required to maintain trading positions. */
  so_activation?: number;
  /** The time when the Margin Call or Stop Out level was reached, in seconds that had elapsed since 01.01.1970. */
  so_time?: number;
  /** The margin level of an account at the time it reaches the Stop Out level. */
  so_level?: number;
  /** The equity of an account at the time it reaches the Stop Out level. */
  so_equity?: number;
  /** The margin of an account at the time it reaches the Stop Out level. */
  so_margin?: number;
  /** The amount of the standard commission locked on the account, which has been accumulated during the day/month. */
  blocked_commission?: number;
  /** The amount of intraday profit locked on the account. */
  blocked_profit?: number;
  /** Margin leverage. */
  leverage?: number;
  /** The volume. */
  volume?: number;
  /** Margin type. */
  margin_type?: number;
  /** Margin level type. */
  level_type?: number;
}[];

export interface GetBalanceResult {
  /** The currency of the trading account. */
  currency?: string;
  /** The type of the trading account MT5/MT4/C2C. */
  type?: string;
  /** The login of the user, to whom the trading account belongs. */
  login?: string;
  /** The number of digits after the decimal point in the account deposit currency. */
  currency_digits?: number;
  /** The balance of a trade account. */
  balance?: number;
  /** The current amount of credit given to an account. */
  credit?: number;
  /** The current amount of credit given to an account. */
  margin?: number;
  /** The free margin of an account. */
  margin_free?: number;
  /** The margin level as a percentage. It is calculated as a percentage of the current account equity (Equity) to the margin volume (Margin). */
  margin_level?: number;
  /** The current size of the initial margin of positions on a trading account. */
  margin_initial?: number;
  /** The current size of the maintenance margin of positions on a trading account. */
  margin_maintenance?: number;
  /** The size of the current profit for all open positions. */
  profit?: number;
  /** The current size of swaps charged for open positions on the account. */
  storage?: number;
  /** The size of floating profit/loss of open positions on the account. The floating profit/loss is calculated as the sum of Profit, Storage and Commission of open positions on the account. */
  floating?: number;
  /** The account equity calculated as a sum of Balance, Credit and Floating. */
  equity?: number;
  /** The current total amount of assets on a trading account. */
  assets?: number;
  /** The current total amount of liabilities on a trading account. */
  liabilities?: number;
  /** The account status as per the minimum amount of funds on the account required to maintain trading positions. */
  so_activation?: number;
  /** The time when the Margin Call or Stop Out level was reached, in seconds that had elapsed since 01.01.1970. */
  so_time?: number;
  /** The margin level of an account at the time it reaches the Stop Out level. */
  so_level?: number;
  /** The equity of an account at the time it reaches the Stop Out level. */
  so_equity?: number;
  /** The margin of an account at the time it reaches the Stop Out level. */
  so_margin?: number;
  /** The amount of the standard commission locked on the account, which has been accumulated during the day/month. */
  blocked_commission?: number;
  /** The amount of intraday profit locked on the account. */
  blocked_profit?: number;
  /** Margin leverage. */
  leverage?: number;
  /** The volume. */
  volume?: number;
  /** Margin type. */
  margin_type?: number;
  /** Margin level type. */
  level_type?: number;
}

export interface GetBalancesPayload {
  ids: string[];
}

export type GetBalancesData = Record<
  string,
  | {
      /** The currency of the trading account. */
      currency?: string;
      /** The type of the trading account MT5/MT4/C2C. */
      type?: string;
      /** The login of the user, to whom the trading account belongs. */
      login?: string;
      /** The number of digits after the decimal point in the account deposit currency. */
      currency_digits?: number;
      /** The balance of a trade account. */
      balance?: number;
      /** The current amount of credit given to an account. */
      credit?: number;
      /** The current amount of credit given to an account. */
      margin?: number;
      /** The free margin of an account. */
      margin_free?: number;
      /** The margin level as a percentage. It is calculated as a percentage of the current account equity (Equity) to the margin volume (Margin). */
      margin_level?: number;
      /** The current size of the initial margin of positions on a trading account. */
      margin_initial?: number;
      /** The current size of the maintenance margin of positions on a trading account. */
      margin_maintenance?: number;
      /** The size of the current profit for all open positions. */
      profit?: number;
      /** The current size of swaps charged for open positions on the account. */
      storage?: number;
      /** The size of floating profit/loss of open positions on the account. The floating profit/loss is calculated as the sum of Profit, Storage and Commission of open positions on the account. */
      floating?: number;
      /** The account equity calculated as a sum of Balance, Credit and Floating. */
      equity?: number;
      /** The current total amount of assets on a trading account. */
      assets?: number;
      /** The current total amount of liabilities on a trading account. */
      liabilities?: number;
      /** The account status as per the minimum amount of funds on the account required to maintain trading positions. */
      so_activation?: number;
      /** The time when the Margin Call or Stop Out level was reached, in seconds that had elapsed since 01.01.1970. */
      so_time?: number;
      /** The margin level of an account at the time it reaches the Stop Out level. */
      so_level?: number;
      /** The equity of an account at the time it reaches the Stop Out level. */
      so_equity?: number;
      /** The margin of an account at the time it reaches the Stop Out level. */
      so_margin?: number;
      /** The amount of the standard commission locked on the account, which has been accumulated during the day/month. */
      blocked_commission?: number;
      /** The amount of intraday profit locked on the account. */
      blocked_profit?: number;
      /** Margin leverage. */
      leverage?: number;
      /** The volume. */
      volume?: number;
      /** Margin type. */
      margin_type?: number;
      /** Margin level type. */
      level_type?: number;
    }
  | {
      error: string;
    }
>;

export type GetPositionsData = any;

export type GetOrdersData = any;

export type GetHistoryData = {
  /** Order ticket. */
  order?: string;
  /** The order ID in external trading systems. */
  externalID?: string;
  /** The login of the client, to whom the order belongs. */
  login?: string;
  /** The login of a dealer, who has processed an order. */
  dealer?: string;
  /** Order symbol. Only makes sense when used together with the `login` parameter. */
  symbol?: string;
  /** The number of decimal places in the price of an order. */
  digits?: string;
  /** The number of decimal places the deposit currency of the client who has placed the order. */
  digitsCurrency?: string;
  /** The contract size of the symbol, for which an order was placed. */
  contractSize?: string;
  /** The current state of an order. */
  state?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  /** The reason for performing a deal. */
  reason?: string;
  /** Order filling type. */
  typeFill?: '0' | '1' | '2';
  /** Order expiration type. */
  typeTime?: '0' | '1' | '2' | '3';
  /** Order activation type. */
  activationMode?: '0' | '1' | '2' | '3' | '4';
  /** Order type. */
  type?: '0' | '1' | '2' | '3' | '4' | '5';
  /** The time of order placing in seconds that have elapsed since 01.01.1970. */
  timeSetup?: string;
  /** The time of order expiration in seconds that have elapsed since 01.01.1970. */
  timeExpiration?: string;
  /** The time of order execution in seconds that have elapsed since 01.01.1970. */
  timeDone?: string;
  /** Order price. */
  priceOrder?: string;
  /** The price, at which a Limit order is placed when the Stop Limit order triggers. */
  priceTrigger?: string;
  /** The current price of the symbol, for which an order has been placed. */
  priceCurrent?: string;
  /** The Stop Loss level of an order. */
  priceSL?: string;
  /** The Take Profit level of an order. */
  priceTP?: string;
  /** The initial order volume. One unit corresponds to 1/10000 lot. */
  volumeInitial?: string;
  /** The initial order volume with an extended accuracy. One unit corresponds to 1/100000000 lot. */
  volumeInitialExt?: string;
  /** The current unfilled volume of an order. One unit corresponds to 1/10000 lot. */
  volumeCurrent?: string;
  /** The current unfilled volume of an order with an extended accuracy. One unit corresponds to 1/100000000 lot. */
  volumeCurrentExt?: string;
  /** The ID of the Expert Advisor that has placed the order. */
  expertID?: string;
  /** The position ID (ticket) set in the order. */
  positionID?: string;
  /** The opposite position ID (ticket) set in the order. The property is set for Close By operations (OP_CLOSE_BY). The ticket of the position that is closed by the opposite one is set in PositionID. */
  positionByID?: string;
  /** Comment to an order. */
  comment?: string;
  /** The time of order activation in seconds that have elapsed since 01.01.1970. */
  activationTime?: string;
  /** The price, at which the order was activated. */
  activationPrice?: string;
  /** Order activation flags. Passed as a value of the EnTradeActivationFlags enumeration (sum of values of appropriate flags). */
  activationFlags?: string;
  /** The order placing time in milliseconds. */
  timeSetupMsc?: string;
  /** The order execution time in milliseconds. */
  timeDoneMsc?: string;
  /** The rate of conversion of the margin currency of the symbol to the deposit currency of the user, which is used when calculating the margin requirements for the order. */
  rateMargin?: string;
  /** The order modification flags. Passed as a value of the EnTradeModifyFlags enumeration (sum of values of appropriate flags). */
  modificationFlags?: string;
  /** The commission charged for an order. */
  commission?: number;
  /** The swap charged for an order. The value is specified in the deposit currency of the user. */
  swap?: number;
  /** The volume of an order in lots. */
  lots?: number;
  /** The profit earned for an order. */
  profit?: number;
  /** The profit earned for an order in pips. */
  pips?: number;
}[];

export interface GetDataData {
  balance: {
    /** The currency of the trading account. */
    currency?: string;
    /** The type of the trading account MT5/MT4/C2C. */
    type?: string;
    /** The login of the user, to whom the trading account belongs. */
    login?: string;
    /** The number of digits after the decimal point in the account deposit currency. */
    currency_digits?: number;
    /** The balance of a trade account. */
    balance?: number;
    /** The current amount of credit given to an account. */
    credit?: number;
    /** The current amount of credit given to an account. */
    margin?: number;
    /** The free margin of an account. */
    margin_free?: number;
    /** The margin level as a percentage. It is calculated as a percentage of the current account equity (Equity) to the margin volume (Margin). */
    margin_level?: number;
    /** The current size of the initial margin of positions on a trading account. */
    margin_initial?: number;
    /** The current size of the maintenance margin of positions on a trading account. */
    margin_maintenance?: number;
    /** The size of the current profit for all open positions. */
    profit?: number;
    /** The current size of swaps charged for open positions on the account. */
    storage?: number;
    /** The size of floating profit/loss of open positions on the account. The floating profit/loss is calculated as the sum of Profit, Storage and Commission of open positions on the account. */
    floating?: number;
    /** The account equity calculated as a sum of Balance, Credit and Floating. */
    equity?: number;
    /** The current total amount of assets on a trading account. */
    assets?: number;
    /** The current total amount of liabilities on a trading account. */
    liabilities?: number;
    /** The account status as per the minimum amount of funds on the account required to maintain trading positions. */
    so_activation?: number;
    /** The time when the Margin Call or Stop Out level was reached, in seconds that had elapsed since 01.01.1970. */
    so_time?: number;
    /** The margin level of an account at the time it reaches the Stop Out level. */
    so_level?: number;
    /** The equity of an account at the time it reaches the Stop Out level. */
    so_equity?: number;
    /** The margin of an account at the time it reaches the Stop Out level. */
    so_margin?: number;
    /** The amount of the standard commission locked on the account, which has been accumulated during the day/month. */
    blocked_commission?: number;
    /** The amount of intraday profit locked on the account. */
    blocked_profit?: number;
    /** Margin leverage. */
    leverage?: number;
    /** The volume. */
    volume?: number;
    /** Margin type. */
    margin_type?: number;
    /** Margin level type. */
    level_type?: number;
  };
  positions?: any;
  orders: {
    /** Order ticket. */
    order?: string;
    /** The order ID in external trading systems. */
    externalID?: string;
    /** The login of the client, to whom the order belongs. */
    login?: string;
    /** The login of a dealer, who has processed an order. */
    dealer?: string;
    /** Order symbol. Only makes sense when used together with the `login` parameter. */
    symbol?: string;
    /** The number of decimal places in the price of an order. */
    digits?: string;
    /** The number of decimal places the deposit currency of the client who has placed the order. */
    digitsCurrency?: string;
    /** The contract size of the symbol, for which an order was placed. */
    contractSize?: string;
    /** The current state of an order. */
    state?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
    /** The reason for performing a deal. */
    reason?: string;
    /** Order filling type. */
    typeFill?: '0' | '1' | '2';
    /** Order expiration type. */
    typeTime?: '0' | '1' | '2' | '3';
    /** Order activation type. */
    activationMode?: '0' | '1' | '2' | '3' | '4';
    /** Order type. */
    type?: '0' | '1' | '2' | '3' | '4' | '5';
    /** The time of order placing in seconds that have elapsed since 01.01.1970. */
    timeSetup?: string;
    /** The time of order expiration in seconds that have elapsed since 01.01.1970. */
    timeExpiration?: string;
    /** The time of order execution in seconds that have elapsed since 01.01.1970. */
    timeDone?: string;
    /** Order price. */
    priceOrder?: string;
    /** The price, at which a Limit order is placed when the Stop Limit order triggers. */
    priceTrigger?: string;
    /** The current price of the symbol, for which an order has been placed. */
    priceCurrent?: string;
    /** The Stop Loss level of an order. */
    priceSL?: string;
    /** The Take Profit level of an order. */
    priceTP?: string;
    /** The initial order volume. One unit corresponds to 1/10000 lot. */
    volumeInitial?: string;
    /** The initial order volume with an extended accuracy. One unit corresponds to 1/100000000 lot. */
    volumeInitialExt?: string;
    /** The current unfilled volume of an order. One unit corresponds to 1/10000 lot. */
    volumeCurrent?: string;
    /** The current unfilled volume of an order with an extended accuracy. One unit corresponds to 1/100000000 lot. */
    volumeCurrentExt?: string;
    /** The ID of the Expert Advisor that has placed the order. */
    expertID?: string;
    /** The position ID (ticket) set in the order. */
    positionID?: string;
    /** The opposite position ID (ticket) set in the order. The property is set for Close By operations (OP_CLOSE_BY). The ticket of the position that is closed by the opposite one is set in PositionID. */
    positionByID?: string;
    /** Comment to an order. */
    comment?: string;
    /** The time of order activation in seconds that have elapsed since 01.01.1970. */
    activationTime?: string;
    /** The price, at which the order was activated. */
    activationPrice?: string;
    /** Order activation flags. Passed as a value of the EnTradeActivationFlags enumeration (sum of values of appropriate flags). */
    activationFlags?: string;
    /** The order placing time in milliseconds. */
    timeSetupMsc?: string;
    /** The order execution time in milliseconds. */
    timeDoneMsc?: string;
    /** The rate of conversion of the margin currency of the symbol to the deposit currency of the user, which is used when calculating the margin requirements for the order. */
    rateMargin?: string;
    /** The order modification flags. Passed as a value of the EnTradeModifyFlags enumeration (sum of values of appropriate flags). */
    modificationFlags?: string;
    /** The commission charged for an order. */
    commission?: number;
    /** The swap charged for an order. The value is specified in the deposit currency of the user. */
    swap?: number;
    /** The volume of an order in lots. */
    lots?: number;
    /** The profit earned for an order. */
    profit?: number;
    /** The profit earned for an order in pips. */
    pips?: number;
  }[];
}

export interface GetDataForManyIdsPayload {
  ids: string[];
}

export type GetDataForManyIdsData = Record<
  string,
  | {
      id?: string;
      balance?: {
        /** The currency of the trading account. */
        currency?: string;
        /** The type of the trading account MT5/MT4/C2C. */
        type?: string;
        /** The login of the user, to whom the trading account belongs. */
        login?: string;
        /** The number of digits after the decimal point in the account deposit currency. */
        currency_digits?: number;
        /** The balance of a trade account. */
        balance?: number;
        /** The current amount of credit given to an account. */
        credit?: number;
        /** The current amount of credit given to an account. */
        margin?: number;
        /** The free margin of an account. */
        margin_free?: number;
        /** The margin level as a percentage. It is calculated as a percentage of the current account equity (Equity) to the margin volume (Margin). */
        margin_level?: number;
        /** The current size of the initial margin of positions on a trading account. */
        margin_initial?: number;
        /** The current size of the maintenance margin of positions on a trading account. */
        margin_maintenance?: number;
        /** The size of the current profit for all open positions. */
        profit?: number;
        /** The current size of swaps charged for open positions on the account. */
        storage?: number;
        /** The size of floating profit/loss of open positions on the account. The floating profit/loss is calculated as the sum of Profit, Storage and Commission of open positions on the account. */
        floating?: number;
        /** The account equity calculated as a sum of Balance, Credit and Floating. */
        equity?: number;
        /** The current total amount of assets on a trading account. */
        assets?: number;
        /** The current total amount of liabilities on a trading account. */
        liabilities?: number;
        /** The account status as per the minimum amount of funds on the account required to maintain trading positions. */
        so_activation?: number;
        /** The time when the Margin Call or Stop Out level was reached, in seconds that had elapsed since 01.01.1970. */
        so_time?: number;
        /** The margin level of an account at the time it reaches the Stop Out level. */
        so_level?: number;
        /** The equity of an account at the time it reaches the Stop Out level. */
        so_equity?: number;
        /** The margin of an account at the time it reaches the Stop Out level. */
        so_margin?: number;
        /** The amount of the standard commission locked on the account, which has been accumulated during the day/month. */
        blocked_commission?: number;
        /** The amount of intraday profit locked on the account. */
        blocked_profit?: number;
        /** Margin leverage. */
        leverage?: number;
        /** The volume. */
        volume?: number;
        /** Margin type. */
        margin_type?: number;
        /** Margin level type. */
        level_type?: number;
      };
      positions?: any;
      orders?: {
        /** Order ticket. */
        order?: string;
        /** The order ID in external trading systems. */
        externalID?: string;
        /** The login of the client, to whom the order belongs. */
        login?: string;
        /** The login of a dealer, who has processed an order. */
        dealer?: string;
        /** Order symbol. Only makes sense when used together with the `login` parameter. */
        symbol?: string;
        /** The number of decimal places in the price of an order. */
        digits?: string;
        /** The number of decimal places the deposit currency of the client who has placed the order. */
        digitsCurrency?: string;
        /** The contract size of the symbol, for which an order was placed. */
        contractSize?: string;
        /** The current state of an order. */
        state?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
        /** The reason for performing a deal. */
        reason?: string;
        /** Order filling type. */
        typeFill?: '0' | '1' | '2';
        /** Order expiration type. */
        typeTime?: '0' | '1' | '2' | '3';
        /** Order activation type. */
        activationMode?: '0' | '1' | '2' | '3' | '4';
        /** Order type. */
        type?: '0' | '1' | '2' | '3' | '4' | '5';
        /** The time of order placing in seconds that have elapsed since 01.01.1970. */
        timeSetup?: string;
        /** The time of order expiration in seconds that have elapsed since 01.01.1970. */
        timeExpiration?: string;
        /** The time of order execution in seconds that have elapsed since 01.01.1970. */
        timeDone?: string;
        /** Order price. */
        priceOrder?: string;
        /** The price, at which a Limit order is placed when the Stop Limit order triggers. */
        priceTrigger?: string;
        /** The current price of the symbol, for which an order has been placed. */
        priceCurrent?: string;
        /** The Stop Loss level of an order. */
        priceSL?: string;
        /** The Take Profit level of an order. */
        priceTP?: string;
        /** The initial order volume. One unit corresponds to 1/10000 lot. */
        volumeInitial?: string;
        /** The initial order volume with an extended accuracy. One unit corresponds to 1/100000000 lot. */
        volumeInitialExt?: string;
        /** The current unfilled volume of an order. One unit corresponds to 1/10000 lot. */
        volumeCurrent?: string;
        /** The current unfilled volume of an order with an extended accuracy. One unit corresponds to 1/100000000 lot. */
        volumeCurrentExt?: string;
        /** The ID of the Expert Advisor that has placed the order. */
        expertID?: string;
        /** The position ID (ticket) set in the order. */
        positionID?: string;
        /** The opposite position ID (ticket) set in the order. The property is set for Close By operations (OP_CLOSE_BY). The ticket of the position that is closed by the opposite one is set in PositionID. */
        positionByID?: string;
        /** Comment to an order. */
        comment?: string;
        /** The time of order activation in seconds that have elapsed since 01.01.1970. */
        activationTime?: string;
        /** The price, at which the order was activated. */
        activationPrice?: string;
        /** Order activation flags. Passed as a value of the EnTradeActivationFlags enumeration (sum of values of appropriate flags). */
        activationFlags?: string;
        /** The order placing time in milliseconds. */
        timeSetupMsc?: string;
        /** The order execution time in milliseconds. */
        timeDoneMsc?: string;
        /** The rate of conversion of the margin currency of the symbol to the deposit currency of the user, which is used when calculating the margin requirements for the order. */
        rateMargin?: string;
        /** The order modification flags. Passed as a value of the EnTradeModifyFlags enumeration (sum of values of appropriate flags). */
        modificationFlags?: string;
        /** The commission charged for an order. */
        commission?: number;
        /** The swap charged for an order. The value is specified in the deposit currency of the user. */
        swap?: number;
        /** The volume of an order in lots. */
        lots?: number;
        /** The profit earned for an order. */
        profit?: number;
        /** The profit earned for an order in pips. */
        pips?: number;
      }[];
    }
  | {
      error: string;
    }
>;

export type GetAllData1 = {
  id: number;
  key: string;
  title: string;
  body: string;
  data?: any;
  lang: string;
  channel: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface CreatePayload1 {
  /** @minLength 1 */
  key: string;
  title: string;
  body: string;
  data?: any;
  lang: string;
  channel: string | null;
}

export interface CreateOutput1 {
  id: number;
  key: string;
  title: string;
  body: string;
  data?: any;
  lang: string;
  channel: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface DeleteManyByIdsBody {
  ids: number[];
}

export type DeleteManyByIdsResult = any;

export type GetByLangData = {
  id: number;
  key: string;
  title: string;
  body: string;
  data?: any;
  lang: string;
  channel: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export type GetByKeyData = {
  id: number;
  key: string;
  title: string;
  body: string;
  data?: any;
  lang: string;
  channel: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface GetByIdOutput {
  id: number;
  key: string;
  title: string;
  body: string;
  data?: any;
  lang: string;
  channel: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface UpdateBody3 {
  title?: string;
  body?: string;
  data?: any;
  lang?: string;
  channel?: string | null;
}

export interface UpdateResult3 {
  id: number;
  key: string;
  title: string;
  body: string;
  data?: any;
  lang: string;
  channel: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export type DeleteByIdResult = any;

export interface GetAllResult4 {
  users: {
    id: number;
    cuid: string;
    role: string;
    drivewealthUserId?: string;
    email: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @format date-time */
    loginAt?: string | null;
    /** @format date-time */
    email_verified?: string | null;
    /** @format date-time */
    phone_verified?: string | null;
    fname?: string | null;
    lname?: string | null;
    dob?: string | null;
    primary_phone?: string | null;
    blocked: boolean;
    /** @format date-time */
    blockedAt?: string | null;
    blockReason?: string | null;
    blockedBy?: string | null;
    appdata?: any;
    metadata?: any;
    sum_deposit: number;
  }[];
  total: number;
}

export interface GetByIdResult1 {
  id: number;
  cuid: string;
  role: string;
  drivewealthUserId?: string;
  email: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  loginAt?: string | null;
  /** @format date-time */
  email_verified?: string | null;
  /** @format date-time */
  phone_verified?: string | null;
  fname?: string | null;
  lname?: string | null;
  dob?: string | null;
  primary_phone?: string | null;
  blocked: boolean;
  /** @format date-time */
  blockedAt?: string | null;
  blockReason?: string | null;
  blockedBy?: string | null;
  appdata?: any;
  metadata?: any;
  sum_deposit: number;
}

export type GetWalletsBalanceData = Record<string, number>;

export interface CreatePayload2 {
  name: string;
  color: string;
  background: string;
  icon: string | null;
}

export interface CreateResult4 {
  name: string;
  color: string;
  background: string;
  icon: string | null;
}

export type GetAllData2 = {
  name: string;
  color: string;
  background: string;
  icon: string | null;
}[];

export interface UpdateBody4 {
  color: string;
  background: string;
  icon: string | null;
}

export interface UpdateResult4 {
  name: string;
  color: string;
  background: string;
  icon: string | null;
}

export type GetAllUserKycFormTagsData = {
  name: string;
  color: string;
  background: string;
  icon: string | null;
}[];

export interface AssignTagToUserKycFormData {
  name: string;
  color: string;
  background: string;
  icon: string | null;
}

export type RemoveTagFromUserKycFormData = any;

export interface UserNoteCreatePayload {
  note: string;
}

export interface UserNoteCreateData {
  id: number;
  userId: string;
  addedByUserCuid: string;
  note: string;
  /** @format date-time */
  createdAt: string;
  entityName: string;
}

export type UserNoteDetailData = any;

export interface UserNoteUpdatePayload {
  note: string;
}

export interface UserNoteUpdateData {
  id: number;
  userId: string;
  addedByUserCuid: string;
  note: string;
  /** @format date-time */
  createdAt: string;
  entityName: string;
}

export interface CreateBankAccountPayload {
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  entityName: string;
  userId: string;
  gatewayName: string;
  externalId: string;
  defaultForEntity?: boolean;
}

export interface CreateBankAccountData {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}

export interface RejectOrApproveBankAccountData {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}

export type GetBankAccountsForUserData = {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}[];

export interface ArchiveBankAccountData {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}

export interface ToggleBankAccountDefaultPayload {
  defaultForEntity: boolean;
}

export interface ToggleBankAccountDefaultData {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}

export interface CreateCreditCardTypePayload {
  name: string;
}

export interface CreateCreditCardTypeData {
  id: number;
  name: string;
}

export type GetAllCreditCardTypesData = {
  id: number;
  name: string;
}[];

export interface CreateCreditCardPayload {
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  entityName: string;
  userId: string;
  gatewayName: string;
  typeId: number;
  defaultForEntity?: boolean;
}

export interface CreateCreditCardData {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}

export interface RejectOrApproveCreditCardData {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}

export type GetCreditCardsForUserData = {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}[];

export interface ArchiveCreditCardData {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}

export interface MakeCreditCardDefaultPayload {
  defaultForEntity: boolean;
}

export interface MakeCreditCardDefaultData {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}

export type GetAllUserFieldsData = string[];

export type GetAllMetadataFieldsData = string[];

export type GetAllTransactionFieldsData = string[];

export type GetAllTransactionEntryFieldsData = string[];

export type GetAllUserKycFormFieldsData = string[];

export type GetAllTradingAccountFieldsData = string[];

export type GetAllExchangeRatesData = {
  id: number;
  group?: string | null;
  symbol?: string | null;
  to: string;
  from: string;
  server: {
    id: string;
    /**
     * @minLength 1
     * @maxLength 255
     */
    name: string;
    type: 'MT5' | 'MT4' | 'C2C';
  };
  rate: number;
  entity: {
    name: string;
    description: string | null;
  };
  actionBy: 'USER' | 'SYSTEM';
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  updatedBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  createdBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
}[];

export interface CreateExchangeRatePayload {
  group?: string;
  symbol?: string;
  to: string;
  from: string;
  /** @minLength 1 */
  serverId: string;
  /** @minLength 1 */
  entityName: string;
  /** @default 1 */
  rate?: number;
  addAsConfig?: boolean;
  schedule?: string;
}

export interface CreateExchangeRateData {
  id: number;
  group?: string | null;
  symbol?: string | null;
  to: string;
  from: string;
  server: {
    id: string;
    /**
     * @minLength 1
     * @maxLength 255
     */
    name: string;
    type: 'MT5' | 'MT4' | 'C2C';
  };
  rate: number;
  entity: {
    name: string;
    description: string | null;
  };
  actionBy: 'USER' | 'SYSTEM';
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  updatedBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  createdBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
}

export interface UpdateExchangeRatePayload {
  group?: string;
  symbol?: string;
  to?: string;
  from?: string;
  /** @minLength 1 */
  serverId?: string;
  /** @minLength 1 */
  entityName?: string;
  /** @default 1 */
  rate?: number;
  addAsConfig?: boolean;
  schedule?: string;
}

export interface UpdateExchangeRateData {
  id: number;
  group?: string | null;
  symbol?: string | null;
  to: string;
  from: string;
  server: {
    id: string;
    /**
     * @minLength 1
     * @maxLength 255
     */
    name: string;
    type: 'MT5' | 'MT4' | 'C2C';
  };
  rate: number;
  entity: {
    name: string;
    description: string | null;
  };
  actionBy: 'USER' | 'SYSTEM';
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  updatedBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  createdBy?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
}

export interface GetMeData {
  id: number;
  cuid: string;
  role: string;
  drivewealthUserId?: string;
  email: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  loginAt?: string | null;
  /** @format date-time */
  email_verified?: string | null;
  /** @format date-time */
  phone_verified?: string | null;
  fname?: string | null;
  lname?: string | null;
  dob?: string | null;
  primary_phone?: string | null;
  blocked: boolean;
  /** @format date-time */
  blockedAt?: string | null;
  blockReason?: string | null;
  blockedBy?: string | null;
  appdata?: any;
  metadata?: any;
  sum_deposit: number;
  dwUsers:
    | {
        id: string;
        status: string;
      }[]
    | null;
  trading_accounts:
    | {
        metadata?: any;
        external_id: string;
      }[]
    | null;
}

export type GetMetaDataData = Record<string, any>;

export type UpdateMetaDataPayload = Record<string, any>;

export type UpdateMetaDataData = Record<string, any>;

export type GetAllMetadataFieldsResult = string[];

export type GetAllUserFieldsResult = string[];

export type UpdateAppDataPayload = Record<string, any>;

export type UpdateAppDataData = Record<string, any>;

export type GetWatchlistData = string[];

export type SetWatchlistPayload = string[];

export type SetWatchlistData = boolean;

export interface VerifyPhoneNumberPayload {
  firebaseToken: string;
}

export type VerifyPhoneNumberData = any;

export interface CreateFromKycFormBody {
  /** @minLength 1 */
  kycFormId: string;
  accountTypeName?: string;
  isDemo?: boolean;
  metadata?: Record<string, string>;
}

export interface CreateFromKycFormResult {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
  account_type?: {
    name: string;
    group: string;
    id: number;
    server?: {
      /**
       * @minLength 1
       * @maxLength 255
       */
      name: string;
      id: string;
      type: 'MT5' | 'MT4' | 'C2C';
    };
  };
  kyc?: {
    entityName: string;
    formType: string;
    id: string;
  };
}

export type GetUserTradingAccountsData = {
  id: string;
  metadata?: any;
  external_id: string;
  server: {
    type: string;
  };
}[];

export type GetExternalAccountsResult = {
  /** The currency of the trading account. */
  currency?: string;
  /** The type of the trading account MT5/MT4/C2C. */
  type?: string;
  /** The login of the user, to whom the trading account belongs. */
  login?: string;
  /** The number of digits after the decimal point in the account deposit currency. */
  currency_digits?: number;
  /** The balance of a trade account. */
  balance?: number;
  /** The current amount of credit given to an account. */
  credit?: number;
  /** The current amount of credit given to an account. */
  margin?: number;
  /** The free margin of an account. */
  margin_free?: number;
  /** The margin level as a percentage. It is calculated as a percentage of the current account equity (Equity) to the margin volume (Margin). */
  margin_level?: number;
  /** The current size of the initial margin of positions on a trading account. */
  margin_initial?: number;
  /** The current size of the maintenance margin of positions on a trading account. */
  margin_maintenance?: number;
  /** The size of the current profit for all open positions. */
  profit?: number;
  /** The current size of swaps charged for open positions on the account. */
  storage?: number;
  /** The size of floating profit/loss of open positions on the account. The floating profit/loss is calculated as the sum of Profit, Storage and Commission of open positions on the account. */
  floating?: number;
  /** The account equity calculated as a sum of Balance, Credit and Floating. */
  equity?: number;
  /** The current total amount of assets on a trading account. */
  assets?: number;
  /** The current total amount of liabilities on a trading account. */
  liabilities?: number;
  /** The account status as per the minimum amount of funds on the account required to maintain trading positions. */
  so_activation?: number;
  /** The time when the Margin Call or Stop Out level was reached, in seconds that had elapsed since 01.01.1970. */
  so_time?: number;
  /** The margin level of an account at the time it reaches the Stop Out level. */
  so_level?: number;
  /** The equity of an account at the time it reaches the Stop Out level. */
  so_equity?: number;
  /** The margin of an account at the time it reaches the Stop Out level. */
  so_margin?: number;
  /** The amount of the standard commission locked on the account, which has been accumulated during the day/month. */
  blocked_commission?: number;
  /** The amount of intraday profit locked on the account. */
  blocked_profit?: number;
  /** Margin leverage. */
  leverage?: number;
  /** The volume. */
  volume?: number;
  /** Margin type. */
  margin_type?: number;
  /** Margin level type. */
  level_type?: number;
}[];

export type GetAllUserNotificationsData = {
  id: number;
  title: string;
  body: string;
  data?: Record<string, any>;
  channel: string | 'null' | null;
  delivered: string | 'null' | null;
  /** @format date-time */
  created_at: string;
}[];

export type DeleteAllUserNotificationsData = any;

export type DeleteUserNotificationData = any;

export interface MarkAsReadPayload {
  ids: number[];
  tradingAccountId?: string;
}

export type MarkAsReadData = any;

export type GetMyDocumentsData = {
  id: string;
  category: 'ID' | 'POR' | 'SELFI';
  auto_approved: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  rejectedAt: string | null;
  /** @format date-time */
  approved_at: string | null;
  /** @format date-time */
  expire_at: string | null;
  type: string;
}[];

export type GetRecurringPaymentsData = {
  id: string;
  raw_amount: number;
  usd_amount: number;
  exchange_rate: number;
  currency_code: string;
  billing_cycle: string;
  interval: number;
  userId: string;
  accountId: string;
  gatewayName: string;
  metadata?: any;
  audit_log: string[];
  active: boolean;
}[];

export interface AddRecurringPaymentPayload {
  raw_amount: number;
  usd_amount: number;
  exchange_rate: number;
  currency_code: string;
  billing_cycle: 'Minutely' | 'Hourly' | 'Daily' | 'Weekly' | 'Monthly';
  interval: number;
  userId: string;
  accountId: string;
  gatewayName: string;
  metadata?: Record<string, any>;
  paymentIntentId?: string;
}

export interface AddRecurringPaymentData {
  id: string;
  raw_amount: number;
  usd_amount: number;
  exchange_rate: number;
  currency_code: string;
  billing_cycle: string;
  interval: number;
  userId: string;
  accountId: string;
  gatewayName: string;
  metadata?: any;
  audit_log: string[];
  active: boolean;
}

export interface UpdateRecurringPaymentPayload {
  active: boolean;
}

export interface UpdateRecurringPaymentData {
  success: boolean;
}

export type GetRecurringPaymentBillsData = {
  id: string;
  recurringPaymentId: string;
  paidAt: string | 'null' | null;
  status: 'PAID' | 'FAILED' | 'SCHEDULED' | 'CANCELLED';
  intentId: string | 'null' | null;
}[];

export interface CreateRecurringPaymentBillPayload {
  recurringPaymentId: string;
  paidAt: string | 'null' | null;
  status: 'PAID' | 'FAILED' | 'SCHEDULED' | 'CANCELLED';
  intentId: string | 'null' | null;
}

export interface CreateRecurringPaymentBillData {
  success: boolean;
}

export type GetTotalDepositsData = number;

export type GetNetDepositsData = number;

export interface TransferFromAccountToAccountPayload {
  fromAccountId: string;
  toAccountId: string;
  amount: number;
  currency: 'USD';
}

export interface TransferFromAccountToAccountData {
  success: true;
}

export type GetWithdrawRequestsResult = {
  id: number;
  kycId: string;
  accountId: string | null;
  amount: number;
  currency: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  processedAt?: string | null;
  processedBy?: string | null;
  processedComment?: string | null;
  userComment?: string | null;
  transactionId: string;
  bankAccountId?: number | null;
  creditCardId?: number | null;
  paymentGatewayName?: string | null;
  transaction: {
    status: 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'REJECTED' | 'ERROR';
    id: string;
    owner: string;
    Owner?: {
      id: number;
      cuid: string;
      email: string;
      fname?: string | null;
      lname?: string | null;
      /** @format date-time */
      createdAt: string;
      metadata?: any;
    };
    createdBy?: {
      id: number;
      cuid: string;
      email: string;
      fname?: string | null;
      lname?: string | null;
      /** @format date-time */
      createdAt: string;
      metadata?: any;
    };
    updatedBy?: {
      id: number;
      cuid: string;
      email: string;
      fname?: string | null;
      lname?: string | null;
      /** @format date-time */
      createdAt: string;
      metadata?: any;
    };
    type: string;
    comment?: string | null;
    subtype?: string | null;
    audit_log: string[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    bankAccount?: {
      bankName: string;
      accountName: string;
    } | null;
    creditCard?: {
      lastFourDigits: number;
      typeName: string;
    } | null;
  };
}[];

export interface RequestWithdrawBody {
  kycId: string;
  accountId: string;
  amount: number;
  /** @minLength 1 */
  currency: string;
  comment?: string;
  paymentGatewayName?: string;
  fromCurrency?: string;
  toCurrency?: string;
}

export type RequestWithdrawResult = any;

export type GetCustomerData = object;

export interface RequestLeanPaymentPayload {
  amount: number;
  currency: 'AED' | 'USD';
  leanAccountId: string;
  mtAccountId?: string;
  metadata?: Record<string, any>;
}

export type RequestLeanPaymentData = string;

export type GetConnectedAccountsData = {
  id: string;
  customer_id: string;
  app_id: string;
  bank_identifier: string;
  bank_friendly_name: string;
  logo: string;
  status: 'ACTIVE' | 'AWAITING_BENEFICIARY_COOL_OFF';
  beneficiary_cool_off_expiry?: any;
  accounts: {
    id: string;
    account_id: string;
    account_name: string;
    account_number: string;
    iban: string;
    currency: string;
  }[];
  beneficiaries: {
    id: string;
    payment_destination_id: string;
    status: string;
    beneficiary_cool_off_expiry: string;
    bank_identifier: string;
  }[];
}[];

export interface RequestCheckoutPaymentPayload {
  is_wallet_payment: boolean;
  account_id?: string;
  payment_type?: 'Regular' | 'Recurring';
  source:
    | {
        type: 'token';
        token: string;
      }
    | {
        type: 'id';
        id: string;
        cvv: string;
      };
  '3ds'?: {
    enabled: boolean;
    challenge_indicator?: string;
    attempt_n3d?: boolean;
  };
  merchant_initiated?: boolean;
  previous_payment_id?: string;
  token_data?: {
    signature: string;
    protocolVersion: string;
    signedMessage: string;
  };
  customer: {
    email: string;
    name: string;
  };
  amount: number;
  currency: string;
  metadata?: Record<string, any>;
  success_url?: string;
  failure_url?: string;
  type: 'id' | 'token';
}

export interface RequestCheckoutPaymentData {
  OTPLink?: string;
  success: boolean;
}

export type GetUsedCardsData = {
  type?: string;
  id?: string;
  fingerprint?: string;
  expiry_month?: number;
  expiry_year?: number;
  name?: string;
  scheme?: string;
  scheme_local?: string;
  last4?: string;
  bin?: string;
  card_type?: string;
  card_category?: string;
  issuer?: string;
  issuer_country?: string;
  product_id?: string;
  product_type?: string;
  customer?: {
    id: string;
    /** @format email */
    email: string;
    name: string;
    default: boolean;
  };
  account_holder?: {
    first_name?: string;
    last_name?: string;
    billing_address?: {
      address_line1: string;
      address_line2?: string;
      city: string;
      state: string;
      zip: string;
      country: string;
    };
    phone?: {
      country_code?: string;
      number?: string;
    };
  };
}[];

export interface GetCardsData {
  customerCards: {
    id: string;
    cardNumber: string;
  }[];
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface RequestOtpPayload {
  cardId: string;
  cardExpiryDate: string;
  amount: number;
  currency: string;
}

export interface RequestOtpData {
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
  [key: string]: any;
}

export interface DepositPayload {
  cardId: string;
  cardExpiryDate: string;
  amount: number;
  otp: string;
  currency: string;
  account_id?: string;
  transactionId: string;
}

export interface DepositData {
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
  [key: string]: any;
}

export interface RequestPraxisPaymentPayload {
  currency: string;
  amount: number;
  cid: string;
  account_id?: string;
  metadata?: Record<string, any>;
}

export interface RequestPraxisPaymentData {
  url: string;
}

export interface RequestWhishPaymentPayload {
  amount: number;
  currency: string;
  account_id?: string;
  metadata?: Record<string, any>;
}

export interface RequestWhishPaymentData {
  redirectUrl: string;
}

export interface GetKycFormByIdData {
  name: string;
  entityName: string;
  /** @default [] */
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue?: string | null;
    min?: number | null;
    max?: number | null;
    page: number;
    order: number;
    pattern?: string | null;
    logic: string[];
    options?: string | null;
    showIf?: string | null;
    published: boolean;
  }[];
  /** @default [] */
  account_type?: {
    id: number;
    name: string;
    group: string;
    requirements: string;
    priority: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @format date-time */
    deletedAt?: string | null;
    is_dw: boolean;
    is_crypto: boolean;
    iDRangeId: number;
    server_id: string;
    kyc_formName: string;
    defaultLeverage: number;
    maxLeverage: number;
  }[];
}

export interface GetOrCreateKycFormResult {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  page: number;
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue: string | null;
    min: number | null;
    max: number | null;
    /** @default 1 */
    page?: number;
    /** @default 1 */
    order?: number;
    pattern: string | null;
    logic: string[] | null;
    options: string | null;
    showIf: string | null;
    /** @default false */
    published?: boolean;
  }[];
  extendedFields?: {
    name: string;
    required: boolean;
    defaultValue?: any;
    section?: string | null;
    pattern?: string | null;
    options?: string[];
    type: string;
    min?: number | null;
    max?: number | null;
    description?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  }[];
  id_status?: string | null;
  /** @format date-time */
  id_verified?: string | null;
  /** @format date-time */
  kyc_verified?: string | null;
  por_status?: string | null;
  /** @format date-time */
  por_verified?: string | null;
  residency?: string | null;
  nationality?: string | null;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  acknowledgementCheckedDateTime?: string | null;
}

export type UpdateUserFormBody = Record<string, any>;

export interface UpdateUserFormResult {
  id: string;
  cuid: string;
  formType: string;
  entityName: string;
  answers: Record<string, string>;
  additionalInformation: Record<string, any>;
  isValid: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  page: number;
  questions?: {
    name: string;
    type: string;
    required: boolean;
    defaultValue: string | null;
    min: number | null;
    max: number | null;
    /** @default 1 */
    page?: number;
    /** @default 1 */
    order?: number;
    pattern: string | null;
    logic: string[] | null;
    options: string | null;
    showIf: string | null;
    /** @default false */
    published?: boolean;
  }[];
  extendedFields?: {
    name: string;
    required: boolean;
    defaultValue?: any;
    section?: string | null;
    pattern?: string | null;
    options?: string[];
    type: string;
    min?: number | null;
    max?: number | null;
    description?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  }[];
  id_status?: string | null;
  /** @format date-time */
  id_verified?: string | null;
  /** @format date-time */
  kyc_verified?: string | null;
  por_status?: string | null;
  /** @format date-time */
  por_verified?: string | null;
  residency?: string | null;
  nationality?: string | null;
  User?: {
    id: number;
    cuid: string;
    email: string;
    fname?: string | null;
    lname?: string | null;
    /** @format date-time */
    createdAt: string;
    metadata?: any;
  };
  /** @format date-time */
  acknowledgementCheckedDateTime?: string | null;
}

export type GetAllDefinitionsResult = Record<string, any>;

export interface BankAccountsCreatePayload {
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  entityName: string;
  gatewayName: string;
  externalId: string;
  defaultForEntity?: boolean;
}

export interface BankAccountsCreateData {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}

export type BankAccountsDetailData = {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}[];

export interface BankAccountsArchiveUpdateData {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}

export interface BankAccountsDefaultUpdatePayload {
  defaultForEntity: boolean;
}

export interface BankAccountsDefaultUpdateData {
  id: number;
  userId: string;
  bankName: string;
  bankAddress: string;
  accountName: string;
  swiftCode: string;
  iban: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  externalId: string;
}

export interface CreditCardsCreatePayload {
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  entityName: string;
  externalId: string;
  gatewayName: string;
  typeId: number;
  defaultForEntity?: boolean;
}

export interface CreditCardsCreateData {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}

export type CreditCardsDetailData = {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}[];

export interface CreditCardsArchiveUpdateData {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}

export interface CreditCardsDefaultUpdatePayload {
  defaultForEntity: boolean;
}

export interface CreditCardsDefaultUpdateData {
  id: number;
  userId: string;
  nameOnCard: string;
  lastFourDigits: number;
  expiry: string;
  externalId: string;
  archived: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  reviewedBy?: string | null;
  /** @format date-time */
  reviewedAt?: string | null;
  defaultForEntity: boolean;
  entityName: string;
  gatewayName: string;
  typeId: number;
  typeName: string;
}

export interface SendZendeskTicketPayload {
  message: string;
  requester: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    country: string;
  };
}

export type SendZendeskTicketData = boolean;

export interface RegisterPayload {
  token: string;
  platform: string;
}

export interface RegisterData {
  ok: boolean;
}

export type UploadDocumentPayload = any;

export interface UploadDocumentData {
  id: string;
  category: 'ID' | 'POR' | 'SELFI';
  auto_approved: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  rejectedAt: string | null;
  /** @format date-time */
  approved_at: string | null;
  /** @format date-time */
  expire_at: string | null;
  type: string;
}

export type GetBalancesForTargetData = {
  id: number;
  currency: string;
  availableBalance: number;
  reservedBalance: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  gatewayName: string | null;
  tradingAccount: {
    external_id: string;
    id: string;
  } | null;
  kycForm: {
    id: string;
    formType: string;
    entityName: string;
  } | null;
  snapshots?: {
    balanceWalletId: number;
    availableBalance: number;
    reservedBalance: number;
    /** @format date-time */
    createdAt: string;
  }[];
}[];

export type GetBalanceForCurrencyData = {
  id: number;
  currency: string;
  availableBalance: number;
  reservedBalance: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  gatewayName: string | null;
  tradingAccount: {
    external_id: string;
    id: string;
  } | null;
  kycForm: {
    id: string;
    formType: string;
    entityName: string;
  } | null;
  snapshots?: {
    balanceWalletId: number;
    availableBalance: number;
    reservedBalance: number;
    /** @format date-time */
    createdAt: string;
  }[];
};

export type GetBalancesByTargetTypeData = {
  id: number;
  currency: string;
  availableBalance: number;
  reservedBalance: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  gatewayName: string | null;
  tradingAccount: {
    external_id: string;
    id: string;
  } | null;
  kycForm: {
    id: string;
    formType: string;
    entityName: string;
  } | null;
  snapshots?: {
    balanceWalletId: number;
    availableBalance: number;
    reservedBalance: number;
    /** @format date-time */
    createdAt: string;
  }[];
}[];

export interface ArchiveBalancesPayload {
  currencies?: string[];
}

export interface ArchiveBalancesData {
  success: boolean;
}

export type GetArchivesData = {
  balanceWalletId: number;
  availableBalance: number;
  reservedBalance: number;
  /** @format date-time */
  createdAt: string;
}[];

export interface GetThemeData {
  logo: string;
}

export interface HealthData {
  status: string;
}

export type GetI18NBySectionData = Record<string, Record<string, string>>;

export type GetI18NByLanguageData = {
  id: string;
  section: string;
  key: string;
  value: string;
  language: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export type GetI18NByKeyData = {
  id: string;
  section: string;
  key: string;
  value: string;
  language: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}[];

export interface GetProviderData {
  name: string;
  icon_light?: string;
  icon_dark?: string;
}

export type PkGenerateRegistrationOptionsData = any;

export interface PkVerifyRegistrationPayload {
  id: string;
  rawId: string;
  response: {
    clientDataJSON: string;
    attestationObject: string;
    authenticatorData?: string;
    transports?: ('ble' | 'cable' | 'hybrid' | 'internal' | 'nfc' | 'smart-card' | 'usb')[];
    publicKeyAlgorithm?: number;
    publicKey?: string;
  };
  authenticatorAttachment: 'cross-platform' | 'platform';
  clientExtensionResults: Record<string, any>;
  type: 'public-key';
}

export type PkVerifyRegistrationData = any;

export interface PkGenerateAuthenticationOptionsData {
  challenge: string;
  timeout?: number;
  rpId?: string;
  allowCredentials?: {
    id: string;
    type: 'public-key';
    transports?: ('ble' | 'cable' | 'hybrid' | 'internal' | 'nfc' | 'smart-card' | 'usb')[];
  }[];
  userVerification?: string;
  extensions?: any;
}

export interface PkVerifyAuthenticationPayload {
  id: string;
  rawId: string;
  response: {
    clientDataJSON: string;
    authenticatorData: string;
    signature: string;
    userHandle?: string;
  };
  authenticatorAttachment: 'cross-platform' | 'platform';
  clientExtensionResults: Record<string, any>;
  type: 'public-key';
}

export interface PkVerifyAuthenticationData {
  verified: true;
  access_token: string;
  refresh_token: string;
}

export interface AdminCreateFromKycFormPayload {
  /** @minLength 1 */
  userId: string;
  /** @minLength 1 */
  kycFormId: string;
  accountTypeName?: string;
  isDemo?: boolean;
  metadata?: Record<string, string>;
}

export interface AdminCreateFromKycFormData {
  id: string;
  external_id: string;
  is_active: boolean;
  currency: string;
  /**
   * @min 1
   * @max 500
   */
  leverage: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  metadata?: any;
}

export type GetCountriesData = {
  id: string;
  name: string;
  code2: string;
  code3: string;
  active: boolean;
  expatsAllowed: boolean;
}[];

export type GetCountriesResult = {
  id: string;
  name: string;
  code2: string;
  code3: string;
  active: boolean;
  expatsAllowed: boolean;
  disabled: boolean;
  foreignTINNotRequired: boolean;
  fpslProhibited: boolean;
  supportDomicile: boolean;
  taxTreatyCountry: boolean;
  riskScore: number;
  dw_active: boolean;
  dw_expatsAllowed: boolean;
}[];

export interface CreateCountryPayload {
  id: string;
  name: string;
  code2: string;
  code3: string;
  active: boolean;
  expatsAllowed: boolean;
  [key: string]: any;
}

export interface CreateCountryData {
  id: string;
  name: string;
  code2: string;
  code3: string;
  active: boolean;
  expatsAllowed: boolean;
  disabled: boolean;
  foreignTINNotRequired: boolean;
  fpslProhibited: boolean;
  supportDomicile: boolean;
  taxTreatyCountry: boolean;
  riskScore: number;
  dw_active: boolean;
  dw_expatsAllowed: boolean;
}

export interface UpdateCountryPayload {
  id?: string;
  name?: string;
  code2?: string;
  code3?: string;
  active?: boolean;
  expatsAllowed?: boolean;
  disabled?: boolean;
  foreignTINNotRequired?: boolean;
  fpslProhibited?: boolean;
  supportDomicile?: boolean;
  taxTreatyCountry?: boolean;
  riskScore?: number;
  dw_active?: boolean;
  dw_expatsAllowed?: boolean;
}

export interface UpdateCountryData {
  id: string;
  name: string;
  code2: string;
  code3: string;
  active: boolean;
  expatsAllowed: boolean;
  disabled: boolean;
  foreignTINNotRequired: boolean;
  fpslProhibited: boolean;
  supportDomicile: boolean;
  taxTreatyCountry: boolean;
  riskScore: number;
  dw_active: boolean;
  dw_expatsAllowed: boolean;
}

export type SyncDrivewealthCountriesData = any;

export interface IdwiseCreatePayload {
  event:
    | 'Finished Journey'
    | 'Manually Reviewed'
    | 'AML Monitor Update'
    | 'Finished User Steps'
    | 'Finished Step'
    | 'Finished Ongoing Check';
  body: {
    eventId: string;
    journeyId: string;
    userId: string;
    referenceNo: string;
    systemDecision: 'Complete' | 'Incomplete' | 'Refer';
    manualDecision: 'Approved' | 'Rejected' | null;
    finalDecision?: 'Approved' | 'Rejected' | 'Complete' | 'Incomplete' | 'Refer';
  };
}

export type IdwiseCreateData = any;

export interface CheckoutHookPayload {
  type: string;
  data: {
    id: string;
    amount: number;
    currency: string;
    metadata?: Record<string, any>;
    response_code?: string;
  };
}

export type CheckoutHookData = boolean;

export type LeanHookPayload = Record<string, any>;

export type LeanHookData = string;

export type PraxisHookPayload = Record<string, any>;

export type PraxisHookData = boolean;

export type WhishHookPayload = Record<string, any>;

export type WhishHookData = boolean;

export type TestDocumentData = any;

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({securityWorker, secure, format, ...axiosConfig}: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({...axiosConfig, baseURL: axiosConfig.baseURL || ''});
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? {'Content-Type': type} : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title CRMBox Api
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * @description Send magic link to email for login
     *
     * @tags auth
     * @name SendMagicLink
     * @request POST:/auth/email
     */
    sendMagicLink: (data: SendMagicLinkPayload, params: RequestParams = {}) =>
      this.request<SendMagicLinkData, any>({
        path: `/auth/email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description check if email exists
     *
     * @tags auth
     * @name CheckEmail
     * @request POST:/auth/check-email
     */
    checkEmail: (data: CheckEmailPayload, params: RequestParams = {}) =>
      this.request<
        CheckEmailData,
        {
          error?: string;
        }
      >({
        path: `/auth/check-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description send verification to email
     *
     * @tags auth
     * @name SendVerifyEmailLink
     * @request POST:/auth/send-email-link
     */
    sendVerifyEmailLink: (data: SendVerifyEmailLinkPayload, params: RequestParams = {}) =>
      this.request<
        SendVerifyEmailLinkData,
        {
          error: string;
        }
      >({
        path: `/auth/send-email-link`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description login as another user
     *
     * @tags auth, admin
     * @name LoginAs
     * @request POST:/auth/login-as
     */
    loginAs: (data: LoginAsPayload, params: RequestParams = {}) =>
      this.request<LoginAsData, any>({
        path: `/auth/login-as`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description exchange credentials for jwt token
     *
     * @tags auth
     * @name Login
     * @request POST:/auth/token
     */
    login: (data: LoginPayload, params: RequestParams = {}) =>
      this.request<LoginData, any>({
        path: `/auth/token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description block a user by userId
     *
     * @tags auth
     * @name BlockUser
     * @request POST:/auth/block
     */
    blockUser: (data: BlockUserPayload, params: RequestParams = {}) =>
      this.request<BlockUserData, any>({
        path: `/auth/block`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description unblock a user by userId
     *
     * @tags auth
     * @name UnblockUser
     * @request POST:/auth/unblock
     */
    unblockUser: (data: UnblockUserPayload, params: RequestParams = {}) =>
      this.request<UnblockUserData, any>({
        path: `/auth/unblock`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name VerifyEmail
     * @request GET:/auth/callback/email
     */
    verifyEmail: (
      query: {
        /** @format uuid */
        code: string;
        /** @format email */
        email?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, any>({
        path: `/auth/callback/email`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name VerifyMagicLink
     * @request GET:/auth/callback/link
     */
    verifyMagicLink: (
      query: {
        /** @format uuid */
        code: string;
        /** @format email */
        email?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, any>({
        path: `/auth/callback/link`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description request new token pair; used to extend session life and for oauth code exchange
     *
     * @tags auth
     * @name RefreshToken
     * @request POST:/auth/refresh
     */
    refreshToken: (data: RefreshTokenPayload, params: RequestParams = {}) =>
      this.request<RefreshTokenData, any>({
        path: `/auth/refresh`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description create a new staff user
     *
     * @tags admin
     * @name CreateStaff
     * @request POST:/auth/staff
     */
    createStaff: (data: CreateStaffPayload, params: RequestParams = {}) =>
      this.request<CreateStaffData, any>({
        path: `/auth/staff`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description create a new user using lead form
     *
     * @tags auth
     * @name ManualSignup
     * @request POST:/auth/manual-signup
     */
    manualSignup: (data: ManualSignupPayload, params: RequestParams = {}) =>
      this.request<
        ManualSignupData,
        {
          message: string;
          user?: any;
        }
      >({
        path: `/auth/manual-signup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description create a new user using social login like google, apple, etc.
     *
     * @tags auth
     * @name SocialSignup
     * @request POST:/auth/social-signup
     */
    socialSignup: (data: SocialSignupPayload, params: RequestParams = {}) =>
      this.request<SocialSignupData, any>({
        path: `/auth/social-signup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description migrate user from old system. allow you to create a user with same cuid
     *
     * @tags migration
     * @name MigrateUser
     * @request POST:/auth/migrate
     */
    migrateUser: (data: MigrateUserPayload, params: RequestParams = {}) =>
      this.request<MigrateUserData, any>({
        path: `/auth/migrate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description update password
     *
     * @tags auth
     * @name ChangePassword
     * @request POST:/auth/change-password
     * @secure
     */
    changePassword: (data: ChangePasswordPayload, params: RequestParams = {}) =>
      this.request<
        ChangePasswordData,
        {
          success?: boolean;
          error: string;
        }
      >({
        path: `/auth/change-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags phone-verification
     * @name SendOtp
     * @request POST:/auth/send-otp
     */
    sendOtp: (data: SendOtpPayload, params: RequestParams = {}) =>
      this.request<SendOtpData, any>({
        path: `/auth/send-otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags phone-verification
     * @name VerifyOtp
     * @request POST:/auth/verify-otp
     */
    verifyOtp: (data: VerifyOtpPayload, params: RequestParams = {}) =>
      this.request<VerifyOtpData, any>({
        path: `/auth/verify-otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  documents = {
    /**
     * @description Get all documents for a user
     *
     * @tags documents
     * @name AdminGetDocuments
     * @request GET:/documents/{cuid}
     * @secure
     */
    adminGetDocuments: (
      cuid: string,
      query?: {
        category?: 'ID' | 'POR' | 'SELFI';
        entity?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminGetDocumentsData, any>({
        path: `/documents/${cuid}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description verify or reject a document
     *
     * @tags documents
     * @name AdminVerifyDoc
     * @request PUT:/documents/{id}
     * @secure
     */
    adminVerifyDoc: (id: string, data: AdminVerifyDocPayload, params: RequestParams = {}) =>
      this.request<AdminVerifyDocData, any>({
        path: `/documents/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Delete a rejected document
     *
     * @tags documents
     * @name AdminDeleteDocument
     * @request DELETE:/documents/{id}
     * @secure
     */
    adminDeleteDocument: (id: string, params: RequestParams = {}) =>
      this.request<AdminDeleteDocumentData, any>({
        path: `/documents/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all documents for a user
     *
     * @tags documents
     * @name PreviewDocument
     * @request GET:/documents/preview/{id}
     * @secure
     */
    previewDocument: (id: string, params: RequestParams = {}) =>
      this.request<PreviewDocumentData, any>({
        path: `/documents/preview/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all documents where approved_at and rejected_at are null
     *
     * @tags documents
     * @name GetPendingDocuments
     * @request GET:/documents/pending
     * @secure
     */
    getPendingDocuments: (params: RequestParams = {}) =>
      this.request<GetPendingDocumentsData, any>({
        path: `/documents/pending`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get statistics about documents
     *
     * @tags documents
     * @name GetDocumentStats
     * @request GET:/documents/stats
     * @secure
     */
    getDocumentStats: (params: RequestParams = {}) =>
      this.request<GetDocumentStatsData, any>({
        path: `/documents/stats`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description upload a document. max 3 pending documents per user per category.
     *
     * @tags documents:Common
     * @name UploadDocument
     * @request POST:/documents/{category}
     * @secure
     */
    uploadDocument: (
      category: 'ID' | 'POR',
      query: {
        type:
          | 'tax_bill'
          | 'electricity_bill'
          | 'water_bill'
          | 'phone_bill'
          | 'bank_statement'
          | 'passport'
          | 'id_front'
          | 'id_back'
          | 'license';
        cuid?: string;
        userKycFormId: string;
        expiryDate?: string;
      },
      data: UploadDocumentPayload,
      params: RequestParams = {}
    ) =>
      this.request<UploadDocumentData, any>({
        path: `/documents/${category}`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
  };
  kyc = {
    /**
     * @description a map of all kyc definitions
     *
     * @tags kyc_definitions
     * @name GetAllDefinitions
     * @request GET:/kyc/definitions/
     * @secure
     */
    getAllDefinitions: (params: RequestParams = {}) =>
      this.request<GetAllDefinitionsData, any>({
        path: `/kyc/definitions/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description a map of all kyc definitions
     *
     * @tags kyc_definitions
     * @name AppendDefinition
     * @request POST:/kyc/definitions/
     * @secure
     */
    appendDefinition: (data: AppendDefinitionPayload, params: RequestParams = {}) =>
      this.request<AppendDefinitionData, any>({
        path: `/kyc/definitions/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description create kyc form
     *
     * @tags entities, kyc
     * @name CreateKycForm
     * @request POST:/kyc/form
     * @secure
     */
    createKycForm: (data: CreateKycFormPayload, params: RequestParams = {}) =>
      this.request<CreateKycFormData, any>({
        path: `/kyc/form`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get all kyc forms
     *
     * @tags entities, kyc
     * @name GetKycForms
     * @request GET:/kyc/forms
     * @secure
     */
    getKycForms: (
      query?: {
        entity?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetKycFormsData, any>({
        path: `/kyc/forms`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description get questions of a form
     *
     * @tags entities, kyc
     * @name AdminGetFormQuestions
     * @request GET:/kyc/forms/{name}/questions
     * @secure
     */
    adminGetFormQuestions: (name: string, params: RequestParams = {}) =>
      this.request<AdminGetFormQuestionsData, any>({
        path: `/kyc/forms/${name}/questions`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description link questions to a form
     *
     * @tags entities, kyc
     * @name AdminLinkQuestions
     * @request PUT:/kyc/forms/{name}/questions
     * @secure
     */
    adminLinkQuestions: (
      name: string,
      data: AdminLinkQuestionsPayload,
      params: RequestParams = {}
    ) =>
      this.request<AdminLinkQuestionsData, any>({
        path: `/kyc/forms/${name}/questions`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description link extended fields to a form
     *
     * @tags entities, kyc
     * @name AdminLinkExtendedFields
     * @request PUT:/kyc/forms/{name}/extended-fields
     * @secure
     */
    adminLinkExtendedFields: (
      name: string,
      data: AdminLinkExtendedFieldsPayload,
      params: RequestParams = {}
    ) =>
      this.request<AdminLinkExtendedFieldsData, any>({
        path: `/kyc/forms/${name}/extended-fields`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description list of all questions
     *
     * @tags entities, questions
     * @name GetAllQuestions
     * @request GET:/kyc/questions
     * @secure
     */
    getAllQuestions: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/kyc/questions`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description upsert a question
     *
     * @tags entities, questions
     * @name UpsertQuestion
     * @request PUT:/kyc/questions
     * @secure
     */
    upsertQuestion: (data: UpsertQuestionPayload, params: RequestParams = {}) =>
      this.request<UpsertQuestionData, any>({
        path: `/kyc/questions`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete a question
     *
     * @tags entities, questions
     * @name DeleteQuestion
     * @request DELETE:/kyc/questions/{name}
     * @secure
     */
    deleteQuestion: (name: string, params: RequestParams = {}) =>
      this.request<DeleteQuestionData, any>({
        path: `/kyc/questions/${name}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description list of all questions names
     *
     * @tags entities, questions
     * @name GetAllQuestionsNames
     * @request GET:/kyc/questions/names
     * @secure
     */
    getAllQuestionsNames: (params: RequestParams = {}) =>
      this.request<GetAllQuestionsNamesData, any>({
        path: `/kyc/questions/names`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  userKycForms = {
    /**
     * @description Create a user KYC form
     *
     * @tags user_kyc_form
     * @name CreateUserKycForm
     * @request POST:/user_kyc_forms/
     * @secure
     */
    createUserKycForm: (data: CreateUserKycFormPayload, params: RequestParams = {}) =>
      this.request<CreateUserKycFormData, any>({
        path: `/user_kyc_forms/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get all user(s) KYC forms filled
     *
     * @tags user_kyc_form
     * @name AdminGetAllUserKycForms
     * @request GET:/user_kyc_forms/
     * @secure
     */
    adminGetAllUserKycForms: (
      query?: {
        entity?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminGetAllUserKycFormsData, any>({
        path: `/user_kyc_forms/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get all user KYC forms for a user
     *
     * @tags user_kyc_form
     * @name GetKycFormsByUserId
     * @request GET:/user_kyc_forms/by-user/{cuid}
     * @secure
     */
    getKycFormsByUserId: (cuid: string, params: RequestParams = {}) =>
      this.request<GetKycFormsByUserIdData, any>({
        path: `/user_kyc_forms/by-user/${cuid}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get or create a user KYC form for a user
     *
     * @tags user_kyc_form
     * @name GetOrCreateKycForm
     * @request GET:/user_kyc_forms/assert
     * @secure
     */
    getOrCreateKycForm: (
      query: {
        entity: string;
        form_name: string;
        cuid: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetOrCreateKycFormData, any>({
        path: `/user_kyc_forms/assert`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Answer user Form, if method is PUT, it will merge the answers, if POST, it will replace the answers
     *
     * @tags user_kyc_form
     * @name UpdateUserForm
     * @request POST:/user_kyc_forms/answers/{id}/{cuid}
     * @secure
     */
    updateUserForm: (
      id: string,
      cuid: string,
      data: UpdateUserFormPayload,
      query?: {
        not?: any;
        reset?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<UpdateUserFormData, any>({
        path: `/user_kyc_forms/answers/${id}/${cuid}`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update additional information for user KYC form
     *
     * @tags user_kyc_form
     * @name UpdateUserFormAdditionalInfo
     * @request PUT:/user_kyc_forms/additional-info/{id}/{cuid}
     * @secure
     */
    updateUserFormAdditionalInfo: (
      id: string,
      cuid: string,
      data: UpdateUserFormAdditionalInfoPayload,
      params: RequestParams = {}
    ) =>
      this.request<UpdateUserFormAdditionalInfoData, any>({
        path: `/user_kyc_forms/additional-info/${id}/${cuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get lead users that don't have user kyc forms or have kyc forms with page = 1
     *
     * @tags user_kyc_form
     * @name GetLeads
     * @request GET:/user_kyc_forms/leads
     * @secure
     */
    getLeads: (params: RequestParams = {}) =>
      this.request<GetLeadsData, any>({
        path: `/user_kyc_forms/leads`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all tags for a user's KYC form
     *
     * @tags entities, tags
     * @name GetAllUserKycFormTags
     * @request GET:/user-kyc-forms/{userKycFormId}/tags
     * @secure
     */
    getAllUserKycFormTags: (userKycFormId: string, params: RequestParams = {}) =>
      this.request<GetAllUserKycFormTagsData, any>({
        path: `/user-kyc-forms/${userKycFormId}/tags`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Assign Tag to user's KYC form
     *
     * @tags entities, tags
     * @name AssignTagToUserKycForm
     * @request PUT:/user-kyc-forms/{userKycFormId}/tags/{tagName}
     * @secure
     */
    assignTagToUserKycForm: (userKycFormId: string, tagName: string, params: RequestParams = {}) =>
      this.request<AssignTagToUserKycFormData, any>({
        path: `/user-kyc-forms/${userKycFormId}/tags/${tagName}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description Remove tag from user's KYC form
     *
     * @tags entities, tags
     * @name RemoveTagFromUserKycForm
     * @request DELETE:/user-kyc-forms/{userKycFormId}/tags/{tagName}
     * @secure
     */
    removeTagFromUserKycForm: (
      userKycFormId: string,
      tagName: string,
      params: RequestParams = {}
    ) =>
      this.request<RemoveTagFromUserKycFormData, any>({
        path: `/user-kyc-forms/${userKycFormId}/tags/${tagName}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  accountTypes = {
    /**
     * @description list of all account types
     *
     * @tags account-types
     * @name GetAll
     * @request GET:/account-types
     * @secure
     */
    getAll: (params: RequestParams = {}) =>
      this.request<GetAllData, any>({
        path: `/account-types`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description create a new account type
     *
     * @tags account-types
     * @name Create
     * @request POST:/account-types
     * @secure
     */
    create: (data: CreatePayload, params: RequestParams = {}) =>
      this.request<CreateData, any>({
        path: `/account-types`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get all account types for a given server id
     *
     * @tags account-types
     * @name GetAllByServerId
     * @request GET:/account-types/by-server/{serverId}
     * @secure
     */
    getAllByServerId: (serverId: string, params: RequestParams = {}) =>
      this.request<GetAllByServerIdData, any>({
        path: `/account-types/by-server/${serverId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all account types for a given group
     *
     * @tags account-types
     * @name GetAllByGroup
     * @request GET:/account-types/by-group/{group}
     * @secure
     */
    getAllByGroup: (group: string, params: RequestParams = {}) =>
      this.request<GetAllByGroupData, any>({
        path: `/account-types/by-group/${group}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all account types for a given kyc form name
     *
     * @tags account-types
     * @name GetAllByKycFormName
     * @request GET:/account-types/by-kyc-form/{name}
     * @secure
     */
    getAllByKycFormName: (name: string, params: RequestParams = {}) =>
      this.request<GetAllByKycFormNameData, any>({
        path: `/account-types/by-kyc-form/${name}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description update an account type
     *
     * @tags account-types
     * @name Update
     * @request PUT:/account-types/{id}
     * @secure
     */
    update: (id: number, data: UpdatePayload, params: RequestParams = {}) =>
      this.request<UpdateData, any>({
        path: `/account-types/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description soft delete an account type by id
     *
     * @tags account-types
     * @name SoftDeleteById
     * @request DELETE:/account-types/{id}
     * @secure
     */
    softDeleteById: (id: number, params: RequestParams = {}) =>
      this.request<SoftDeleteByIdData, any>({
        path: `/account-types/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description soft delete multiple account types by ids
     *
     * @tags account-types
     * @name SoftDeleteManyByIds
     * @request DELETE:/account-types/delete-many
     * @secure
     */
    softDeleteManyByIds: (data: SoftDeleteManyByIdsPayload, params: RequestParams = {}) =>
      this.request<SoftDeleteManyByIdsData, any>({
        path: `/account-types/delete-many`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  range = {
    /**
     * @description create an id range
     *
     * @tags id_range
     * @name Create
     * @request POST:/range
     * @secure
     */
    create: (data: CreateBody, params: RequestParams = {}) =>
      this.request<CreateResult, any>({
        path: `/range`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get id ranges
     *
     * @tags id_range
     * @name GetAll
     * @request GET:/range
     * @secure
     */
    getAll: (params: RequestParams = {}) =>
      this.request<GetAllResult, any>({
        path: `/range`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description delete many id ranges
     *
     * @tags id_range
     * @name DeleteMany
     * @request DELETE:/range
     * @secure
     */
    deleteMany: (data: DeleteManyPayload, params: RequestParams = {}) =>
      this.request<DeleteManyData, any>({
        path: `/range`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description update an id range
     *
     * @tags id_range
     * @name Update
     * @request PUT:/range/{id}
     * @secure
     */
    update: (id: number, data: UpdateBody, params: RequestParams = {}) =>
      this.request<UpdateResult, any>({
        path: `/range/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete an id range
     *
     * @tags id_range
     * @name Delete
     * @request DELETE:/range/{id}
     * @secure
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<DeleteData, any>({
        path: `/range/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  groups = {
    /**
     * @description get all groups
     *
     * @tags groups
     * @name GetAllByServerId
     * @request GET:/groups/by-server-id/{id}
     * @secure
     */
    getAllByServerId: (
      id: string,
      query?: {
        /**
         * @min 0
         * @exclusiveMin true
         * @default 1
         */
        page?: number;
        /**
         * @min 0
         * @exclusiveMin true
         * @default 100
         */
        limit?: number;
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllByServerIdResult, any>({
        path: `/groups/by-server-id/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description get all groups
     *
     * @tags groups
     * @name GetAll
     * @request GET:/groups
     * @secure
     */
    getAll: (
      query?: {
        /**
         * @min 0
         * @exclusiveMin true
         * @default 1
         */
        page?: number;
        /**
         * @min 0
         * @exclusiveMin true
         * @default 100
         */
        limit?: number;
        search?: string;
        serverId?: string;
        serverType?: 'MT5' | 'MT4' | 'C2C';
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllOutput, any>({
        path: `/groups`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description get all groups
     *
     * @tags groups
     * @name GetAllByServerType
     * @request GET:/groups/by-server-type/{type}
     * @secure
     */
    getAllByServerType: (
      type: 'MT5' | 'MT4' | 'C2C',
      query?: {
        /**
         * @min 0
         * @exclusiveMin true
         * @default 1
         */
        page?: number;
        /**
         * @min 0
         * @exclusiveMin true
         * @default 100
         */
        limit?: number;
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllByServerTypeData, any>({
        path: `/groups/by-server-type/${type}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description set enabled value for group
     *
     * @tags groups
     * @name SetEnabled
     * @request PATCH:/groups/{id}/set-enabled
     * @secure
     */
    setEnabled: (id: string, data: SetEnabledPayload, params: RequestParams = {}) =>
      this.request<
        SetEnabledData,
        {
          message: string;
        }
      >({
        path: `/groups/${id}/set-enabled`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description sync groups by server id
     *
     * @tags groups
     * @name SyncByServerId
     * @request PATCH:/groups/sync/{id}
     * @secure
     */
    syncByServerId: (id: string, params: RequestParams = {}) =>
      this.request<SyncByServerIdData, any>({
        path: `/groups/sync/${id}`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * @description get minimal groups data by server id
     *
     * @tags groups
     * @name GetMinimalGroupsByServerId
     * @request GET:/groups/by-server-id/{id}/minimal
     * @secure
     */
    getMinimalGroupsByServerId: (id: string, params: RequestParams = {}) =>
      this.request<GetMinimalGroupsByServerIdData, any>({
        path: `/groups/by-server-id/${id}/minimal`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  transactions = {
    /**
     * @description Get Wallets of user
     *
     * @tags transactions
     * @name AdminGetWalletByUserId
     * @request GET:/transactions/wallet/{kyc_user_id}
     * @secure
     */
    adminGetWalletByUserId: (kycUserId: string, params: RequestParams = {}) =>
      this.request<AdminGetWalletByUserIdData, any>({
        path: `/transactions/wallet/${kycUserId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get Wallets of all users
     *
     * @tags transactions
     * @name AdminGetWallets
     * @request GET:/transactions/wallets
     * @secure
     */
    adminGetWallets: (
      query?: {
        /** @default 1 */
        allWallets?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminGetWalletsData, any>({
        path: `/transactions/wallets`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get all transactions
     *
     * @tags transactions
     * @name AdminGetTransactions
     * @request GET:/transactions
     * @secure
     */
    adminGetTransactions: (
      query?: {
        /** @default 50 */
        limit?: number;
        offset?: number;
        kycId?: string;
        userId?: string;
        accountId?: string;
        gateway?: string;
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminGetTransactionsData, any>({
        path: `/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Transfer money from user to account
     *
     * @tags transactions
     * @name AdminTransferFromWalletToAccount
     * @request POST:/transactions/admin-wallet-to-account
     * @secure
     */
    adminTransferFromWalletToAccount: (
      data: AdminTransferFromWalletToAccountPayload,
      params: RequestParams = {}
    ) =>
      this.request<
        AdminTransferFromWalletToAccountData,
        {
          success: false;
          error:
            | 'BAD_REQUEST'
            | 'BAD_GATEWAY'
            | 'CROSS_ENTITY'
            | 'BAD_TARGET'
            | 'BAD_ENTITY'
            | 'DUPLICATE'
            | 'INSUFFICIENT_BALANCE'
            | 'BAD_AMOUNT'
            | 'BAD_CURRENCY'
            | 'LOCK_FAILED'
            | 'MT_BALANCE_UPDATE_FAILED'
            | 'BAD_MT_TARGET'
            | 'BAD_BALANCE_TARGET'
            | 'CRITICAL'
            | 'NOT_FOUND';
          message?: string;
        }
      >({
        path: `/transactions/admin-wallet-to-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Transfer money from account to wallet
     *
     * @tags transactions
     * @name AdminTransferFromAccountToWallet
     * @request POST:/transactions/admin-account-to-wallet
     * @secure
     */
    adminTransferFromAccountToWallet: (
      data: AdminTransferFromAccountToWalletPayload,
      params: RequestParams = {}
    ) =>
      this.request<
        AdminTransferFromAccountToWalletData,
        {
          success: false;
          error:
            | 'BAD_REQUEST'
            | 'BAD_GATEWAY'
            | 'CROSS_ENTITY'
            | 'BAD_TARGET'
            | 'BAD_ENTITY'
            | 'DUPLICATE'
            | 'INSUFFICIENT_BALANCE'
            | 'BAD_AMOUNT'
            | 'BAD_CURRENCY'
            | 'LOCK_FAILED'
            | 'MT_BALANCE_UPDATE_FAILED'
            | 'BAD_MT_TARGET'
            | 'BAD_BALANCE_TARGET'
            | 'CRITICAL'
            | 'NOT_FOUND';
          message?: string;
        }
      >({
        path: `/transactions/admin-account-to-wallet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Transfer money between accounts
     *
     * @tags transactions
     * @name AdminTransferFromAccountToAccount
     * @request POST:/transactions/account-to-account
     * @secure
     */
    adminTransferFromAccountToAccount: (
      data: AdminTransferFromAccountToAccountPayload,
      params: RequestParams = {}
    ) =>
      this.request<
        AdminTransferFromAccountToAccountData,
        {
          success: false;
          error:
            | 'BAD_REQUEST'
            | 'BAD_GATEWAY'
            | 'CROSS_ENTITY'
            | 'BAD_TARGET'
            | 'BAD_ENTITY'
            | 'DUPLICATE'
            | 'INSUFFICIENT_BALANCE'
            | 'BAD_AMOUNT'
            | 'BAD_CURRENCY'
            | 'LOCK_FAILED'
            | 'MT_BALANCE_UPDATE_FAILED'
            | 'BAD_MT_TARGET'
            | 'BAD_BALANCE_TARGET'
            | 'CRITICAL'
            | 'NOT_FOUND';
          message?: string;
        }
      >({
        path: `/transactions/account-to-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Transfer money from gateway to user/account
     *
     * @tags transactions
     * @name AdminTransferFromGatewayToTarget
     * @request POST:/transactions/gateway-to-target
     * @secure
     */
    adminTransferFromGatewayToTarget: (
      data: AdminTransferFromGatewayToTargetPayload,
      params: RequestParams = {}
    ) =>
      this.request<
        AdminTransferFromGatewayToTargetData,
        {
          success: false;
          error:
            | 'BAD_REQUEST'
            | 'BAD_GATEWAY'
            | 'CROSS_ENTITY'
            | 'BAD_TARGET'
            | 'BAD_ENTITY'
            | 'DUPLICATE'
            | 'INSUFFICIENT_BALANCE'
            | 'BAD_AMOUNT'
            | 'BAD_CURRENCY'
            | 'LOCK_FAILED'
            | 'MT_BALANCE_UPDATE_FAILED'
            | 'BAD_MT_TARGET'
            | 'BAD_BALANCE_TARGET'
            | 'CRITICAL'
            | 'NOT_FOUND';
          message?: string;
        }
      >({
        path: `/transactions/gateway-to-target`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update transaction status
     *
     * @tags transactions
     * @name AdminUpdateTransactionStatus
     * @request PATCH:/transactions/{transactionId}/status
     * @secure
     */
    adminUpdateTransactionStatus: (
      transactionId: string,
      data: AdminUpdateTransactionStatusPayload,
      params: RequestParams = {}
    ) =>
      this.request<
        AdminUpdateTransactionStatusData,
        {
          success: false;
          error:
            | 'BAD_REQUEST'
            | 'BAD_GATEWAY'
            | 'CROSS_ENTITY'
            | 'BAD_TARGET'
            | 'BAD_ENTITY'
            | 'DUPLICATE'
            | 'INSUFFICIENT_BALANCE'
            | 'BAD_AMOUNT'
            | 'BAD_CURRENCY'
            | 'LOCK_FAILED'
            | 'MT_BALANCE_UPDATE_FAILED'
            | 'BAD_MT_TARGET'
            | 'BAD_BALANCE_TARGET'
            | 'CRITICAL'
            | 'NOT_FOUND';
          message?: string;
        }
      >({
        path: `/transactions/${transactionId}/status`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update/Approve a withdrawal request
     *
     * @tags transactions
     * @name AdminUpdateWithdraw
     * @request PUT:/transactions/withdraw
     * @secure
     */
    adminUpdateWithdraw: (data: AdminUpdateWithdrawPayload, params: RequestParams = {}) =>
      this.request<AdminUpdateWithdrawData, any>({
        path: `/transactions/withdraw`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get withdrawal request
     *
     * @tags transactions
     * @name GetWithdrawRequests
     * @request GET:/transactions/withdraw
     * @secure
     */
    getWithdrawRequests: (
      query?: {
        status?: 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'REJECTED' | 'ERROR';
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        kycId?: string;
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetWithdrawRequestsData, any>({
        path: `/transactions/withdraw`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description User request a Withdraw money out of the system
     *
     * @tags transactions
     * @name RequestWithdraw
     * @request POST:/transactions/withdraw
     * @secure
     */
    requestWithdraw: (data: RequestWithdrawPayload, params: RequestParams = {}) =>
      this.request<RequestWithdrawData, any>({
        path: `/transactions/withdraw`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get the first and last deposit transactions for a user
     *
     * @tags transactions
     * @name GetDeposits
     * @request POST:/transactions/deposits
     * @secure
     */
    getDeposits: (data: GetDepositsPayload, params: RequestParams = {}) =>
      this.request<GetDepositsData, any>({
        path: `/transactions/deposits`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  paymentGateways = {
    /**
     * @description Get all payment gateways
     *
     * @tags payment-gateways
     * @name GetAll
     * @request GET:/payment-gateways
     * @secure
     */
    getAll: (
      query?: {
        /** @default "0" */
        withBalance?: '0' | '1';
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllOutput1, any>({
        path: `/payment-gateways`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Create a payment gateway
     *
     * @tags payment-gateways
     * @name Create
     * @request POST:/payment-gateways
     * @secure
     */
    create: (data: CreateInput, params: RequestParams = {}) =>
      this.request<CreateOutput, any>({
        path: `/payment-gateways`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Soft delete many payment gateways
     *
     * @tags payment-gateways
     * @name SoftDeleteMany
     * @request DELETE:/payment-gateways
     * @secure
     */
    softDeleteMany: (data: SoftDeleteManyPayload, params: RequestParams = {}) =>
      this.request<SoftDeleteManyData, any>({
        path: `/payment-gateways`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update a payment gateway
     *
     * @tags payment-gateways
     * @name Update
     * @request PUT:/payment-gateways/{name}
     * @secure
     */
    update: (name: string, data: UpdateInput, params: RequestParams = {}) =>
      this.request<UpdateOutput, any>({
        path: `/payment-gateways/${name}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Soft delete a payment gateway
     *
     * @tags payment-gateways
     * @name SoftDelete
     * @request DELETE:/payment-gateways/{name}
     * @secure
     */
    softDelete: (name: string, params: RequestParams = {}) =>
      this.request<SoftDeleteData, any>({
        path: `/payment-gateways/${name}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Get balance of a payment gateway
     *
     * @tags payment-gateways
     * @name GetBalance
     * @request GET:/payment-gateways/{name}/balance
     * @secure
     */
    getBalance: (name: string, params: RequestParams = {}) =>
      this.request<GetBalanceData, any>({
        path: `/payment-gateways/${name}/balance`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  config = {
    /**
     * @description create a new config, or update an existing one
     *
     * @tags config
     * @name UpsertConfig
     * @request POST:/config
     * @secure
     */
    upsertConfig: (data: UpsertConfigPayload, params: RequestParams = {}) =>
      this.request<UpsertConfigData, any>({
        path: `/config`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description list of all latest config
     *
     * @tags config
     * @name GetLatest
     * @request GET:/config
     * @secure
     */
    getLatest: (
      query?: {
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetLatestData, any>({
        path: `/config`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description config value by name
     *
     * @tags config
     * @name GetByName
     * @request GET:/config/{name}
     * @secure
     */
    getByName: (name: string, params: RequestParams = {}) =>
      this.request<
        GetByNameData,
        {
          message: string;
        }
      >({
        path: `/config/${name}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description history of config by name
     *
     * @tags config
     * @name GetHistoryByName
     * @request GET:/config/{name}/history
     * @secure
     */
    getHistoryByName: (name: string, params: RequestParams = {}) =>
      this.request<GetHistoryByNameData, any>({
        path: `/config/${name}/history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description delete many configs by name
     *
     * @tags config
     * @name DeleteManyByName
     * @request DELETE:/config/permanent
     * @secure
     */
    deleteManyByName: (data: DeleteManyByNamePayload, params: RequestParams = {}) =>
      this.request<
        DeleteManyByNameData,
        {
          message: string;
        }
      >({
        path: `/config/permanent`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  entities = {
    /**
     * @description list of all entities
     *
     * @tags entities
     * @name GetAll
     * @request GET:/entities
     * @secure
     */
    getAll: (params: RequestParams = {}) =>
      this.request<GetAllOutput2, any>({
        path: `/entities`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description add a new entity
     *
     * @tags entities
     * @name EntitiesCreate
     * @request POST:/entities
     * @secure
     */
    entitiesCreate: (data: EntitiesCreatePayload, params: RequestParams = {}) =>
      this.request<EntitiesCreateData, any>({
        path: `/entities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete an entity
     *
     * @tags entities
     * @name DeleteByName
     * @request DELETE:/entities/{name}
     * @secure
     */
    deleteByName: (name: string, params: RequestParams = {}) =>
      this.request<DeleteByNameData, any>({
        path: `/entities/${name}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description edit an entity
     *
     * @tags entities
     * @name UpdateByName
     * @request PUT:/entities/{name}
     * @secure
     */
    updateByName: (name: string, data: UpdateByNamePayload, params: RequestParams = {}) =>
      this.request<UpdateByNameData, any>({
        path: `/entities/${name}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  extendedFields = {
    /**
     * @description list of all extended fields
     *
     * @tags entities, extended-fields
     * @name GetAll
     * @request GET:/extended-fields
     * @secure
     */
    getAll: (
      query?: {
        kycFormName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllResult1, any>({
        path: `/extended-fields`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description upsert an extended field
     *
     * @tags entities, extended-fields
     * @name Upsert
     * @request PUT:/extended-fields
     * @secure
     */
    upsert: (data: UpsertPayload, params: RequestParams = {}) =>
      this.request<UpsertData, any>({
        path: `/extended-fields`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete multiple fields
     *
     * @tags entities, extended-fields
     * @name DeleteMany
     * @request DELETE:/extended-fields
     * @secure
     */
    deleteMany: (data: DeleteManyBody, params: RequestParams = {}) =>
      this.request<DeleteManyResult, any>({
        path: `/extended-fields`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete a field
     *
     * @tags entities, extended-fields
     * @name Delete
     * @request DELETE:/extended-fields/{name}
     * @secure
     */
    delete: (name: string, params: RequestParams = {}) =>
      this.request<DeleteResult, any>({
        path: `/extended-fields/${name}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description list of all sections
     *
     * @tags entities, extended-fields
     * @name GetSections
     * @request GET:/extended-fields/sections
     * @secure
     */
    getSections: (params: RequestParams = {}) =>
      this.request<GetSectionsData, any>({
        path: `/extended-fields/sections`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  driveWealth = {
    /**
     * @description get all drive wealth users
     *
     * @tags entities, drive-wealth
     * @name GetAllUsers
     * @request GET:/drive-wealth/users
     * @secure
     */
    getAllUsers: (params: RequestParams = {}) =>
      this.request<GetAllUsersData, any>({
        path: `/drive-wealth/users`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description create drive wealth user from user kyc form
     *
     * @tags entities, drive-wealth
     * @name CreateUser
     * @request POST:/drive-wealth/users
     * @secure
     */
    createUser: (data: CreateUserPayload, params: RequestParams = {}) =>
      this.request<CreateUserData, any>({
        path: `/drive-wealth/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description soft delete many drive wealth users
     *
     * @tags entities, drive-wealth
     * @name SoftDeleteManyUsersByIds
     * @request DELETE:/drive-wealth/users
     * @secure
     */
    softDeleteManyUsersByIds: (data: SoftDeleteManyUsersByIdsPayload, params: RequestParams = {}) =>
      this.request<
        SoftDeleteManyUsersByIdsData,
        {
          message: string;
        }
      >({
        path: `/drive-wealth/users`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description update drive wealth user
     *
     * @tags entities, drive-wealth
     * @name UpdateUser
     * @request PATCH:/drive-wealth/users/{user}
     * @secure
     */
    updateUser: (user: string, data: UpdateUserPayload, params: RequestParams = {}) =>
      this.request<UpdateUserData, any>({
        path: `/drive-wealth/users/${user}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description soft delete drive wealth user
     *
     * @tags entities, drive-wealth
     * @name SoftDeleteUserById
     * @request DELETE:/drive-wealth/users/{user}
     * @secure
     */
    softDeleteUserById: (user: string, params: RequestParams = {}) =>
      this.request<
        SoftDeleteUserByIdData,
        {
          message: string;
        }
      >({
        path: `/drive-wealth/users/${user}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description get drive wealth user by user kyc form id
     *
     * @tags entities, drive-wealth
     * @name GetUserByKycFormId
     * @request GET:/drive-wealth/users/{formId}
     * @secure
     */
    getUserByKycFormId: (formId: string, params: RequestParams = {}) =>
      this.request<GetUserByKycFormIdData, any>({
        path: `/drive-wealth/users/${formId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description sync drive wealth user status
     *
     * @tags entities, drive-wealth
     * @name SyncUserStatus
     * @request POST:/drive-wealth/users/{user}/sync
     * @secure
     */
    syncUserStatus: (user: string, params: RequestParams = {}) =>
      this.request<SyncUserStatusData, any>({
        path: `/drive-wealth/users/${user}/sync`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description get all drive wealth accounts
     *
     * @tags entities, drive-wealth
     * @name GetAllAccounts
     * @request GET:/drive-wealth/accounts
     * @secure
     */
    getAllAccounts: (params: RequestParams = {}) =>
      this.request<GetAllAccountsData, any>({
        path: `/drive-wealth/accounts`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description soft delete many drive wealth accounts
     *
     * @tags entities, drive-wealth
     * @name SoftDeleteManyAccountsByIds
     * @request DELETE:/drive-wealth/accounts
     * @secure
     */
    softDeleteManyAccountsByIds: (
      data: SoftDeleteManyAccountsByIdsPayload,
      params: RequestParams = {}
    ) =>
      this.request<
        SoftDeleteManyAccountsByIdsData,
        {
          message: string;
        }
      >({
        path: `/drive-wealth/accounts`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description create drive wealth account
     *
     * @tags entities, drive-wealth
     * @name CreateAccount
     * @request POST:/drive-wealth/accounts/{user}
     * @secure
     */
    createAccount: (user: string, data: CreateAccountPayload, params: RequestParams = {}) =>
      this.request<
        CreateAccountData,
        {
          message: string;
        }
      >({
        path: `/drive-wealth/accounts/${user}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get all drive wealth accounts for a given user id
     *
     * @tags entities, drive-wealth
     * @name GetAllAccountsByUserId
     * @request GET:/drive-wealth/accounts/by-user/{user}
     * @secure
     */
    getAllAccountsByUserId: (
      user: string,
      query?: {
        userKycFormId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllAccountsByUserIdData, any>({
        path: `/drive-wealth/accounts/by-user/${user}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description sync drive wealth account status
     *
     * @tags entities, drive-wealth
     * @name SyncAccountStatus
     * @request POST:/drive-wealth/accounts/{account}/sync
     * @secure
     */
    syncAccountStatus: (account: string, params: RequestParams = {}) =>
      this.request<SyncAccountStatusData, any>({
        path: `/drive-wealth/accounts/${account}/sync`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  commentTemplates = {
    /**
     * @description create a comment template
     *
     * @tags entities, comment-templates
     * @name Create
     * @request POST:/comment-templates
     * @secure
     */
    create: (data: CreateBody1, params: RequestParams = {}) =>
      this.request<CreateResult1, any>({
        path: `/comment-templates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get comment templates
     *
     * @tags entities, comment-templates
     * @name GetAll
     * @request GET:/comment-templates
     * @secure
     */
    getAll: (params: RequestParams = {}) =>
      this.request<GetAllResult2, any>({
        path: `/comment-templates`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description delete many comment templates
     *
     * @tags entities, comment-templates
     * @name DeleteMany
     * @request DELETE:/comment-templates
     * @secure
     */
    deleteMany: (data: DeleteManyInput, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/comment-templates`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get comment templates minimal
     *
     * @tags entities, comment-templates
     * @name GetMinimal
     * @request GET:/comment-templates/minimal
     * @secure
     */
    getMinimal: (params: RequestParams = {}) =>
      this.request<GetMinimalData, any>({
        path: `/comment-templates/minimal`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description update a comment template
     *
     * @tags entities, comment-templates
     * @name Update
     * @request PUT:/comment-templates/{id}
     * @secure
     */
    update: (id: number, data: UpdateBody1, params: RequestParams = {}) =>
      this.request<UpdateResult1, any>({
        path: `/comment-templates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete a comment template
     *
     * @tags entities, comment-templates
     * @name Delete
     * @request DELETE:/comment-templates/{id}
     * @secure
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOutput, any>({
        path: `/comment-templates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  i18N = {
    /**
     * @description create translations
     *
     * @tags entities, i18n
     * @name Create
     * @request POST:/i18n
     * @secure
     */
    create: (data: CreateBody2, params: RequestParams = {}) =>
      this.request<CreateResult2, any>({
        path: `/i18n`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete many translations by ids
     *
     * @tags entities, i18n
     * @name SoftDeleteManyByIds
     * @request DELETE:/i18n
     * @secure
     */
    softDeleteManyByIds: (data: SoftDeleteManyByIdsBody, params: RequestParams = {}) =>
      this.request<
        SoftDeleteManyByIdsResult,
        {
          message: string;
        }
      >({
        path: `/i18n`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get all translations
     *
     * @tags i18n
     * @name GetAll
     * @request GET:/i18n
     */
    getAll: (
      query?: {
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllOutput3, any>({
        path: `/i18n`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description update a translation by id
     *
     * @tags entities, i18n
     * @name UpdateById
     * @request PUT:/i18n/{id}
     * @secure
     */
    updateById: (id: string, data: UpdateByIdPayload, params: RequestParams = {}) =>
      this.request<
        UpdateByIdData,
        {
          message: string;
        }
      >({
        path: `/i18n/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete a translation by id
     *
     * @tags entities, i18n
     * @name SoftDeleteById
     * @request DELETE:/i18n/{id}
     * @secure
     */
    softDeleteById: (id: string, params: RequestParams = {}) =>
      this.request<SoftDeleteByIdResult, any>({
        path: `/i18n/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description get a translation by id
     *
     * @tags i18n
     * @name GetI18NById
     * @request GET:/i18n/{id}
     */
    getI18NById: (id: string, params: RequestParams = {}) =>
      this.request<
        GetI18NByIdData,
        {
          message: string;
        }
      >({
        path: `/i18n/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description permanently delete a translation by id
     *
     * @tags entities, i18n
     * @name DeleteById
     * @request DELETE:/i18n/{id}/permanent
     * @secure
     */
    deleteById: (id: string, params: RequestParams = {}) =>
      this.request<DeleteByIdData, any>({
        path: `/i18n/${id}/permanent`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description permanently delete many translations by ids
     *
     * @tags entities, i18n
     * @name DeleteManyByIds
     * @request DELETE:/i18n/permanent
     * @secure
     */
    deleteManyByIds: (data: DeleteManyByIdsPayload, params: RequestParams = {}) =>
      this.request<
        DeleteManyByIdsData,
        {
          message: string;
        }
      >({
        path: `/i18n/permanent`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get translations by section
     *
     * @tags i18n
     * @name GetI18NBySection
     * @request GET:/i18n/section/{section}/{language}
     */
    getI18NBySection: (section: string, language: string, params: RequestParams = {}) =>
      this.request<GetI18NBySectionData, any>({
        path: `/i18n/section/${section}/${language}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description get translations by language
     *
     * @tags i18n
     * @name GetI18NByLanguage
     * @request GET:/i18n/language/{language}
     */
    getI18NByLanguage: (language: string, params: RequestParams = {}) =>
      this.request<GetI18NByLanguageData, any>({
        path: `/i18n/language/${language}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description get translations by key
     *
     * @tags i18n
     * @name GetI18NByKey
     * @request GET:/i18n/key/{key}
     */
    getI18NByKey: (key: string, params: RequestParams = {}) =>
      this.request<GetI18NByKeyData, any>({
        path: `/i18n/key/${key}`,
        method: 'GET',
        ...params,
      }),
  };
  tradingServers = {
    /**
     * @description list of all trading servers
     *
     * @tags entities, trading-servers
     * @name GetAll
     * @request GET:/trading-servers
     * @secure
     */
    getAll: (
      query?: {
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllResult3, any>({
        path: `/trading-servers`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description create a new trading server
     *
     * @tags entities, trading-servers
     * @name Create
     * @request POST:/trading-servers
     * @secure
     */
    create: (data: CreateBody3, params: RequestParams = {}) =>
      this.request<CreateResult3, any>({
        path: `/trading-servers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete multiple trading servers
     *
     * @tags entities, trading-servers
     * @name DeleteMultipleByIds
     * @request DELETE:/trading-servers
     * @secure
     */
    deleteMultipleByIds: (data: DeleteMultipleByIdsPayload, params: RequestParams = {}) =>
      this.request<DeleteMultipleByIdsData, any>({
        path: `/trading-servers`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get all trading servers for a given user id
     *
     * @tags entities, trading-servers
     * @name GetAllByUserId
     * @request GET:/trading-servers/by-user
     * @secure
     */
    getAllByUserId: (
      query: {
        user: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllByUserIdData, any>({
        path: `/trading-servers/by-user`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description get trading server details
     *
     * @tags entities, trading-servers
     * @name GetById
     * @request GET:/trading-servers/{id}
     * @secure
     */
    getById: (id: string, params: RequestParams = {}) =>
      this.request<GetByIdData, any>({
        path: `/trading-servers/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description update a trading server
     *
     * @tags entities, trading-servers
     * @name Update
     * @request PATCH:/trading-servers/{id}
     * @secure
     */
    update: (id: string, data: UpdateInput1, params: RequestParams = {}) =>
      this.request<UpdateData1, any>({
        path: `/trading-servers/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete a trading server
     *
     * @tags entities, trading-servers
     * @name Delete
     * @request DELETE:/trading-servers/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<DeleteResult1, any>({
        path: `/trading-servers/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description fetch group names by trading server details
     *
     * @tags entities, trading-servers
     * @name GetGroupsByConfig
     * @request POST:/trading-servers/groups
     * @secure
     */
    getGroupsByConfig: (data: GetGroupsByConfigPayload, params: RequestParams = {}) =>
      this.request<GetGroupsByConfigData, any>({
        path: `/trading-servers/groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description verify mt server
     *
     * @tags entities, trading-servers
     * @name VerifyMtServer
     * @request POST:/trading-servers/verify
     * @secure
     */
    verifyMtServer: (data: VerifyMtServerPayload, params: RequestParams = {}) =>
      this.request<VerifyMtServerData, any>({
        path: `/trading-servers/verify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  tradingAccounts = {
    /**
     * @description get all trading accounts
     *
     * @tags entities, trading-accounts
     * @name GetAllByServerId
     * @request GET:/trading-accounts/by-server/{serverId}
     * @secure
     */
    getAllByServerId: (
      serverId: string,
      query?: {
        kycFormId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllByServerIdOutput, any>({
        path: `/trading-accounts/by-server/${serverId}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description get trading account details from external server
     *
     * @tags entities, trading-accounts
     * @name GetById
     * @request GET:/trading-accounts/{id}
     * @secure
     */
    getById: (id: string, params: RequestParams = {}) =>
      this.request<GetByIdResult, any>({
        path: `/trading-accounts/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description update a trading account
     *
     * @tags entities, trading-accounts
     * @name Update
     * @request PATCH:/trading-accounts/{id}
     * @secure
     */
    update: (id: string, data: UpdateBody2, params: RequestParams = {}) =>
      this.request<UpdateResult2, any>({
        path: `/trading-accounts/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete a trading account
     *
     * @tags entities, trading-accounts
     * @name Delete
     * @request DELETE:/trading-accounts/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<DeleteData1, any>({
        path: `/trading-accounts/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description get internal balance of a trading account
     *
     * @tags entities, trading-accounts
     * @name GetInternalBalance
     * @request GET:/trading-accounts/{id}/internal-balance
     * @secure
     */
    getInternalBalance: (id: string, params: RequestParams = {}) =>
      this.request<GetInternalBalanceData, any>({
        path: `/trading-accounts/${id}/internal-balance`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all trading accounts for a given user id
     *
     * @tags entities, trading-accounts
     * @name GetAllByUserId
     * @request GET:/trading-accounts/by-user/{user}
     * @secure
     */
    getAllByUserId: (
      user: string,
      query?: {
        /** @default "false" */
        withExternal?: 'true' | 'false';
        formId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllByUserIdResult, any>({
        path: `/trading-accounts/by-user/${user}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description create a new trading account
     *
     * @tags entities, trading-accounts
     * @name Create
     * @request POST:/trading-accounts
     * @secure
     */
    create: (data: CreateBody4, params: RequestParams = {}) =>
      this.request<CreateData1, any>({
        path: `/trading-accounts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete multiple trading accounts
     *
     * @tags entities, trading-accounts
     * @name DeleteMultipleByIds
     * @request DELETE:/trading-accounts
     * @secure
     */
    deleteMultipleByIds: (data: DeleteMultipleByIdsBody, params: RequestParams = {}) =>
      this.request<DeleteMultipleByIdsResult, any>({
        path: `/trading-accounts`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description disable a trading account on mt server
     *
     * @tags entities, trading-accounts
     * @name Disable
     * @request POST:/trading-accounts/{id}/disable
     * @secure
     */
    disable: (id: string, params: RequestParams = {}) =>
      this.request<DisableData, any>({
        path: `/trading-accounts/${id}/disable`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description reactivate a trading account on mt server
     *
     * @tags entities, trading-accounts
     * @name Reactivate
     * @request POST:/trading-accounts/{id}/reactivate
     * @secure
     */
    reactivate: (id: string, params: RequestParams = {}) =>
      this.request<ReactivateData, any>({
        path: `/trading-accounts/${id}/reactivate`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description reactivate many trading accounts on mt servers
     *
     * @tags entities, trading-accounts
     * @name ReactivateMany
     * @request POST:/trading-accounts/reactivate
     * @secure
     */
    reactivateMany: (data: ReactivateManyPayload, params: RequestParams = {}) =>
      this.request<ReactivateManyData, any>({
        path: `/trading-accounts/reactivate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description disable many trading accounts on mt servers
     *
     * @tags entities, trading-accounts
     * @name DisableMany
     * @request POST:/trading-accounts/disable
     * @secure
     */
    disableMany: (data: DisableManyPayload, params: RequestParams = {}) =>
      this.request<DisableManyData, any>({
        path: `/trading-accounts/disable`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description unlink a trading account from a user in trading server
     *
     * @tags entities, trading-accounts
     * @name UnlinkAccount
     * @request POST:/trading-accounts/{id}/unlink
     * @secure
     */
    unlinkAccount: (id: string, params: RequestParams = {}) =>
      this.request<
        UnlinkAccountData,
        {
          message: string;
        }
      >({
        path: `/trading-accounts/${id}/unlink`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description unlink many trading accounts from users in trading server
     *
     * @tags entities, trading-accounts
     * @name UnlinkManyAccounts
     * @request POST:/trading-accounts/unlink
     * @secure
     */
    unlinkManyAccounts: (data: UnlinkManyAccountsPayload, params: RequestParams = {}) =>
      this.request<UnlinkManyAccountsData, any>({
        path: `/trading-accounts/unlink`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description link a trading account to a user
     *
     * @tags entities, trading-accounts
     * @name LinkAccount
     * @request POST:/trading-accounts/link
     * @secure
     */
    linkAccount: (data: LinkAccountPayload, params: RequestParams = {}) =>
      this.request<
        LinkAccountData,
        {
          message: string;
        }
      >({
        path: `/trading-accounts/link`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description link existing trading account to account in mt server
     *
     * @tags entities, trading-accounts
     * @name LinkExistingAccount
     * @request POST:/trading-accounts/link-existing
     * @secure
     */
    linkExistingAccount: (data: LinkExistingAccountPayload, params: RequestParams = {}) =>
      this.request<
        LinkExistingAccountData,
        {
          message: string;
        }
      >({
        path: `/trading-accounts/link-existing`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description create a new trading account from kyc form and accountType
     *
     * @tags entities, trading-accounts
     * @name CreateFromKycForm
     * @request POST:/trading-accounts/kyc-form
     * @secure
     */
    createFromKycForm: (data: CreateFromKycFormPayload, params: RequestParams = {}) =>
      this.request<CreateFromKycFormData, any>({
        path: `/trading-accounts/kyc-form`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description reset password of a trading account
     *
     * @tags entities, trading-accounts
     * @name ResetPassword
     * @request POST:/trading-accounts/{id}/reset-password
     * @secure
     */
    resetPassword: (id: string, params: RequestParams = {}) =>
      this.request<ResetPasswordData, any>({
        path: `/trading-accounts/${id}/reset-password`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description get external balance of a trading account
     *
     * @tags entities, trading-accounts
     * @name GetExternalAccounts
     * @request GET:/trading-accounts/external-balance
     * @secure
     */
    getExternalAccounts: (
      query: {
        cuid: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetExternalAccountsData, any>({
        path: `/trading-accounts/external-balance`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description get balance of a trading account
     *
     * @tags entities, trading-accounts
     * @name GetBalance
     * @request GET:/trading-accounts/{id}/balance
     * @secure
     */
    getBalance: (id: string, params: RequestParams = {}) =>
      this.request<GetBalanceResult, any>({
        path: `/trading-accounts/${id}/balance`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get balances of multiple trading accounts
     *
     * @tags entities, trading-accounts
     * @name GetBalances
     * @request POST:/trading-accounts/balances
     * @secure
     */
    getBalances: (data: GetBalancesPayload, params: RequestParams = {}) =>
      this.request<GetBalancesData, any>({
        path: `/trading-accounts/balances`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get positions of a trading account
     *
     * @tags entities, trading-accounts
     * @name GetPositions
     * @request GET:/trading-accounts/{id}/positions
     * @secure
     */
    getPositions: (id: string, params: RequestParams = {}) =>
      this.request<GetPositionsData, any>({
        path: `/trading-accounts/${id}/positions`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get orders of a trading account
     *
     * @tags entities, trading-accounts
     * @name GetOrders
     * @request GET:/trading-accounts/{id}/orders
     * @secure
     */
    getOrders: (id: string, params: RequestParams = {}) =>
      this.request<GetOrdersData, any>({
        path: `/trading-accounts/${id}/orders`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get history of a trading account
     *
     * @tags entities, trading-accounts
     * @name GetHistory
     * @request GET:/trading-accounts/{id}/history
     * @secure
     */
    getHistory: (id: string, params: RequestParams = {}) =>
      this.request<GetHistoryData, any>({
        path: `/trading-accounts/${id}/history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get data of a trading account
     *
     * @tags entities, trading-accounts
     * @name GetData
     * @request GET:/trading-accounts/{id}/data
     * @secure
     */
    getData: (id: string, params: RequestParams = {}) =>
      this.request<GetDataData, any>({
        path: `/trading-accounts/${id}/data`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get data for many trading accounts id
     *
     * @tags entities, trading-accounts
     * @name GetDataForManyIds
     * @request POST:/trading-accounts/data
     * @secure
     */
    getDataForManyIds: (data: GetDataForManyIdsPayload, params: RequestParams = {}) =>
      this.request<GetDataForManyIdsData, any>({
        path: `/trading-accounts/data`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description create a new trading account from kyc form and accountType
     *
     * @tags trading-accounts
     * @name AdminCreateFromKycForm
     * @request POST:/trading-accounts/admin-kyc-form
     */
    adminCreateFromKycForm: (data: AdminCreateFromKycFormPayload, params: RequestParams = {}) =>
      this.request<AdminCreateFromKycFormData, any>({
        path: `/trading-accounts/admin-kyc-form`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  notificationTemplates = {
    /**
     * @description list of all notification templates
     *
     * @tags entities, notification-templates
     * @name GetAll
     * @request GET:/notification-templates
     * @secure
     */
    getAll: (
      query?: {
        lang?: string;
        key?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllData1, any>({
        path: `/notification-templates`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description create a new notification template
     *
     * @tags entities, notification-templates
     * @name Create
     * @request POST:/notification-templates
     * @secure
     */
    create: (data: CreatePayload1, params: RequestParams = {}) =>
      this.request<CreateOutput1, any>({
        path: `/notification-templates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete multiple notification templates by ids
     *
     * @tags entities, notification-templates
     * @name DeleteManyByIds
     * @request DELETE:/notification-templates
     * @secure
     */
    deleteManyByIds: (data: DeleteManyByIdsBody, params: RequestParams = {}) =>
      this.request<DeleteManyByIdsResult, any>({
        path: `/notification-templates`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get notification templates by lang
     *
     * @tags entities, notification-templates
     * @name GetByLang
     * @request GET:/notification-templates/by-lang/{lang}
     * @secure
     */
    getByLang: (lang: string, params: RequestParams = {}) =>
      this.request<GetByLangData, any>({
        path: `/notification-templates/by-lang/${lang}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get notification templates by key
     *
     * @tags entities, notification-templates
     * @name GetByKey
     * @request GET:/notification-templates/by-key/{key}
     * @secure
     */
    getByKey: (key: string, params: RequestParams = {}) =>
      this.request<GetByKeyData, any>({
        path: `/notification-templates/by-key/${key}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get notification template details
     *
     * @tags entities, notification-templates
     * @name GetById
     * @request GET:/notification-templates/{id}
     * @secure
     */
    getById: (id: number, params: RequestParams = {}) =>
      this.request<GetByIdOutput, any>({
        path: `/notification-templates/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description update notification template details
     *
     * @tags entities, notification-templates
     * @name Update
     * @request PATCH:/notification-templates/{id}
     * @secure
     */
    update: (id: number, data: UpdateBody3, params: RequestParams = {}) =>
      this.request<UpdateResult3, any>({
        path: `/notification-templates/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description delete notification template by id
     *
     * @tags entities, notification-templates
     * @name DeleteById
     * @request DELETE:/notification-templates/{id}
     * @secure
     */
    deleteById: (id: number, params: RequestParams = {}) =>
      this.request<DeleteByIdResult, any>({
        path: `/notification-templates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  users = {
    /**
     * @description list paginated users list
     *
     * @tags entities, Users:Admin
     * @name GetAll
     * @request GET:/users
     * @secure
     */
    getAll: (
      query?: {
        /** search by email or cuid or trading account external id */
        search?: string;
        skip?: number;
        limit?: number;
        orderBy?: 'email' | 'createdAt';
        orderDirection?: 'asc' | 'desc';
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllResult4, any>({
        path: `/users`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description get user by id
     *
     * @tags entities, Users:Admin
     * @name GetById
     * @request GET:/users/{id}
     * @secure
     */
    getById: (id: string, params: RequestParams = {}) =>
      this.request<
        GetByIdResult1,
        {
          message: string;
        }
      >({
        path: `/users/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get wallet balance for a user_kyc_form
     *
     * @tags entities, Users:Admin
     * @name GetWalletsBalance
     * @request GET:/users/{kycId}/balance
     * @secure
     */
    getWalletsBalance: (kycId: string, params: RequestParams = {}) =>
      this.request<GetWalletsBalanceData, any>({
        path: `/users/${kycId}/balance`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  tags = {
    /**
     * @description Create a Tag
     *
     * @tags entities, tags
     * @name Create
     * @request POST:/tags
     * @secure
     */
    create: (data: CreatePayload2, params: RequestParams = {}) =>
      this.request<CreateResult4, any>({
        path: `/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get all tags
     *
     * @tags entities, tags
     * @name GetAll
     * @request GET:/tags
     * @secure
     */
    getAll: (params: RequestParams = {}) =>
      this.request<GetAllData2, any>({
        path: `/tags`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Update a tag
     *
     * @tags entities, tags
     * @name Update
     * @request PATCH:/tags/{name}
     * @secure
     */
    update: (name: string, data: UpdateBody4, params: RequestParams = {}) =>
      this.request<UpdateResult4, any>({
        path: `/tags/${name}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  userNote = {
    /**
     * @description Create a user note
     *
     * @tags entities, user note
     * @name UserNoteCreate
     * @request POST:/user-note/{cuid}
     * @secure
     */
    userNoteCreate: (cuid: string, data: UserNoteCreatePayload, params: RequestParams = {}) =>
      this.request<UserNoteCreateData, any>({
        path: `/user-note/${cuid}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get user note
     *
     * @tags entities, user note
     * @name UserNoteDetail
     * @request GET:/user-note/{cuid}
     * @secure
     */
    userNoteDetail: (cuid: string, params: RequestParams = {}) =>
      this.request<UserNoteDetailData, any>({
        path: `/user-note/${cuid}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Update a user note
     *
     * @tags entities, user note
     * @name UserNoteUpdate
     * @request PUT:/user-note/{noteId}
     * @secure
     */
    userNoteUpdate: (noteId: string, data: UserNoteUpdatePayload, params: RequestParams = {}) =>
      this.request<UserNoteUpdateData, any>({
        path: `/user-note/${noteId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  bankAccounts = {
    /**
     * @description Create a bank account for a user
     *
     * @tags entities, bank-accounts
     * @name CreateBankAccount
     * @request POST:/bank-accounts/
     * @secure
     */
    createBankAccount: (data: CreateBankAccountPayload, params: RequestParams = {}) =>
      this.request<CreateBankAccountData, any>({
        path: `/bank-accounts/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Approve or reject a bank account
     *
     * @tags entities, bank-accounts
     * @name RejectOrApproveBankAccount
     * @request PUT:/bank-accounts/{action}/{bankAccountId}
     * @secure
     */
    rejectOrApproveBankAccount: (
      bankAccountId: string,
      action: 'approve' | 'reject',
      params: RequestParams = {}
    ) =>
      this.request<RejectOrApproveBankAccountData, any>({
        path: `/bank-accounts/${action}/${bankAccountId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description Get a bank account
     *
     * @tags entities, bank-accounts
     * @name GetBankAccountsForUser
     * @request GET:/bank-accounts/{userId}
     * @secure
     */
    getBankAccountsForUser: (userId: string, params: RequestParams = {}) =>
      this.request<GetBankAccountsForUserData, any>({
        path: `/bank-accounts/${userId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Archive a bank account
     *
     * @tags entities, bank-accounts
     * @name ArchiveBankAccount
     * @request PUT:/bank-accounts/archive/{bankAccountId}
     * @secure
     */
    archiveBankAccount: (bankAccountId: string, params: RequestParams = {}) =>
      this.request<ArchiveBankAccountData, any>({
        path: `/bank-accounts/archive/${bankAccountId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description toggle a bank account default status for an entity
     *
     * @tags entities, bank-accounts
     * @name ToggleBankAccountDefault
     * @request PUT:/bank-accounts/default/{bankAccountId}
     * @secure
     */
    toggleBankAccountDefault: (
      bankAccountId: string,
      data: ToggleBankAccountDefaultPayload,
      params: RequestParams = {}
    ) =>
      this.request<ToggleBankAccountDefaultData, any>({
        path: `/bank-accounts/default/${bankAccountId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  creditCards = {
    /**
     * @description Create a new credit card type
     *
     * @tags entities, credit-cards
     * @name CreateCreditCardType
     * @request POST:/credit-cards/types
     * @secure
     */
    createCreditCardType: (data: CreateCreditCardTypePayload, params: RequestParams = {}) =>
      this.request<CreateCreditCardTypeData, any>({
        path: `/credit-cards/types`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get all credit card types
     *
     * @tags entities, credit-cards
     * @name GetAllCreditCardTypes
     * @request GET:/credit-cards/types
     * @secure
     */
    getAllCreditCardTypes: (params: RequestParams = {}) =>
      this.request<GetAllCreditCardTypesData, any>({
        path: `/credit-cards/types`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Create a credit card for a user
     *
     * @tags entities, credit-cards
     * @name CreateCreditCard
     * @request POST:/credit-cards/
     * @secure
     */
    createCreditCard: (data: CreateCreditCardPayload, params: RequestParams = {}) =>
      this.request<CreateCreditCardData, any>({
        path: `/credit-cards/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Approve or reject a credit card
     *
     * @tags entities, credit-cards
     * @name RejectOrApproveCreditCard
     * @request PUT:/credit-cards/{action}/{creditCardId}
     * @secure
     */
    rejectOrApproveCreditCard: (
      creditCardId: string,
      action: 'approve' | 'reject',
      params: RequestParams = {}
    ) =>
      this.request<RejectOrApproveCreditCardData, any>({
        path: `/credit-cards/${action}/${creditCardId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description Get credit cards for a user
     *
     * @tags entities, credit-cards
     * @name GetCreditCardsForUser
     * @request GET:/credit-cards/{userId}
     * @secure
     */
    getCreditCardsForUser: (userId: string, params: RequestParams = {}) =>
      this.request<GetCreditCardsForUserData, any>({
        path: `/credit-cards/${userId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Archive a credit card
     *
     * @tags entities, credit-cards
     * @name ArchiveCreditCard
     * @request PUT:/credit-cards/archive/{creditCardId}
     * @secure
     */
    archiveCreditCard: (creditCardId: string, params: RequestParams = {}) =>
      this.request<ArchiveCreditCardData, any>({
        path: `/credit-cards/archive/${creditCardId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description toggle a credit card default status for an entity
     *
     * @tags entities, credit-cards
     * @name MakeCreditCardDefault
     * @request PUT:/credit-cards/default/{creditCardId}
     * @secure
     */
    makeCreditCardDefault: (
      creditCardId: string,
      data: MakeCreditCardDefaultPayload,
      params: RequestParams = {}
    ) =>
      this.request<MakeCreditCardDefaultData, any>({
        path: `/credit-cards/default/${creditCardId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  fields = {
    /**
     * @description get all user fields
     *
     * @tags entities, db_fields
     * @name GetAllUserFields
     * @request GET:/fields/user
     * @secure
     */
    getAllUserFields: (params: RequestParams = {}) =>
      this.request<GetAllUserFieldsData, any>({
        path: `/fields/user`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all metadata fields
     *
     * @tags entities, db_fields
     * @name GetAllMetadataFields
     * @request GET:/fields/metadata
     * @secure
     */
    getAllMetadataFields: (params: RequestParams = {}) =>
      this.request<GetAllMetadataFieldsData, any>({
        path: `/fields/metadata`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all transaction fields
     *
     * @tags entities, db_fields
     * @name GetAllTransactionFields
     * @request GET:/fields/transaction
     * @secure
     */
    getAllTransactionFields: (params: RequestParams = {}) =>
      this.request<GetAllTransactionFieldsData, any>({
        path: `/fields/transaction`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all transaction entry fields
     *
     * @tags entities, db_fields
     * @name GetAllTransactionEntryFields
     * @request GET:/fields/transaction-entry
     * @secure
     */
    getAllTransactionEntryFields: (params: RequestParams = {}) =>
      this.request<GetAllTransactionEntryFieldsData, any>({
        path: `/fields/transaction-entry`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all user kyc form fields
     *
     * @tags entities, db_fields
     * @name GetAllUserKycFormFields
     * @request GET:/fields/user-kyc-form
     * @secure
     */
    getAllUserKycFormFields: (params: RequestParams = {}) =>
      this.request<GetAllUserKycFormFieldsData, any>({
        path: `/fields/user-kyc-form`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all trading account fields
     *
     * @tags entities, db_fields
     * @name GetAllTradingAccountFields
     * @request GET:/fields/trading-account
     * @secure
     */
    getAllTradingAccountFields: (params: RequestParams = {}) =>
      this.request<GetAllTradingAccountFieldsData, any>({
        path: `/fields/trading-account`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  exchangeRates = {
    /**
     * No description
     *
     * @tags entities, exchange-rates
     * @name GetAllExchangeRates
     * @request GET:/exchange-rates
     * @secure
     */
    getAllExchangeRates: (
      query?: {
        /** @minLength 1 */
        from?: string;
        /** @minLength 1 */
        to?: string;
        /** @minLength 1 */
        serverId?: string;
        /** @default "1" */
        latest?: '0' | '1';
        /** @minLength 1 */
        group?: string;
        /** @minLength 1 */
        symbol?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllExchangeRatesData, any>({
        path: `/exchange-rates`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags entities, exchange-rates
     * @name CreateExchangeRate
     * @request POST:/exchange-rates
     * @secure
     */
    createExchangeRate: (data: CreateExchangeRatePayload, params: RequestParams = {}) =>
      this.request<CreateExchangeRateData, any>({
        path: `/exchange-rates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags entities, exchange-rates
     * @name UpdateExchangeRate
     * @request PUT:/exchange-rates/{id}
     * @secure
     */
    updateExchangeRate: (id: number, data: UpdateExchangeRatePayload, params: RequestParams = {}) =>
      this.request<UpdateExchangeRateData, any>({
        path: `/exchange-rates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  client = {
    /**
     * @description get my account details
     *
     * @tags Users:Client
     * @name GetMe
     * @request GET:/client/users/me
     * @secure
     */
    getMe: (params: RequestParams = {}) =>
      this.request<GetMeData, any>({
        path: `/client/users/me`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get my account metadata
     *
     * @tags Users:Client
     * @name GetMetaData
     * @request GET:/client/users/metadata
     * @secure
     */
    getMetaData: (params: RequestParams = {}) =>
      this.request<GetMetaDataData, any>({
        path: `/client/users/metadata`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description set my account metadata
     *
     * @tags Users:Client
     * @name UpdateMetaData
     * @request PATCH:/client/users/metadata
     * @secure
     */
    updateMetaData: (data: UpdateMetaDataPayload, params: RequestParams = {}) =>
      this.request<UpdateMetaDataData, any>({
        path: `/client/users/metadata`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get all metadata fields
     *
     * @tags Users:Client
     * @name GetAllMetadataFields
     * @request GET:/client/usersmetadata/fields
     * @secure
     */
    getAllMetadataFields: (params: RequestParams = {}) =>
      this.request<GetAllMetadataFieldsResult, any>({
        path: `/client/usersmetadata/fields`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get all user fields
     *
     * @tags Users:Client
     * @name GetAllUserFields
     * @request GET:/client/usersfields
     * @secure
     */
    getAllUserFields: (params: RequestParams = {}) =>
      this.request<GetAllUserFieldsResult, any>({
        path: `/client/usersfields`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description set my account appdata
     *
     * @tags Users:Client
     * @name UpdateAppData
     * @request PATCH:/client/users/appdata
     * @secure
     */
    updateAppData: (data: UpdateAppDataPayload, params: RequestParams = {}) =>
      this.request<UpdateAppDataData, any>({
        path: `/client/users/appdata`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get my account metadata
     *
     * @tags Users:Client
     * @name GetWatchlist
     * @request GET:/client/users/watchlist
     * @secure
     */
    getWatchlist: (params: RequestParams = {}) =>
      this.request<GetWatchlistData, any>({
        path: `/client/users/watchlist`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description set my account metadata
     *
     * @tags Users:Client
     * @name SetWatchlist
     * @request PATCH:/client/users/watchlist
     * @secure
     */
    setWatchlist: (data: SetWatchlistPayload, params: RequestParams = {}) =>
      this.request<SetWatchlistData, any>({
        path: `/client/users/watchlist`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description verify phone number
     *
     * @tags Users:Client
     * @name VerifyPhoneNumber
     * @request POST:/client/users/verify-phone
     * @secure
     */
    verifyPhoneNumber: (data: VerifyPhoneNumberPayload, params: RequestParams = {}) =>
      this.request<VerifyPhoneNumberData, any>({
        path: `/client/users/verify-phone`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description create a new trading account from kyc form and accountType
     *
     * @tags trading-accounts:Client
     * @name CreateFromKycForm
     * @request POST:/client/trading-accounts/kyc-form
     * @secure
     */
    createFromKycForm: (data: CreateFromKycFormBody, params: RequestParams = {}) =>
      this.request<CreateFromKycFormResult, any>({
        path: `/client/trading-accounts/kyc-form`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get auth user trading account
     *
     * @tags trading-accounts:Client
     * @name GetUserTradingAccounts
     * @request GET:/client/trading-accounts/
     * @secure
     */
    getUserTradingAccounts: (params: RequestParams = {}) =>
      this.request<GetUserTradingAccountsData, any>({
        path: `/client/trading-accounts/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description get external balance of a trading account
     *
     * @tags trading-accounts:Client
     * @name GetExternalAccounts
     * @request GET:/client/trading-accounts/external-balance
     * @secure
     */
    getExternalAccounts: (params: RequestParams = {}) =>
      this.request<GetExternalAccountsResult, any>({
        path: `/client/trading-accounts/external-balance`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-notifications:Client
     * @name GetAllUserNotifications
     * @request GET:/client/user-notifications/{tradingAccountId}
     * @secure
     */
    getAllUserNotifications: (tradingAccountId: string, params: RequestParams = {}) =>
      this.request<GetAllUserNotificationsData, any>({
        path: `/client/user-notifications/${tradingAccountId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-notifications:Client
     * @name DeleteAllUserNotifications
     * @request DELETE:/client/user-notifications/all/{tradingAccountId}
     * @secure
     */
    deleteAllUserNotifications: (tradingAccountId: string, params: RequestParams = {}) =>
      this.request<DeleteAllUserNotificationsData, any>({
        path: `/client/user-notifications/all/${tradingAccountId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-notifications:Client
     * @name DeleteUserNotification
     * @request DELETE:/client/user-notifications/{id}
     * @secure
     */
    deleteUserNotification: (id: string, params: RequestParams = {}) =>
      this.request<DeleteUserNotificationData, any>({
        path: `/client/user-notifications/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-notifications:Client
     * @name MarkAsRead
     * @request POST:/client/user-notifications/mark-as-read/
     * @secure
     */
    markAsRead: (data: MarkAsReadPayload, params: RequestParams = {}) =>
      this.request<MarkAsReadData, any>({
        path: `/client/user-notifications/mark-as-read/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get all my documents
     *
     * @tags documents:Client
     * @name GetMyDocuments
     * @request GET:/client/documents/
     * @secure
     */
    getMyDocuments: (params: RequestParams = {}) =>
      this.request<GetMyDocumentsData, any>({
        path: `/client/documents/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get recurring payments
     *
     * @tags recurring_payment:Client
     * @name GetRecurringPayments
     * @request GET:/client/recurring/
     * @secure
     */
    getRecurringPayments: (params: RequestParams = {}) =>
      this.request<GetRecurringPaymentsData, any>({
        path: `/client/recurring/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Add recurring payment
     *
     * @tags recurring_payment:Client
     * @name AddRecurringPayment
     * @request POST:/client/recurring/
     * @secure
     */
    addRecurringPayment: (data: AddRecurringPaymentPayload, params: RequestParams = {}) =>
      this.request<AddRecurringPaymentData, any>({
        path: `/client/recurring/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update recurring payment
     *
     * @tags recurring_payment:Client
     * @name UpdateRecurringPayment
     * @request PUT:/client/recurring/{id}
     * @secure
     */
    updateRecurringPayment: (
      id: string,
      data: UpdateRecurringPaymentPayload,
      params: RequestParams = {}
    ) =>
      this.request<UpdateRecurringPaymentData, any>({
        path: `/client/recurring/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get recurring payment bills
     *
     * @tags recurring_payment:Client
     * @name GetRecurringPaymentBills
     * @request GET:/client/recurring/{id}/bills
     * @secure
     */
    getRecurringPaymentBills: (id: string, params: RequestParams = {}) =>
      this.request<GetRecurringPaymentBillsData, any>({
        path: `/client/recurring/${id}/bills`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Create recurring payment bill
     *
     * @tags recurring_payment:Client
     * @name CreateRecurringPaymentBill
     * @request POST:/client/recurring/bill
     * @secure
     */
    createRecurringPaymentBill: (
      data: CreateRecurringPaymentBillPayload,
      params: RequestParams = {}
    ) =>
      this.request<CreateRecurringPaymentBillData, any>({
        path: `/client/recurring/bill`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get total deposits for the user
     *
     * @tags transactions:Client
     * @name GetTotalDeposits
     * @request GET:/client/transactions/total-deposits
     * @secure
     */
    getTotalDeposits: (
      query?: {
        accountId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetTotalDepositsData, any>({
        path: `/client/transactions/total-deposits`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get net deposits for the user
     *
     * @tags transactions:Client
     * @name GetNetDeposits
     * @request GET:/client/transactions/net-deposits
     * @secure
     */
    getNetDeposits: (
      query?: {
        accountId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetNetDepositsData, any>({
        path: `/client/transactions/net-deposits`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Transfer money between accounts
     *
     * @tags transactions:Client
     * @name TransferFromAccountToAccount
     * @request POST:/client/transactions/transactions/account-to-account
     * @secure
     */
    transferFromAccountToAccount: (
      data: TransferFromAccountToAccountPayload,
      params: RequestParams = {}
    ) =>
      this.request<
        TransferFromAccountToAccountData,
        {
          success: false;
          error:
            | 'BAD_REQUEST'
            | 'BAD_GATEWAY'
            | 'CROSS_ENTITY'
            | 'BAD_TARGET'
            | 'BAD_ENTITY'
            | 'DUPLICATE'
            | 'INSUFFICIENT_BALANCE'
            | 'BAD_AMOUNT'
            | 'BAD_CURRENCY'
            | 'LOCK_FAILED'
            | 'MT_BALANCE_UPDATE_FAILED'
            | 'BAD_MT_TARGET'
            | 'BAD_BALANCE_TARGET'
            | 'CRITICAL'
            | 'NOT_FOUND';
          message?: string;
        }
      >({
        path: `/client/transactions/transactions/account-to-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get withdrawal request
     *
     * @tags transactions:Client
     * @name GetWithdrawRequests
     * @request GET:/client/transactions/transactions/withdraw
     * @secure
     */
    getWithdrawRequests: (
      query?: {
        status?: 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'REJECTED' | 'ERROR';
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        kycId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetWithdrawRequestsResult, any>({
        path: `/client/transactions/transactions/withdraw`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description User request a Withdraw money out of the system
     *
     * @tags transactions:Client
     * @name RequestWithdraw
     * @request POST:/client/transactions/transactions/withdraw
     * @secure
     */
    requestWithdraw: (data: RequestWithdrawBody, params: RequestParams = {}) =>
      this.request<RequestWithdrawResult, any>({
        path: `/client/transactions/transactions/withdraw`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get lean customer
     *
     * @tags lean_payment_gateway:Client
     * @name GetCustomer
     * @request GET:/client/lean/
     * @secure
     */
    getCustomer: (params: RequestParams = {}) =>
      this.request<GetCustomerData, any>({
        path: `/client/lean/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Request lean payment
     *
     * @tags lean_payment_gateway:Client
     * @name RequestLeanPayment
     * @request POST:/client/lean/
     * @secure
     */
    requestLeanPayment: (data: RequestLeanPaymentPayload, params: RequestParams = {}) =>
      this.request<RequestLeanPaymentData, any>({
        path: `/client/lean/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get user connected accounts
     *
     * @tags lean_payment_gateway:Client
     * @name GetConnectedAccounts
     * @request GET:/client/lean/accounts
     * @secure
     */
    getConnectedAccounts: (params: RequestParams = {}) =>
      this.request<GetConnectedAccountsData, any>({
        path: `/client/lean/accounts`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Request checkout payment
     *
     * @tags checkout_payment_gateway:Client
     * @name RequestCheckoutPayment
     * @request POST:/client/checkout/
     * @secure
     */
    requestCheckoutPayment: (data: RequestCheckoutPaymentPayload, params: RequestParams = {}) =>
      this.request<RequestCheckoutPaymentData, any>({
        path: `/client/checkout/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get user cards
     *
     * @tags checkout_payment_gateway:Client
     * @name GetUsedCards
     * @request GET:/client/checkout/cards
     * @secure
     */
    getUsedCards: (params: RequestParams = {}) =>
      this.request<GetUsedCardsData, any>({
        path: `/client/checkout/cards`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get user cards
     *
     * @tags gate2pay_payment_gateway:Client
     * @name GetCards
     * @request GET:/client/gate2pay/cards
     * @secure
     */
    getCards: (params: RequestParams = {}) =>
      this.request<GetCardsData, any>({
        path: `/client/gate2pay/cards`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Request gate2pay OTP
     *
     * @tags gate2pay_payment_gateway:Client
     * @name RequestOtp
     * @request POST:/client/gate2pay/otp
     * @secure
     */
    requestOtp: (data: RequestOtpPayload, params: RequestParams = {}) =>
      this.request<RequestOtpData, any>({
        path: `/client/gate2pay/otp`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Request gate2pay deposit
     *
     * @tags gate2pay_payment_gateway:Client
     * @name Deposit
     * @request POST:/client/gate2pay/deposit
     * @secure
     */
    deposit: (data: DepositPayload, params: RequestParams = {}) =>
      this.request<DepositData, any>({
        path: `/client/gate2pay/deposit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Request praxis payment
     *
     * @tags praxis_payment_gateway:Client
     * @name RequestPraxisPayment
     * @request POST:/client/praxis/
     * @secure
     */
    requestPraxisPayment: (data: RequestPraxisPaymentPayload, params: RequestParams = {}) =>
      this.request<RequestPraxisPaymentData, any>({
        path: `/client/praxis/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Request whish payment
     *
     * @tags praxis_payment_gateway:Client
     * @name RequestWhishPayment
     * @request POST:/client/whish/
     * @secure
     */
    requestWhishPayment: (data: RequestWhishPaymentPayload, params: RequestParams = {}) =>
      this.request<RequestWhishPaymentData, any>({
        path: `/client/whish/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get user kyc form
     *
     * @tags kyc:Client
     * @name GetKycFormById
     * @request GET:/client/kyc/form/kyc/forms/{name}
     * @secure
     */
    getKycFormById: (name: string, params: RequestParams = {}) =>
      this.request<GetKycFormByIdData, any>({
        path: `/client/kyc/form/kyc/forms/${name}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get or create a user KYC form for a user
     *
     * @tags user_kyc_form
     * @name GetOrCreateKycForm
     * @request GET:/client/user_kyc_forms/assert
     * @secure
     */
    getOrCreateKycForm: (
      query: {
        entity: string;
        form_name: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetOrCreateKycFormResult, any>({
        path: `/client/user_kyc_forms/assert`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Answer user Form, if method is PUT, it will merge the answers, if POST, it will replace the answers
     *
     * @tags user_kyc_form
     * @name UpdateUserForm
     * @request POST:/client/user_kyc_forms/answers/{id}
     * @secure
     */
    updateUserForm: (id: string, data: UpdateUserFormBody, params: RequestParams = {}) =>
      this.request<UpdateUserFormResult, any>({
        path: `/client/user_kyc_forms/answers/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description a map of all kyc definitions
     *
     * @tags kyc_definitions:Client
     * @name GetAllDefinitions
     * @request GET:/client/kyc/definitions/
     * @secure
     */
    getAllDefinitions: (params: RequestParams = {}) =>
      this.request<GetAllDefinitionsResult, any>({
        path: `/client/kyc/definitions/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Create a bank account for a user
     *
     * @tags bank-accounts:Client
     * @name BankAccountsCreate
     * @request POST:/client/bank-accounts/
     * @secure
     */
    bankAccountsCreate: (data: BankAccountsCreatePayload, params: RequestParams = {}) =>
      this.request<BankAccountsCreateData, any>({
        path: `/client/bank-accounts/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get a bank account
     *
     * @tags bank-accounts:Client
     * @name BankAccountsDetail
     * @request GET:/client/bank-accounts/{userId}
     * @secure
     */
    bankAccountsDetail: (userId: string, params: RequestParams = {}) =>
      this.request<BankAccountsDetailData, any>({
        path: `/client/bank-accounts/${userId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Archive a bank account
     *
     * @tags bank-accounts:Client
     * @name BankAccountsArchiveUpdate
     * @request PUT:/client/bank-accounts/archive/{bankAccountId}
     * @secure
     */
    bankAccountsArchiveUpdate: (bankAccountId: string, params: RequestParams = {}) =>
      this.request<BankAccountsArchiveUpdateData, any>({
        path: `/client/bank-accounts/archive/${bankAccountId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description toggle a bank account default status for an entity
     *
     * @tags bank-accounts:Client
     * @name BankAccountsDefaultUpdate
     * @request PUT:/client/bank-accounts/default/{bankAccountId}
     * @secure
     */
    bankAccountsDefaultUpdate: (
      bankAccountId: string,
      data: BankAccountsDefaultUpdatePayload,
      params: RequestParams = {}
    ) =>
      this.request<BankAccountsDefaultUpdateData, any>({
        path: `/client/bank-accounts/default/${bankAccountId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create a credit card for a user
     *
     * @tags credit-cards:Client
     * @name CreditCardsCreate
     * @request POST:/client/credit-cards/
     * @secure
     */
    creditCardsCreate: (data: CreditCardsCreatePayload, params: RequestParams = {}) =>
      this.request<CreditCardsCreateData, any>({
        path: `/client/credit-cards/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get credit cards for a user
     *
     * @tags credit-cards:Client
     * @name CreditCardsDetail
     * @request GET:/client/credit-cards/{userId}
     * @secure
     */
    creditCardsDetail: (userId: string, params: RequestParams = {}) =>
      this.request<CreditCardsDetailData, any>({
        path: `/client/credit-cards/${userId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Archive a credit card
     *
     * @tags credit-cards:Client
     * @name CreditCardsArchiveUpdate
     * @request PUT:/client/credit-cards/archive/{creditCardId}
     * @secure
     */
    creditCardsArchiveUpdate: (creditCardId: string, params: RequestParams = {}) =>
      this.request<CreditCardsArchiveUpdateData, any>({
        path: `/client/credit-cards/archive/${creditCardId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description toggle a credit card default status for an entity
     *
     * @tags credit-cards:Client
     * @name CreditCardsDefaultUpdate
     * @request PUT:/client/credit-cards/default/{creditCardId}
     * @secure
     */
    creditCardsDefaultUpdate: (
      creditCardId: string,
      data: CreditCardsDefaultUpdatePayload,
      params: RequestParams = {}
    ) =>
      this.request<CreditCardsDefaultUpdateData, any>({
        path: `/client/credit-cards/default/${creditCardId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Send a ticket to Zendesk
     *
     * @tags support-ticket:Client
     * @name SendZendeskTicket
     * @request POST:/client/support-tickets/send-zendesk-ticket
     * @secure
     */
    sendZendeskTicket: (data: SendZendeskTicketPayload, params: RequestParams = {}) =>
      this.request<SendZendeskTicketData, any>({
        path: `/client/support-tickets/send-zendesk-ticket`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  fcm = {
    /**
     * No description
     *
     * @tags fcm
     * @name Register
     * @request POST:/fcm/register
     * @secure
     */
    register: (data: RegisterPayload, params: RequestParams = {}) =>
      this.request<RegisterData, any>({
        path: `/fcm/register`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  balances = {
    /**
     * No description
     *
     * @tags balance
     * @name GetBalancesForTarget
     * @request GET:/balances/{target}
     * @secure
     */
    getBalancesForTarget: (
      target: string,
      query?: {
        gte?: number;
        lte?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetBalancesForTargetData, any>({
        path: `/balances/${target}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags balance
     * @name GetBalanceForCurrency
     * @request GET:/balances/{target}/{currency}
     * @secure
     */
    getBalanceForCurrency: (target: string, currency: string, params: RequestParams = {}) =>
      this.request<GetBalanceForCurrencyData, any>({
        path: `/balances/${target}/${currency}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all balances by target type [gateway or account or wallet]
     *
     * @tags balance
     * @name GetBalancesByTargetType
     * @request GET:/balances
     * @secure
     */
    getBalancesByTargetType: (
      query?: {
        type?: 'gateway' | 'account' | 'wallet';
        gte?: number;
        lte?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetBalancesByTargetTypeData, any>({
        path: `/balances`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags balance
     * @name ArchiveBalances
     * @request PUT:/balances/{target}/archive
     * @secure
     */
    archiveBalances: (target: string, data: ArchiveBalancesPayload, params: RequestParams = {}) =>
      this.request<ArchiveBalancesData, any>({
        path: `/balances/${target}/archive`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags balance
     * @name GetArchives
     * @request GET:/balances/{target}/archives
     * @secure
     */
    getArchives: (
      target: string,
      query?: {
        currencies?: string[];
        gte?: number;
        lte?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetArchivesData, any>({
        path: `/balances/${target}/archives`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  theme = {
    /**
     * @description get the theme of the site
     *
     * @name GetTheme
     * @request GET:/theme
     */
    getTheme: (params: RequestParams = {}) =>
      this.request<GetThemeData, any>({
        path: `/theme`,
        method: 'GET',
        ...params,
      }),
  };
  health = {
    /**
     * @description Check the health of the server
     *
     * @name Health
     * @request GET:/health
     */
    health: (params: RequestParams = {}) =>
      this.request<HealthData, any>({
        path: `/health`,
        method: 'GET',
        ...params,
      }),
  };
  passkey = {
    /**
     * No description
     *
     * @name GetProvider
     * @request GET:/passkey/provider/{id}
     */
    getProvider: (id: string, params: RequestParams = {}) =>
      this.request<GetProviderData, any>({
        path: `/passkey/provider/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @name PkGenerateRegistrationOptions
     * @request GET:/passkey/generate-registration-options
     * @secure
     */
    pkGenerateRegistrationOptions: (params: RequestParams = {}) =>
      this.request<PkGenerateRegistrationOptionsData, any>({
        path: `/passkey/generate-registration-options`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name PkVerifyRegistration
     * @request POST:/passkey/verify-registration
     * @secure
     */
    pkVerifyRegistration: (data: PkVerifyRegistrationPayload, params: RequestParams = {}) =>
      this.request<PkVerifyRegistrationData, any>({
        path: `/passkey/verify-registration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name PkGenerateAuthenticationOptions
     * @request GET:/passkey/generate-authentication-options
     * @secure
     */
    pkGenerateAuthenticationOptions: (params: RequestParams = {}) =>
      this.request<PkGenerateAuthenticationOptionsData, any>({
        path: `/passkey/generate-authentication-options`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name PkVerifyAuthentication
     * @request POST:/passkey/verify-authentication
     */
    pkVerifyAuthentication: (data: PkVerifyAuthenticationPayload, params: RequestParams = {}) =>
      this.request<
        PkVerifyAuthenticationData,
        {
          error: string;
        }
      >({
        path: `/passkey/verify-authentication`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  countries = {
    /**
     * @description get list of countries
     *
     * @tags countries
     * @name GetCountries
     * @request GET:/countries
     */
    getCountries: (params: RequestParams = {}) =>
      this.request<GetCountriesData, any>({
        path: `/countries`,
        method: 'GET',
        ...params,
      }),
  };
  admin = {
    /**
     * @description get list of countries
     *
     * @tags admin
     * @name GetCountries
     * @request GET:/admin/countries
     */
    getCountries: (params: RequestParams = {}) =>
      this.request<GetCountriesResult, any>({
        path: `/admin/countries`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description create a country
     *
     * @tags admin
     * @name CreateCountry
     * @request POST:/admin/countries
     */
    createCountry: (data: CreateCountryPayload, params: RequestParams = {}) =>
      this.request<CreateCountryData, any>({
        path: `/admin/countries`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description update a country
     *
     * @tags admin
     * @name UpdateCountry
     * @request PATCH:/admin/countries/{id}
     */
    updateCountry: (id: string, data: UpdateCountryPayload, params: RequestParams = {}) =>
      this.request<UpdateCountryData, any>({
        path: `/admin/countries/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description sync countries from external source (Drivewealth)
     *
     * @tags admin
     * @name SyncDrivewealthCountries
     * @request POST:/admin/countries/sync/dw
     */
    syncDrivewealthCountries: (params: RequestParams = {}) =>
      this.request<SyncDrivewealthCountriesData, any>({
        path: `/admin/countries/sync/dw`,
        method: 'POST',
        ...params,
      }),
  };
  hooks = {
    /**
     * @description idwise webhook
     *
     * @tags webhooks
     * @name IdwiseCreate
     * @request POST:/hooks/idwise/
     */
    idwiseCreate: (data: IdwiseCreatePayload, params: RequestParams = {}) =>
      this.request<IdwiseCreateData, any>({
        path: `/hooks/idwise/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Checkout hook
     *
     * @tags webhooks
     * @name CheckoutHook
     * @request POST:/hooks/checkout/
     */
    checkoutHook: (data: CheckoutHookPayload, params: RequestParams = {}) =>
      this.request<CheckoutHookData, any>({
        path: `/hooks/checkout/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Lean hook
     *
     * @tags webhooks
     * @name LeanHook
     * @request POST:/hooks/leantech/
     */
    leanHook: (data: LeanHookPayload, params: RequestParams = {}) =>
      this.request<LeanHookData, any>({
        path: `/hooks/leantech/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Praxis hook
     *
     * @tags webhooks
     * @name PraxisHook
     * @request POST:/hooks/praxis/
     */
    praxisHook: (data: PraxisHookPayload, params: RequestParams = {}) =>
      this.request<PraxisHookData, any>({
        path: `/hooks/praxis/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Whish hook
     *
     * @tags webhooks
     * @name WhishHook
     * @request POST:/hooks/whish/
     */
    whishHook: (data: WhishHookPayload, params: RequestParams = {}) =>
      this.request<WhishHookData, any>({
        path: `/hooks/whish/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  test = {
    /**
     * @description Get all documents for a user
     *
     * @name TestDocument
     * @request GET:/test
     */
    testDocument: (params: RequestParams = {}) =>
      this.request<TestDocumentData, any>({
        path: `/test`,
        method: 'GET',
        ...params,
      }),
  };
}
