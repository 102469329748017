import DeviceEventEmitter from '../../DeviceEmitter';
import {mobileApi} from '@/Lib/api';
import {toastError, toastSuccess} from '@/components/ToastUI';
import type {SymbolStore} from '../SymbolStore';

export interface AlertData {
  id: string;
  symbol: string;
  price: string;
  server: string;
  user_id: number;
  login: string;
  notification_methods: {
    sms: boolean;
    push: boolean;
    email: boolean;
  };
  created_at: string;
  updated_at: string;
}

export class Alert {
  data: AlertData;
  constructor(data: AlertData, private symbolStore: SymbolStore) {
    this.data = data;
  }

  get Symbol() {
    return this.symbolStore.getSymbolSync(this.data.symbol);
  }

  save(sms: boolean, push: boolean, email: boolean, price: string) {
    this.data.notification_methods.sms = !!sms;
    this.data.notification_methods.push = !!push;
    this.data.notification_methods.email = !!email;
    this.data.price = String(price);

    return mobileApi
      .patch<AlertData[]>('/price-alerts/' + this.data.id, {
        price: this.data.price,
        notification_methods: this.data.notification_methods,
        server: 'live',
      })
      .then(({data}: any) => {
        toastSuccess(data?.message || 'Alert Updated');
      })
      .catch(() => toastError('could not update alert'));
  }

  confirm(data: AlertData) {
    DeviceEventEmitter.emit('navigate', [
      'ModifyAlert',
      {id: this.data.id, data: data || this.data},
    ]);
  }
}
